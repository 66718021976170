import PortHeader from "../components/contents/portContent/PortHeader";
import PortBody from "../components/contents/portContent/PortBody";

function Port() {
  return (
    <>
      <PortHeader />
      <PortBody />
    </>
  );
}

export default Port;
