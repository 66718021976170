import React from 'react'
import AuthComponent from '../components/auth/AuthComponent'

export default function Login() {
  return (
    <div>
      <AuthComponent authType="login"/>
    </div>
  )
}
