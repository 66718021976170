import ShippingTable from "../components/linksContent/Shipping/ShippingTable";

function ShippingProjects() {
  return (
    <>
      <ShippingTable />
    </>
  );
}

export default ShippingProjects;
