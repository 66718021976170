import React from 'react'
import { handleChange } from '../../misc/commonFunctions';
import { TabSystem, TextFieldWithAdornment } from '../common/CommonComponents';

export default function CostCalculationsParameters({ state, setState, }) {
  let { CalculatorSteps, userType } = state;

  const data = CalculatorSteps[2].inputType;

  return (
    <div className="row mt-xs-32">
      <div className="col-lg-12">
        <h4 className='color-w'>Cost Calculations Parameters</h4>
        <div className="row">
          <div className="col-lg-12">
            <div className="block-level-1">
              <h4 className='block-title'>Externalities  + co2 cost </h4>
              <div className='row'>
                <div className="col-lg-6">
                  <div className="h2-input-group">
                    <label className="h2-input-group_title">CO2</label>
                    <TextFieldWithAdornment
                      label="Cost Factor"
                      value={data.externalitiesCo2CostFactor}
                      onChange={e => {
                        handleChange(e.target.value, setState, CalculatorSteps, "externalitiesCo2CostFactor", 2)
                      }}
                      rightLabel="€/tCO2 eq."
                    />
                      <TextFieldWithAdornment
                      label="Diesel CO2 emission factor"
                      value={data.externalitiesDieselCo2EmmissionFactor}
                      onChange={e => {
                        handleChange(e.target.value, setState, CalculatorSteps, "externalitiesDieselCo2EmmissionFactor", 2)
                      }}
                      rightLabel="g CO2eq./ liter fuel"
                    />
                        <TextFieldWithAdornment
                      label="LNG_CO2 emission factor "
                      value={data.externalitiesLngCo2EmmissionFactor}
                      onChange={e => {
                        handleChange(e.target.value, setState, CalculatorSteps, "externalitiesLngCo2EmmissionFactor", 2)
                      }}
                      rightLabel="g CO2eq./ kg fuel"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="h2-input-group">
                    <label className='h2-input-group_title'>Air Pollution</label>
                    <TextFieldWithAdornment
                      label="Air Pollution cost"
                      value={data.externalitiesAirPollutionCost}
                      onChange={e => {
                        handleChange(e.target.value, setState, CalculatorSteps, "externalitiesAirPollutionCost", 2)
                      }}
                      rightLabel="€-cent/tkm"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="h2-input-group">
                    <label className='h2-input-group_title'>Climate Change</label>
                    <TextFieldWithAdornment
                      label="Climate Chage cost  "
                      value={data.externalitiesClimateChangeCost}
                      onChange={e => {
                        handleChange(e.target.value, setState, CalculatorSteps, "externalitiesClimateChangeCost", 2)
                      }}
                      rightLabel="€-cent/tkm"
                    />
                  </div>
                </div>

              </div>

            </div>

            <div className="block-level-1">
              <h4 className='block-title'>Cost Parameter </h4>

              <TabSystem
                tabTitleData={[{
                  value: 1,
                  label: "Capex Parameters"
                },
                {
                  value: 2,
                  label: "Opex Parameters"
                },
                {
                  value: 3,
                  label: "System Lifetime"
                }
                ]}
                tabPanelData={
                  [
                    {
                      value: 1,
                      component: <div className='inner-tab'>
                        <TabSystem
                          tabTitleData={[{
                            value: 1,
                            label: "E-motor"
                          },
                          {
                            value: 2,
                            label: "Battery"
                          },
                          {
                            value: 3,
                            label: "Fuel cell"
                          },
                          {
                            value: 4,
                            label: "Diesel"
                          },
                          {
                            value: 5,
                            label: "LNG"
                          }
                          ]}
                          tabPanelData={
                            [
                              {
                                value: 1,
                                component: <div className='row'>

                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="E-Motor"
                                        value={data.capexEmotor}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexEmotor", 2)
                                        }}
                                        rightLabel="€/Kw"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Variable Frequency driver "
                                        value={data.capexEmotorVariableFrequencyDriver}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexEmotorVariableFrequencyDriver", 2)
                                        }}
                                        rightLabel="€/Kw"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Converter"
                                        value={data.capexEmotorConverter}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexEmotorConverter", 2)
                                        }}
                                        rightLabel="€/Kw"
                                      />
                                    </div>
                                  </div>

                                </div>
                              },
                              {
                                value: 2,
                                component: <div className='row'>

                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Battery System"
                                        value={data.capexBatterySystem}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexBatterySystem", 2)
                                        }}
                                        rightLabel="€/Kwh"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Total Installation Cost"
                                        value={data.capexBatteryInstallationCost}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexBatteryInstallationCost", 2)
                                        }}
                                        rightLabel="€"
                                      />
                                    </div>
                                  </div>


                                </div>
                              },
                              {
                                value: 3,
                                component: <div className='row'>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Fuel cell system (With installation cost)"
                                        value={data.capexFuelCellInstallationCost}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexFuelCellInstallationCost", 2)
                                        }}
                                        rightLabel="€/Kw"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-xs-16">
                                    <div className="col-lg-12">
                                      <h4 className="color-w mb-xs-16 fz18">Compressed Hydrogen </h4>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Hydrogen Storage (35MPa)"
                                              value={data.capexCompressedHydrogen35Mpa}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexCompressedHydrogen35Mpa", 2)
                                              }}
                                              rightLabel="€/KgH2"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Hydrogen Storage (50MPa)"
                                              value={data.capexCompressedHydrogen50Mpa}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexCompressedHydrogen50Mpa", 2)
                                              }}
                                              rightLabel="€/KgH2"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Hydrogen Storage (70MPa)"
                                              value={data.capexCompressedHydrogen70Mpa}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexCompressedHydrogen70Mpa", 2)
                                              }}
                                              rightLabel="€/KgH2"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <h4 className="color-w mb-xs-16 fz18">Metal Hydrides  </h4>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Sodium Alanate"
                                              value={data.capexMetalHydrides}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexMetalHydrides", 2)
                                              }}
                                              rightLabel="€/KgH2"
                                            />
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <h4 className="color-w mb-xs-16 fz18">Ammonia  </h4>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Cracker "
                                              value={data.capexAmmoniaCracker}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexAmmoniaCracker", 2)
                                              }}
                                              rightLabel="€/Kw"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Tank "
                                              value={data.capexAmmoniaTank}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexAmmoniaTank", 2)
                                              }}
                                              rightLabel="€/Kg (tank)"
                                            />
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <h4 className="color-w mb-xs-16 fz18">Liquid Hydrogen   </h4>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="h2-input-group">
                                            <TextFieldWithAdornment
                                              label="Liquid hydrogen storage Cost "
                                              value={data.capexLiquidHydrogenStorageCost}
                                              onChange={e => {
                                                handleChange(e.target.value, setState, CalculatorSteps, "capexLiquidHydrogenStorageCost", 2)
                                              }}
                                              rightLabel="€/KgH2"
                                            />
                                          </div>
                                        </div>


                                      </div>
                                    </div>
                                  </div>


                                </div>
                              },
                              {
                                value: 4,
                                component: <div className='row'>

                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Diesel Engine CCRII + Gearbox "
                                        value={data.capexDieselEngineAndGearbox}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexDieselEngineAndGearbox", 2)
                                        }}
                                        rightLabel="€/Kwh "
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Diesel Tank"
                                        value={data.capexDieselTank}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexDieselTank", 2)
                                        }}
                                        rightLabel="€/Kg (tank weight)"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Total installation cost"
                                        value={data.capexDieselInstallationCost}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexDieselInstallationCost", 2)
                                        }}
                                        rightLabel="€"
                                      />
                                    </div>
                                  </div>


                                </div>
                              },
                              {
                                value: 5,
                                component: <div className='row'>


                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="LNG+ gear box"
                                        value={data.capexLngEngineAndGearbox}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexLngEngineAndGearbox", 2)
                                        }}
                                        rightLabel=" €/Kw"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="LNG cryogenic tank"
                                        value={data.capexLngTank}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexLngTank", 2)
                                        }}
                                        rightLabel="€/m3(LNG stored)"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Total installation const"
                                        value={data.capexLngInstallationCost}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "capexLngInstallationCost", 2)
                                        }}
                                        rightLabel="€"
                                      />
                                    </div>
                                  </div>

                                </div>
                              },


                            ]
                          }
                        />

                      </div>
                    },
                    {
                      value: 2,
                      component: <div className='inner-tab'>
                        <TabSystem
                          tabTitleData={[{
                            value: 1,
                            label: "Fixed Opex"
                          },
                          {
                            value: 2,
                            label: "variable Opex"
                          },
                          {
                            value: 3,
                            label: "Replacement"
                          },
                          {
                            value: 4,
                            label: "Engine Revision"
                          }
                          ]}
                          tabPanelData={
                            [
                              {
                                value: 1,
                                component: <div className='row'>

                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Combustion Engine"
                                        value={data.opexFixedCombustionEngine}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "opexFixedCombustionEngine", 2)
                                        }}
                                        rightLabel="€/Kw/a"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Converter"
                                        value={data.opexFixedConverter}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "opexFixedConverter", 2)
                                        }}
                                        rightLabel="€/Kw/a"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Variable frequency driver"
                                        value={data.opexFixedVariableFrequencyDriver}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "opexFixedVariableFrequencyDriver", 2)
                                        }}
                                        rightLabel="€/Kw/a"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="h2-input-group">
                                      <TextFieldWithAdornment
                                        label="Cracker"
                                        value={data.opexFixedCracker}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "opexFixedCracker", 2)
                                        }}
                                        rightLabel="of"
                                      />
                                    </div>
                                  </div>
                                </div>
                              },
                              {
                                value: 2,
                                component: <div className='row'>
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Diesel Price "
                                            value={data.opexVariableDieselPrice}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableDieselPrice", 2)
                                            }}
                                            rightLabel="€/Kg"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Electricity Cost"
                                            value={data.opexVariableElectricityCost}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableElectricityCost", 2)
                                            }}
                                            rightLabel="€/KWh"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="LNG Price"
                                            value={data.opexVariableLngPrice}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableLngPrice", 2)
                                            }}
                                            rightLabel="€/Kg"
                                          />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Compressed Hydrogen </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Compressed Hydrogen Cost (700 bar)"
                                            value={data.opexVariableCompressedHydrogen700Bar}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableCompressedHydrogen700Bar", 2)
                                            }}
                                            rightLabel="€/KgH2"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Compressed Hydrogen Cost (500 bar)"
                                            value={data.opexVariableCompressedHydrogen500Bar}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableCompressedHydrogen500Bar", 2)
                                            }}
                                            rightLabel="€/KgH2"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Compressed Hydrogen Cost (350 bar)"
                                            value={data.opexVariableCompressedHydrogen350Bar}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableCompressedHydrogen350Bar", 2)
                                            }}
                                            rightLabel="€/KgH2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Metal Hydrides  </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Metal Hydrides  refuling cost "
                                            value={data.opexVariableMetalHydridesRefuelingCost}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableMetalHydridesRefuelingCost", 2)
                                            }}
                                            rightLabel="€/KgH2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Ammonia  </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Ammonia Cost"
                                            value={data.opexVariableAmmoniaCost}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableAmmoniaCost", 2)
                                            }}
                                            rightLabel="€/Kg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Liquid Hydrogen   </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Liquid hydrogen Price"
                                            value={data.opexVariableLiquidHydrogenPrice}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "opexVariableLiquidHydrogenPrice", 2)
                                            }}
                                            rightLabel="€/KgH2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              },
                              {
                                value: 3,
                                component: <div className='row'>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Fuel Cell </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Lifetime"
                                            value={data.replacementFuelCellLifetime}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "replacementFuelCellLifetime", 2)
                                            }}
                                            rightLabel="Hours"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Replacement "
                                            value={data.replacementFuelCellCost}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "replacementFuelCellCost", 2)
                                            }}
                                            rightLabel="Cost FC system cost"
                                          />
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Battery </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Lifetime"
                                            value={data.replacementBatteryLifetime}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "replacementBatteryLifetime", 2)
                                            }}
                                            rightLabel="Cycles"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Replacement "
                                            value={data.replacementBatteryCost}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "replacementBatteryCost", 2)
                                            }}
                                            rightLabel="€/Kwh"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              },
                              {
                                value: 4,
                                component: <div className='row'>
                                  <div className="col-lg-12">
                                    <h5 className="color-w mb-xs-8">Engine Revision </h5>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Interval"
                                            value={data.engineRevisionInterval}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "engineRevisionInterval", 2)
                                            }}
                                            rightLabel="Hours"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="h2-input-group">
                                          <TextFieldWithAdornment
                                            label="Cost "
                                            value={data.engineRevisionCost}
                                            onChange={e => {
                                              handleChange(e.target.value, setState, CalculatorSteps, "engineRevisionCost", 2)
                                            }}
                                            rightLabel="€/Kw"
                                          />
                                        </div>
                                      </div>

                                    </div>
                                  </div>


                                </div>
                              },
                            ]
                          }
                        />

                      </div>
                    },
                    {
                      value: 3,
                      component: <div className='row'>

                        <div className="col-lg-6">
                          <div className="h2-input-group">
                            <TextFieldWithAdornment
                              label="Lifetime"
                              value={data.lifeTime}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "lifeTime", 2)
                              }}
                              rightLabel="Years"
                            />
                          </div>
                        </div>
                      </div>
                    },


                  ]
                }
              />

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
