import portCover from "../../../assets/images/portCover.jpeg";
import moment from "moment"
import { useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";
import React from "react";
import { LoadingComponent } from "../../common/CommonComponents";
import { getReportDetails } from "../../../request/api";
function ReportDetails() {
  let params = useParams();
  const [state, setState] = React.useState({
    details: null
  })
  React.useEffect(() => {
    (async () => {
      let res = await getReportDetails(params.id)
      if (res) {
        setState(prev => ({ ...prev, details: res.data.data }))
      }
    })();
  }, [])

  let { details } = state;
  return (
    <div className="reportHeader container">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          {!details ? <LoadingComponent /> : <div>
            <div className="reportHeader__reportHeaderTop">
              <h1 className="reportHeader__title">

                {details.title}
              </h1>
              <div className="reportHeader__author">
                {/*<img src={author} alt="" className="reportHeader__authorImg" />*/}

                <div className="reportHeader__authorInfo">
                  <div className="reportHeader__authorInfoText">
                    <h5 className="reportHeader__authorName">
                      {details.author}
                    </h5>
                    <p className="reportHeader__authorDate">{moment(details.date_of_publish).format("DD MMM YYYY")}</p>
                  </div>
                </div>

                <span className="badge bg-success reportHeader__reportCategory">
                  {details.category_name}
                </span>
              </div>
            </div>
            <div className="reportHeader__coverImg mb-5">
              <img
                src={portCover}
                alt=""
                width="100%"
                className="rounded shadow-sm"
              />
              <p className="reportHeader__coverImgTitle text-center">
                Hydrogen can power virtually all container ships crossing the
                Pacific
              </p>
            </div>
            <div className="reportHeader__reportDesc">
              <p>Master’s thesis - Master Energy Science </p>
              <p>
                ASSESSMENT AND COMPARISON OF ALTERNATIVE MARINE FUELS TOWARDS THE
                DECARBONISATION OF PORT OF AMSTERDAM
              </p>
              <p>
                Written by: Thomas van der Maas (5535654) -
                t.j.vandermaas@students.uu.nl
              </p>

              <div className="reportHeader__textFlex"
                dangerouslySetInnerHTML={{ __html: details.details }}
              >

              </div>
              <div className="reportHeader__reportDesc">
                <h1>below text is just exmple static text</h1>
                <p>Master’s thesis - Master Energy Science </p>
                <p>
                  ASSESSMENT AND COMPARISON OF ALTERNATIVE MARINE FUELS TOWARDS THE
                  DECARBONISATION OF PORT OF AMSTERDAM
                </p>
                <p>
                  Written by: Thomas van der Maas (5535654) -
                  t.j.vandermaas@students.uu.nl
                </p>

                <div className="reportHeader__textFlex">
                  <div className="reportHeader__contact">
                    <h4 className="fw-bold">Supervisor Utrecht University:</h4>
                    <span> Prof. dr. G. J. Kramer - g.j.kramer@uu.nl </span>
                    <span>Supervisor Port of Amsterdam:</span>
                    <span> J. Egbertsen - Jan.Egbertsen@portofamsterdam.com</span>
                    <span> 29-07-2020</span>
                  </div>

                  <button className="btn btn-primary reportHeader__downloadBtn">
                    Download
                  </button>
                </div>
              </div>
            </div>

          </div>}

        </div>
      </div>
    </div>);
}

export default ReportDetails;
