import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5C96E0",
    color: theme.palette.common.white,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 700,
    paddingRight: 5,
    paddingLeft: 5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



export default function CalcDataTable({ rows, titleList, type, unit }) {
  return (
    <TableContainer className='CalcDataTable' component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {titleList.map((tl, i) => <StyledTableCell
              align={i === 0 ? "left" : "right"}
              key={tl + type}
              dangerouslySetInnerHTML={{ __html: tl }} ></StyledTableCell>)}



          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i + type}>
              {row.map((item, j) => <StyledTableCell
                align={j === 0 ? "left" : "right"}
                key={j + "child" + type}
                component="th"
                scope="row">
                {isNaN(item) ? item : Math.round(item * 100) / 100}
                {" "}
                {j !== 0 && unit}
              </StyledTableCell>)}


            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
