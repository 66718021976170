import { result } from "./result";

export const CalculatorStepsDATA_KEY_MAP = {
  "Propulsion_Power": 0,
  "Operational_Profile": 1,
  "Capacity_Dimensioning": 2,


}

let CalculatorStepsDATA = [
  {
    key: "Propulsion_Power",
    title: "Propulsion Power",
    validated: false,
    inputType: {
      vesselType: { value: "Cargo", required: true, validationMessage: "" },
      knowInstalledPower: { value: "No", required: false, validationMessage: "" },
      installedPower: { value: "", required: false, validationMessage: "" },
      hullLength: { value: "", required: true, validationMessage: "" },
      hullBreadth: { value: "", required: true, validationMessage: "" },
      hullDraught: { value: "", required: true, validationMessage: "" },
      maxSpeed: { value: "", required: true, validationMessage: "" },
      keepAtLeastDuring: { value: "", required: true, validationMessage: "" },
      resistantCoEfficient: { value: "", required: false, validationMessage: "" },
      shaftEfficiency: { value: "99", required: false, validationMessage: "", userType: "Advanced_User_Only" },
      propellerEfficiency: { value: "72", required: false, validationMessage: "", userType: "Advanced_User_Only" },

    }
  },
  {
    key: "Operational_Profile",
    title: "Operational Profile",
    validated: false,
    inputType: {
      originDestinationDistance: { value: "", required: true, validationMessage: "" },
      serviceAverageSpeed: { value: "", required: true, validationMessage: "" },
      maxLoadCapacity: { value: "", required: true, validationMessage: "" },
      averageLoadCapacity: { value: "", required: true, validationMessage: "" },
      numberOfRoundTripsForYear: { value: "", required: true, validationMessage: "" },
      sailingTimePerYear: { value: "", required: false, validationMessage: "" },
      numberOfStops: { value: "", required: true, validationMessage: "" },
      longestRange: { value: "", required: false, validationMessage: "" },

    }
  },
  {
    key: "Capacity_Dimensioning",
    title: "Capacity Dimensioning",
    validated: false,
    inputType: {
      hydrogenStorageSystem: { value: "", required: true, validationMessage: "" },
      pressureOfHydrogen: { value: "", required: false, validationMessage: "" },
      massLimit: { value: "", required: true, validationMessage: "" },
      volumeLimit: { value: "", required: true, validationMessage: "" },

      dieselInstallationCost: { value: "", required: false, validationMessage: "" },
      batteryInstallationCost: { value: "", required: false, validationMessage: "" },
      fuelCellInstallationCost: { value: "", required: false, validationMessage: "" },
      lngInstallationCost: { value: "", required: false, validationMessage: "" },
      lifeTime: { value: "20", required: true, validationMessage: "" },

      efficiencyBatteryEmotor: { value: "", required: false, validationMessage: "" },
      efficiencyBatteryRoundTrip: { value: "", required: false, validationMessage: "" },
      efficiencyBatteryConverter: { value: "", required: false, validationMessage: "" },
      efficiencyBatterySpeedController: { value: "", required: false, validationMessage: "" },


      efficiencyFuelCellEmotor: { value: "", required: false, validationMessage: "" },
      efficiencyFuelCellBatteryRoundTrip: { value: "", required: false, validationMessage: "" },
      efficiencyFuelCell: { value: "", required: false, validationMessage: "" },
      efficiencyFuelCellConverter: { value: "", required: false, validationMessage: "" },
      efficiencyFuelCellSpeedController: { value: "", required: false, validationMessage: "" },

      efficiencyHybridEmotor: { value: "", required: false, validationMessage: "" },
      efficiencyHybridBatteryRoundTrip: { value: "", required: false, validationMessage: "" },
      efficiencyHybridFuelCell: { value: "", required: false, validationMessage: "" },
      efficiencyHybridConverter: { value: "", required: false, validationMessage: "" },
      efficiencyHybridSpeedController: { value: "", required: false, validationMessage: "" },

      efficiencyDieselCombustionEngine: { value: "", required: false, validationMessage: "" },

      efficiencyLngCombustionEngine: { value: "", required: false, validationMessage: "" },

      otherParameterBatteryDepthOfDischarge: { value: "", required: false, validationMessage: "" },
      otherParameterFuelCellSpecificEnergyDensity: { value: "", required: false, validationMessage: "" },
      otherParameterDieselLowerHeatingValue: { value: "", required: false, validationMessage: "" },
      otherParameterLngLowerHeatingValue: { value: "", required: false, validationMessage: "" },


      specificPowerEnergyDieselEngineA: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyDieselEngineB: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyLngEngineA: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyLngEngineB: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyElectricMotorA: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyElectricMotorB: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyConverterA: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyConverterB: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyVFDA: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyVFDB: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyFuelCellA: { value: "", required: false, validationMessage: "" },
      specificPowerEnergyBatteryA: { value: "", required: false, validationMessage: "" },


      powerEnergyDensityDieselEngineA: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityDieselEngineB: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityLngEngineA: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityLngEngineB: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityElectricMotorA: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityElectricMotorB: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityConverterA: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityConverterB: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityVFDA: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityVFDB: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityFuelCellA: { value: "", required: false, validationMessage: "" },
      powerEnergyDensityBatteryA: { value: "", required: false, validationMessage: "" },


      fuelStorageCompressedHydrogen70MpaWeight: { value: "", required: false, validationMessage: "" },
      fuelStorageCompressedHydrogen70MpaVolume: { value: "", required: false, validationMessage: "" },
      fuelStorageCompressedHydrogen50MpaWeight: { value: "", required: false, validationMessage: "" },
      fuelStorageCompressedHydrogen50MpaVolume: { value: "", required: false, validationMessage: "" },
      fuelStorageCompressedHydrogen35MpaWeight: { value: "", required: false, validationMessage: "" },
      fuelStorageCompressedHydrogen35MpaVolume: { value: "", required: false, validationMessage: "" },

      fuelStorageAmmoniaNH3CrackerWeight: { value: "", required: false, validationMessage: "" },
      fuelStorageAmmoniaNH3CrackerVolume: { value: "", required: false, validationMessage: "" },
      fuelStorageAmmoniaTankVolumetricStorageDensity: { value: "", required: false, validationMessage: "" },
      fuelStorageAmmoniaTankSpecificWeight: { value: "", required: false, validationMessage: "" },
      fuelStorageMetalHydridesWeight: { value: "", required: false, validationMessage: "" },
      fuelStorageMetalHydridesVolume: { value: "", required: false, validationMessage: "" },
      fuelStorageLiquidHydrogenVolumetricStorageDensity: { value: "", required: false, validationMessage: "" },
      fuelStorageDensityLngMass: { value: "", required: false, validationMessage: "" },
      fuelStorageMassTankLng: { value: "", required: false, validationMessage: "" },
      fuelStorageDensityDieselMass: { value: "", required: false, validationMessage: "" },
      fuelStorageMassTankDiesel: { value: "", required: false, validationMessage: "" },


      externalitiesCo2CostFactor: { value: "", required: false, validationMessage: "" },
      externalitiesDieselCo2EmmissionFactor: { value: "", required: false, validationMessage: "" },
      externalitiesLngCo2EmmissionFactor: { value: "", required: false, validationMessage: "" },
      externalitiesAirPollutionCost: { value: "", required: false, validationMessage: "" },
      externalitiesClimateChangeCost: { value: "", required: false, validationMessage: "" },


      capexEmotor: { value: "", required: false, validationMessage: "" },
      capexEmotorVariableFrequencyDriver: { value: "", required: false, validationMessage: "" },
      capexEmotorConverter: { value: "", required: false, validationMessage: "" },
      capexBatterySystem: { value: "", required: false, validationMessage: "" },
      capexBatteryInstallationCost: { value: "", required: false, validationMessage: "" },

      capexFuelCellInstallationCost: { value: "", required: false, validationMessage: "" },
      capexCompressedHydrogen35Mpa: { value: "", required: false, validationMessage: "" },
      capexCompressedHydrogen50Mpa: { value: "", required: false, validationMessage: "" },
      capexCompressedHydrogen70Mpa: { value: "", required: false, validationMessage: "" },
      capexMetalHydrides: { value: "", required: false, validationMessage: "" },
      capexAmmoniaCracker: { value: "", required: false, validationMessage: "" },
      capexAmmoniaTank: { value: "", required: false, validationMessage: "" },
      capexLiquidHydrogenStorageCost: { value: "", required: false, validationMessage: "" },

      capexDieselEngineAndGearbox: { value: "", required: false, validationMessage: "" },
      capexDieselTank: { value: "", required: false, validationMessage: "" },
      capexDieselInstallationCost: { value: "", required: false, validationMessage: "" },
      capexLngEngineAndGearbox: { value: "", required: false, validationMessage: "" },
      capexLngTank: { value: "", required: false, validationMessage: "" },
      capexLngInstallationCost: { value: "", required: false, validationMessage: "" },

      opexFixedCombustionEngine: { value: "", required: false, validationMessage: "" },
      opexFixedConverter: { value: "", required: false, validationMessage: "" },
      opexFixedVariableFrequencyDriver: { value: "", required: false, validationMessage: "" },
      opexFixedCracker: { value: "", required: false, validationMessage: "" },
      opexVariableDieselPrice: { value: "", required: false, validationMessage: "" },
      opexVariableElectricityCost: { value: "", required: false, validationMessage: "" },
      opexVariableLngPrice: { value: "", required: false, validationMessage: "" },
      opexVariableCompressedHydrogen700Bar: { value: "", required: false, validationMessage: "" },
      opexVariableCompressedHydrogen500Bar: { value: "", required: false, validationMessage: "" },
      opexVariableCompressedHydrogen350Bar: { value: "", required: false, validationMessage: "" },
      opexVariableMetalHydridesRefuelingCost: { value: "", required: false, validationMessage: "" },
      opexVariableAmmoniaCost: { value: "", required: false, validationMessage: "" },
      opexVariableLiquidHydrogenPrice: { value: "", required: false, validationMessage: "" },
      replacementFuelCellLifetime: { value: "", required: false, validationMessage: "" },
      replacementFuelCellCost: { value: "", required: false, validationMessage: "" },
      replacementBatteryLifetime: { value: "", required: false, validationMessage: "" },
      replacementBatteryCost: { value: "", required: false, validationMessage: "" },
      engineRevisionInterval: { value: "", required: false, validationMessage: "" },
      engineRevisionCost: { value: "", required: false, validationMessage: "" },








    },

  },


];

export const StateDataModel = {
  selectedStep:
    "Propulsion_Power", //loading,  Propulsion_Power,Operational_Profile,Capacity_Dimensioning and final step is "result"
  userType: "standard", // standard . advanced
  CalculatorSteps: [...CalculatorStepsDATA],
  // result: result
  result: null
}
export const subcategoryListReports = [
  {
    "id": 1,
    "name": "Case Studies",
    "type": 1,
  },
  {
    "id": 2,
    "name": "Clean Fuels",
    "type": 1,
  },
  {
    "id": 3,
    "name": "Economics and Markets",
    "type": 1,
  },
  {
    "id": 4,
    "name": "Shipping Platforms",
    "type": 2,
  },
  {
    "id": 5,
    "name": "Initiatives & workshops",
    "type": 2,
  },
  {
    "id": 6,
    "name": "Fund & research",
    "type": 2,
  }
]
export const ReportFilters = [
  {
    label: "All Reports",
    value: "all",
  },
  {
    label: "Case Studies",
    value: "Case_Studies",
  },
  {
    label: "Clean Fuels",
    value: "Clean_Fuels",
  },
  {
    label: "Roadmaps",
    value: "Roadmaps",
  },
  {
    label: "Economics & Markets",
    value: "Economics_&_Markets",
  },
  {
    label: "Ship Propulsion",
    value: "Ship_Propulsion",
  },
];
export const ReportFilterTags = [
  {
    label: "Research",
    value: "Research",
  },
  {
    label: "H2O",
    value: "H2O",
  },
  {
    label: "CO2",
    value: "CO2",
  },
  {
    label: "Hydrogen fuel",
    value: "Hydrogen_fuel",
  },
  {
    label: "Emission",
    value: "Emission",
  },
  {
    label: "Coastal",
    value: "Coastal",
  },
  {
    label: "Greenhouse",
    value: "Greenhouse",
  },
];


export const dashboard_link_tree = [
  {
    label: "Resources",
    value: "Resources",
    childrens: [
      {
        label: "Reports",
        value: "Reports",
      },
      {
        label: "Legislative",
        value: "Legislative",
      },
      {
        label: "Education materials",
        value: "Education_materials",
      },
    ]

  },
  {
    label: "Links",
    value: "Links",
    childrens: [
      {
        label: "Shipping Platforms",
        value: "Shipping_platforms",
      },
      {
        label: "Initiatives & workshops ",
        value: "Initiatives_&_workshops ",
      },
      {
        label: "Fund & research",
        value: "Fund_&_research",
      }
    ]

  }
]


export const ReportsCellTitles = [
  "Title",
  "Author",
  "Sub Category",

  "Publish date",
  " ",
  " ",
  // " &nbsp;",
];

export const typeList = [
  {
    "id": 1,
    "name": "Resources",

  },
  {
    "id": 2,
    "name": "Links",
  }
]
export const categoryList = [
  {
    "id": 1,
    "name": "Reports",
    "type": 1,
    subcategory: subcategoryListReports,
  },
  {
    "id": 2,
    "name": "Legislative",
    "type": 1,
    subcategory: subcategoryListReports,
  },
  {
    "id": 33,
    "name": "Education materials",
    "type": 1,
    subcategory: [],
  },
  {
    "id": 4,
    "name": "Shipping Platforms",
    "type": 2,
    subcategory: [],
  },
  {
    "id": 5,
    "name": "Initiatives & workshops",
    "type": 2,
    subcategory: subcategoryListReports,
  },
  {
    "id": 6,
    "name": "Fund & research",
    "type": 2,
    subcategory: subcategoryListReports,
  }
]


export const tagList = [
  {
    "id": 4,
    "name": "Oil",
    "type": 3,
    "status": 1,
    "created_at": null,
    "updated_at": null
  },
  {
    "id": 5,
    "name": "H2O",
    "type": 3,
    "status": 1,
    "created_at": null,
    "updated_at": null
  },
  {
    "id": 6,
    "name": "Research",
    "type": 3,
    "status": 1,
    "created_at": null,
    "updated_at": "2022-04-29T08:31:52.000000Z"
  },
  {
    "id": 9,
    "name": "Hydrogen",
    "type": 3,
    "status": 1,
    "created_at": null,
    "updated_at": null
  }
]


export const categoryTree = [
  {
    "id": 2,
    "name": "Projects database",
    "status": 1,
    "created_at": null,
    "updated_at": "2022-08-03T12:15:49.000000Z",
    "category": [
      {
        "id": 5,
        "name": "Initiative & Workshops",
        "type_id": 2,
        "parent_id": 0,
        "status": 0,
        "created_at": null,
        "updated_at": "2022-08-03T13:17:20.000000Z",
        "subcategory": []
      },
      {
        "id": 6,
        "name": "Research projects",
        "type_id": 2,
        "parent_id": 0,
        "status": 1,
        "created_at": null,
        "updated_at": "2022-08-03T12:15:16.000000Z",
        "subcategory": []
      },
      {
        "id": 4,
        "name": "Ships",
        "type_id": 2,
        "parent_id": 0,
        "status": 1,
        "created_at": null,
        "updated_at": "2022-08-03T12:15:28.000000Z",
        "subcategory": []
      }
    ]
  },
  {
    "id": 1,
    "name": "Resources",
    "status": 1,
    "created_at": null,
    "updated_at": null,
    "category": [
      {
        "id": 3,
        "name": "Education Materials",
        "type_id": 1,
        "parent_id": 0,
        "status": 1,
        "created_at": null,
        "updated_at": null,
        "subcategory": []
      },
      {
        "id": 2,
        "name": "Legislative",
        "type_id": 1,
        "parent_id": 0,
        "status": 1,
        "created_at": null,
        "updated_at": "2022-05-17T16:00:56.000000Z",
        "subcategory": []
      },
      {
        "id": 1,
        "name": "Reports",
        "type_id": 1,
        "parent_id": 0,
        "status": 1,
        "created_at": null,
        "updated_at": "2022-08-03T12:19:48.000000Z",
        "subcategory": [
          {
            "id": 7,
            "name": "Case Studies",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": "2022-05-17T16:43:33.000000Z"
          },
          {
            "id": 8,
            "name": "Clean Fuels",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 9,
            "name": "Economics and Markets",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 10,
            "name": "Emissions and Conventional Fuels",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 11,
            "name": "Environmental Impact",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 12,
            "name": "Fuel Cell Technology",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 13,
            "name": "Hydrogen Technology",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 14,
            "name": "Innovative Ship Designs",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 15,
            "name": "Port Technology",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 18,
            "name": "Project deliverable",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 16,
            "name": "Roadmaps",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          },
          {
            "id": 17,
            "name": "Ship Propulsion",
            "type_id": 0,
            "parent_id": 1,
            "status": 1,
            "created_at": null,
            "updated_at": null
          }
        ]
      }
    ]
  }
];



export let CalculatorBarDataList = [

  {
    labelKey: "a",
    label: "Battery",
    name: "Battery",
    value: 1197,
    color: "#FABE7A"
  },
  {
    labelKey: "b",
    label: "Hybrid",
    name: "Hybrid",
    value: 851,
    color: "#F6866A"
  },
  {
    labelKey: "c",
    label: "H2 Fuel Cell",
    name: "H2 Fuel Cell",
    value: 783,
    color: "#59E6F6"
  },
  {
    labelKey: "d",
    label: "Diesel",
    name: "Diesel",
    value: 410,
    color: "#7661E2"
  },
]


export let TabileTitleData = {
  RequiredPower: [
    "Required Power",
    "&nbsp;"
  ],
  WeightandSpace: [
    "Weight and Space",
    "&nbsp;"
  ],
  AnnualTCOwithoutexternalities: [
    "Annual TCO without externalities",
    "k€",
    "k€/a",
  ],
  AnnualTCOwithexternalities: [
    "Annual TCO with externalities",
    "CO2 Production",
    "Air Pollution",
  ],
}

export let RequiredPower = [
  ["Underwater Hull Volume", 2016],
  ["Underwater Hull Volume", 2016],
  ["Underwater Hull Volume", 2016],
]
export let AnnualTCOwithoutexternalities = [
  ["100% Battery", "64044 E/a", "64044 E/a"],
  ["100% Battery", "64044 E/a", "64044 E/a"],
  ["100% Battery", "64044 E/a", "64044 E/a"],

]
export let AnnualTCOwithexternalities = [
  ["100% Battery", "64044 E/a", "64044 E/a"],
  ["100% Battery", "64044 E/a", "64044 E/a"],
  ["100% Battery", "64044 E/a", "64044 E/a"],

]


export const chartColors = [
  { color: "#003f5c" },
  { color: "#dd5182" },
  { color: "#955196" },
  { color: "#444e86" },
  { color: "#ff6e54" },
  { color: "#ffa600" },
]

