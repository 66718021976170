import * as React from 'react';
import { ReportFilters, ReportFilterTags, ReportsCellTitles } from "../../misc/data";
import PaginationComp from "../common/Pagination";
import { GenericTables } from "../common/GenericTables";
import { LoadingComponent, RadioInputs, SearchOption, Tags } from "../common/CommonComponents";
import { GetReports, getReportTypeList, getReportTagList, getCountryList } from "../../request/api";
import Box from "@mui/material/Box";
import { debounce_leading } from '../../misc/commonFunctions';
import { Button } from '@mui/material';
import { SiteContext } from '../../context/SiteContext';
import LinkList from "./LinkList";


export default function GenericContentTable({ params, dashboard }) {
  const [state, setstate] = React.useState({
    viewType: "reports", // links/reports
    defaultSortKey: "",
    titleSortType: "",
    dateSortType: "",
    countryList: [],
  });
  const { viewType } = state;

  const { siteStore, dispatch } = React.useContext(SiteContext);
  let { categoryTree } = siteStore;
  const catParam = `?category=${parseInt(params.id)}`;


  const initialFilterObject = {
    per_page: viewType === "reports" ? 10 : 9,
    sortKey: "title",// title/date_of_publish.
    sortValue: "ASC", //ASC OR DESC
    page: 1,
    type: 0,
    category: parseInt(params.id),
    subcategory: 0,
    country: 0,
    tag: [],
    keyword: ""
  };

  const [loading, setloading] = React.useState(false)


  const setSort = (type) => {

    setstate(prevState => {

      let { titleSortType, dateSortType } = JSON.parse(JSON.stringify(prevState));


      if (type === "title") {
        dateSortType = ""
        if (titleSortType === "ASC" || titleSortType === "") {
          titleSortType = "DESC"
          console.log("Hit 58")
        } else if (titleSortType === "DESC") {
          titleSortType = "ASC"
        }

        sortToggle("title", titleSortType)
      } else if (type === "date_of_publish") {
        console.log("setSort hit");
        titleSortType = "";

        if (dateSortType === "ASC" || dateSortType === "") {
          dateSortType = "DESC"

        } else if (dateSortType === "DESC") {
          dateSortType = "ASC"
        }

        sortToggle("date_of_publish", dateSortType);

      }

      return {
        ...prevState,
        defaultSortKey: type,
        titleSortType,
        dateSortType
      }
    })
  }
  const [tagList, settagList] = React.useState([])
  const [response, setresponse] = React.useState({
    current_page: 0,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: '',
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  });

  const [filterObject, setFilterObject] = React.useState(initialFilterObject);

  const resetFilter = async () => {
    setFilterObject(prev => ({ ...initialFilterObject }))
  }
  const initFilter = async (filterObject) => {
    let queryStringArr = []

    queryStringArr.push(`per_page=${filterObject.per_page}`);
    queryStringArr.push(`page=${filterObject.page}`);

    if (filterObject.sortKey || filterObject.sortValue) {
      queryStringArr.push(`sortKey=${filterObject.sortKey}`);
      queryStringArr.push(`sortValue=${filterObject.sortValue}`);
    }


    if (parseInt(params.id) !== 0) {
      queryStringArr.push(`category=${parseInt(params.id)}`);
    }

    if (filterObject.subcategory !== 0) {
      queryStringArr.push(`subcategory=${filterObject.subcategory}`);
    }

    if (filterObject.tag.length !== 0) {
      queryStringArr.push(`tags=${filterObject.tag}`);
    }
    if (filterObject.keyword.length !== 0) {
      queryStringArr.push(`keyword=${filterObject.keyword}`);
    }
    if (filterObject.country.length !== 0) {
      queryStringArr.push(`country=${filterObject.country}`);
    }


    let queryString = '?' + queryStringArr.join("&");
    if (filterObject !== initialFilterObject) {
      queryString = queryString === "?" ? "" : queryString;

      setloading(true);
      let res = await GetReports(queryString, dashboard);
      if (res) {
        setresponse(res.data.data);
        setFilterObject(prev => ({ ...prev, page: res.data.data.current_page }));

      }
      setloading(false);
    }


  }
  const sortToggle = (sortKey, sortValue) => {
    setFilterObject(prev => {
      return {
        ...prev,
        sortKey,
        sortValue
      }
    });

  }
  React.useEffect(() => {
    resetFilter();
    let { id } = params;
    id = parseInt(id)
    categoryTree.forEach((item) => {
      item.category.forEach(cat => {
        console.log(item.name, cat, id)
        if (item.name === "Projects database" && cat.id === id) {

          setstate(prev => ({
            ...prev,
            viewType: "links"
          }))

          setFilterObject(prev => ({
            ...prev,
            per_page: 9
          }))

        } else if (item.name === "Resources" && cat.id === id) {
          setstate(prev => ({
            ...prev,
            viewType: "reports"
          }))
          setFilterObject(prev => ({
            ...prev,
            per_page: 10
          }))
        }
      })
    });


    (async () => {
      let res = await getCountryList();
      if (res) {
        console.log('res:', res)
        setstate(prev => ({ ...prev, countryList: res.data.data }))
      }
    })();

  }, [params.id,]);

  React.useEffect(() => {
    debounce_leading(initFilter(filterObject), 1000)
  }, [
    filterObject.sortKey,
    filterObject.sortValue,
    filterObject.per_page,
    filterObject.page,
    filterObject.subcategory,
    filterObject.country,
    filterObject.tag,
    filterObject.keyword,
  ]);

  const dataFetch = async () => {


    setloading(true);
    await GetReports(catParam, dashboard).then(async (res) => {
      if (res.data.data) {
        setresponse({
          ...res.data.data,
          per_page: viewType === "reports" ? 10 : 9,
        });
      }
      setloading(false);
      let tagList = await getReportTagList();
      tagList = tagList.data.data.map((d => ({ label: d.name, value: d.id })))
      settagList(tagList)
    });

  }


  React.useEffect(() => {
    dataFetch();
    console.log('viewType:', viewType)


  }, []);


  const handleChange = (event) => {

    setFilterObject({
      ...filterObject,
      page: 1,
      subcategory: event.target.value
    });

  };


  const handleChangeCountry = (event) => {

    setFilterObject({
      ...filterObject,
      page: 1,
      country: event.target.value
    });

  };

  const handleChangeTag = (v) => {
    let prevTag = [...filterObject.tag];
    let index = prevTag.indexOf(v);
    if (index === -1) {
      prevTag.push(v)
    } else {
      prevTag.splice(index, 1);
    }


    setFilterObject({
      ...filterObject,
      page: 1,
      tag: prevTag
    });
  };


  let subCategoryList = [];
  categoryTree.forEach((item) => {
    item.category.forEach(cat => {
      if (cat.id === parseInt(params.id)) {
        subCategoryList = cat.subcategory
      } else {

      }
    })
  });



  return (
    <section className="table-view Reports">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {!dashboard && <div className="col-md-3 col-12">
            {/*<p className="text-right">
              <Button onClick={resetFilter}>Reset</Button>
            </p>*/}
            <div className="filters">
              <div className="filter-block">
                <div className="d-flex justify-content-between align-items-start">
                  <h3 className="h4 title">Seach by filter</h3>
                  <Button onClick={resetFilter} className="btn btn-sm btn-primary text-white">Reset all</Button>
                </div>

                {viewType === "reports" && <div className="filter-cont">
                  <RadioInputs
                    list={subCategoryList}
                    value={filterObject.subcategory}
                    handleChange={handleChange}
                  />
                </div>}
                {viewType !== "reports" && <div className="filter-cont">
                  <RadioInputs
                    list={state.countryList}
                    value={filterObject.country}
                    handleChange={handleChangeCountry}
                  />
                </div>}

              </div>

              {viewType === "reports" && <div className="filter-block">
                <h3 className="h4 title">Tags</h3>
                <div className="filter-cont">
                  <Tags
                    list={tagList}
                    values={filterObject.tag}
                    handleChange={handleChangeTag}
                  />
                </div>
              </div>
              }
            </div>
          </div>}

          <div className={!dashboard ? "col-md-9 col-12" : "col-md-12 col-12"}>

            <div className="table-title-search">
              <h4 className="title">
                {params.slug.replaceAll("-", " ")}
              </h4>
              <div className="search">
                <SearchOption
                  placeholder={viewType === "reports" ? "Search by title" : "Search by projects"}
                  keyword={filterObject.keyword}
                  search={(v) => {
                    initFilter(filterObject)
                  }}
                  onChange={(v) => {
                    let newobj = {
                      ...filterObject,
                      keyword: v,
                      page: 1
                    }
                    setFilterObject(newobj);
                    if (v === "") {
                      initFilter(newobj);
                    }
                  }}
                />
              </div>




            </div>


            {loading ? <LoadingComponent /> : <>
              {viewType === "links" ? <LinkList
                dashboard={dashboard}
                rows={response.data}
                cellTitles={ReportsCellTitles}
              /> : <GenericTables
                dashboard={dashboard}
                rows={response.data}
                cellTitles={ReportsCellTitles}
                state={state}
                setSort={setSort}
              />}
            </>}

            {response.total > 10 && <Box sx={{ mt: 4 }}>
              <PaginationComp
                onChange={(e, page) => {
                  console.log('e, page:', e, page)
                  setFilterObject({
                    ...filterObject,
                    page
                  });


                }}
                page={filterObject.page}
                pageCount={Math.ceil(response.total / response.per_page)}
              />
            </Box>}

          </div>

        </div>
      </div>
    </section>
  );
}
