function PortBody() {
  return (
    <div className="portBody container">
      <div className="row justify-content-center">
        <div className="col-11 col-md-8">
          <p className="fs-5">
            The objective of the pilot of the Port of Amsterdam is to
            demonstrate the feasibility of operating an inland port vessel with
            hydrogen as a fuel. The 20 meter long vessel will operate both in
            urban areas (Amsterdam’s canals) as well as in the seaport area
            between Amsterdam and IJmuiden. The vessel will be completely
            emission-free and silent, operating with a battery and a fuel cell.
            Sodiumborohydrid will be used as hydrogen carrier, a powder diluted
            in water, which allows for safe storage and has a high flame point.
            The operational sailing experience with this innovative hydrogen
            solution should enable a quick implementation of this technology on
            board of other ship types, especially other inland vessels. Port of
            Amsterdam will contract the equipment, build the ship and operate it
            in the Amsterdam port area. Tata Steel will bring its expertise as a
            supply chain operator to this platform. University of Birmingham
            will provide technical and engineering assistance for the adaptation
            of the propulsion system and carry out assessment studies. TU Delft
            provides and coordinates the body of knowledge on Sodiumborohydrid
            system components, hydrogen generation implementation and the
            integration and testing of the system on shore and on board.
          </p>
          <div className="bg-light p-3 rounded">
            <p>Port of Amsterdam Community.</p>
            <p>
              Port of Amsterdam has an active user committee, which gathers
              local stakeholders to express their views and requirements
              regarding pilot implementation and feeding into pilot operations.
              In order to encourage the uptake of new H2-based solutions, local
              stakeholders of the sector need to understand the reality of such
              systems. The H2SHIPS pilot will enable local stakeholders to
              become active in implementing solutions and defining future
              developments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortBody;
