import EventContainer from "../components/contents/eventContent/EventContainer";

function Events() {
  return (
    <>
      <EventContainer />
    </>
  );
}

export default Events;
