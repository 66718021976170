import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { AnnualTCOwithexternalities, AnnualTCOwithoutexternalities, RequiredPower, TabileTitleData } from '../../misc/data';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button, FormGroup, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";

export function LoadingComponent() {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      m: 2,
      p: 3,
      width: "100%",
      height: 400,
    }}>
      <CircularProgress
        size={90}
      />
    </Box>
  );
}



export function SearchOption({ search, onChange, keyword, placeholder }) {

  return (
    <div className="SearchOption">
      <FormGroup row>
        <TextField
          value={keyword}
          label={placeholder}
          type="search"
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.target.value)
            if (e.target.keyword === "") {
              search("");
            }
          }}
        />
        {/* <TextField variant="outlined" placeholder="Search by keyword" /> */}
        <Button variant="contained" disableElevation
          onClick={() => { keyword.length > 0 && search(keyword) }}
        >
          Search
        </Button>
      </FormGroup>
    </div>
  );
}






export function RadioInputs({ value, handleChange, list }) {
  return (
    <div>
      <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value={0}
            key={"inputs" + "all"}
            control={<Radio />}
            label={"All"}
          />
          {list.map((v) => (
            <FormControlLabel
              value={v.id}
              key={"inputs" + v.id}
              control={<Radio />}
              label={v.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export const TagButton = styled(Button)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  backgroundColor: "#EDEDF0",
  borderRadius: 22,
  marginRight: 12,
  marginBottom: 12,
  boxShadow: "none",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#74AAF1",
  },
  "&.selected": {
    color: "#fff",
    backgroundColor: "#0B4DA2;",
  },
}));

export function Tags({ values, handleChange, list }) {
  return (
    <div className="Tags">
      <Box

        height="200px"
        style={{

          overflowY: "scroll" // added scroll
        }}
      >
        {list.map((v, i) => {

          return (<TagButton
            key={"tags" + v.value}
            variant="contained"
            className={values.includes(v.value) ? "selected" : ""}
            onClick={(e) => handleChange(v.value)}
          >
            {v.label}

          </TagButton>
          )
        })}

      </Box>
      <Typography
        textAlign="center"
        mt="15px"
        sx={{
          fontSize: "14px"
        }}>
        Scroll Down ↓

      </Typography>

    </div>
  );
}




export const WhiteButton = styled(Button)(({ theme }) => ({
  color: "#000",
  backgroundColor: "#fff",
  padding: "6px 24px",
  '&:hover': {
    backgroundColor: "#0b4da2",
    color: "#fff",

  },
}));





export function TabSystem({ tabTitleData, tabPanelData }) {


  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className='TabSystem'>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <TabList onChange={handleChange}>
            {tabTitleData.map((ttd, i) => <Tab key={"tpl" + ttd.label + tabPanelData[i].value} label={ttd.label} value={ttd.value} style={{ color: "#fff" }} />)}
          </TabList>
        </Box>
        {tabPanelData.map((tpd, i) => <TabPanel key={"tpd" + tpd.value + tabTitleData[i].label} value={tpd.value} >{tpd.component}</TabPanel>)}
      </TabContext>

    </Box>
  );
}



export const TextFieldWithAdornment = ({ value, label, onChange, rightLabel, ...props }) => {
  const inputValue = value.value;
  const { required, validationMessage } = value;
  
  return <TextField
    label={label}
    variant="filled"
    fullWidth
    value={inputValue}
    onChange={onChange}
    InputProps={{
      endAdornment: <InputAdornment position="end">{rightLabel}</InputAdornment>,
    }}
    required={required}
    error={validationMessage.length > 0}
    helperText={validationMessage}
    {...props}
  />
}
export const SelectCustom = ({ value, label, onChange, rightLabel, list, ...props }) => {
  const inputValue = value.value;
  const { required, validationMessage } = value;
  return <TextField
    select
    label={label}
    variant="filled"
    fullWidth
    value={inputValue}
    onChange={onChange}
    InputProps={{
      endAdornment: <InputAdornment position="end" style={{ marginRight: 20 }}>{rightLabel}</InputAdornment>,
    }}
    required={required}
    error={validationMessage.length > 0}
    helperText={validationMessage}
    {...props}
  >
    {list.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
  </ TextField>

}
