import NewsCardContent from "../components/contents/newsContent/NewsCardContent";
import EventContainer from '../components/contents/eventContent/EventContainer';


function News() {
  return (
    <>
      <NewsCardContent />
	  <EventContainer />
    </>
  );
}

export default News;
