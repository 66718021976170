import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

function NavBar() {
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Button
        sx={{ mr: 3 }}
        color="primary"
        variant="contained"
        onClick={() => navigate("/user-dashboard")}
      >
        Dashboard overview
      </Button>

{/*    
      <ColorButton
        color="success"
        endIcon={<AddIcon />}
        variant="outlined"
        onClick={() => navigate("/user-dashboard-form")}
      >
        Add content
      </ColorButton>*/}
    </Box>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#2E486A",
  "&:hover": {
    backgroundColor: "#2E486A",
  },
}));

export default NavBar;
