
import { Button, TextField } from "@mui/material";
import * as yup from "yup";
import { Box } from "@mui/system";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import React from "react";
import BasicDatePicker, {
  BasicSelect,
  commonPos,
  FileUploadButton,
  MultipleSelectCheckmarks,
} from "./GenericFormComponents";
import { Formik, Field } from "formik";
import { SiteContext } from "../../../context/SiteContext";
import UploadButton from "./UploadButton"
import { getReportTagList, reportStore } from "../../../request/api";
import moment from "moment";

export default function GenericForms() {
  const { siteStore, dispatch } = React.useContext(SiteContext);
  let { typeList, categoryList, categoryTree, tagList } = siteStore;


  React.useEffect(() => {
    (async () => {
      let res = await getReportTagList();
      if (res) {
        dispatch({
          type: "update_tags",
          tagList: res.data.data
        })
      }
    })();

  }, []);

  const [state, setState] = React.useState({
    status_message: {
      type: "success",
      message: "",
    },
    tag: [],
    type: categoryTree[0].id,
    category: "",
    subCategory: "",
    title: "",
    author: "",
    date_of_publish: new Date(),
    details: "",
    external_links: "",
    report_file: null,

  });


  let { type, category, subCategory, tag, title, author, date_of_publish, details, external_links, report_file } = state;
  const setData = (type, value) => {
    if (type === "dd") {

    } else {
      setState(prevState => {
        return {
          ...prevState,
          [type]: value
        }
      })
    }
  }
  return (
    <div className="GenericForms">
      <h2 className="mb-xs-64 text-center">Add Content</h2>
      <p className="mb-xs-32 text-center text-muted">
        This form is for suggesting content to add. Submissions must be approved by an admin to be publicly visible.
      </p>
      <Formik
        enableReinitialize={true}
        initialValues={state}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          let data = new FormData();
          data.append('type', values.type);
          data.append('category', values.category);
          data.append('subCategory', values.subCategory);
          data.append('tag', JSON.stringify(values.tag.map(t => t.id)));


          data.append('title', values.title);
          data.append('author', values.author);
          data.append('date_of_publish', moment(values.date_of_publish).format('YYYY-MM-DD HH:mm:ss'));
          data.append('details', values.details);
          data.append('external_links', values.external_links);
          data.append('report_file', values.report_file);

          let res = await reportStore(data);
          if (res.data) {
            setState({
              ...state,
              status_message: {
                type: "success",
                message: res.data.message,
              },
            });
          } else {
            let { message, errors } = res.response.data;

            for (const property in errors) {
              // console.log(`${property}: ${errors[property]}`);
              // message = message + ` ${property}: ${errors[property]}`
              message = message + ` ${errors[property]}`;
            }

            setState({
              ...state,
              status_message: {
                type: "error",
                message,
              },
            });
          }
        }
        }
      >
        {(props) => {
          let catTree = [];
          let subcatTree = [];

          categoryTree.forEach(t => {

            if (t.id === props.values.type) {
              catTree = t.category;
              t.category.forEach(c => {
                if (c.id === props.values.category) {

                  subcatTree = c.subcategory;
                }

              })
            }

          })
          return (
            <form onSubmit={props.handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <BasicSelect
                    select
                    label="Type"
                    dataList={categoryTree.map(t => ({ id: t.id, name: t.name }))}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="type"
                    name="type"
                    value={props.values.type}
                    onChange={(e) => {

                      props.setFieldValue("type", e.target.value);
                      props.setFieldValue("category", "");
                      props.setFieldValue("subcategory", "");
                    }}
                    error={
                      props.touched.type && Boolean(props.errors.type)
                    }
                    helperText={props.touched.type && props.errors.type}
                  />
                </div>
                <div className="col-md-4">

                  <BasicSelect
                    select
                    label="Category"
                    // dataList={categoryList}
                    dataList={catTree}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="category"
                    name="category"
                    value={props.values.category}
                    onChange={(e) => {

                      props.setFieldValue("category", e.target.value);
                      props.setFieldValue("subcategory", "");
                    }}
                    error={
                      props.touched.category && Boolean(props.errors.category)
                    }
                    helperText={props.touched.category && props.errors.category}
                  />
                </div>
                <div className="col-md-4">

                  <BasicSelect
                    select
                    label="Sub Category"
                    dataList={subcatTree}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="subCategory"
                    name="subCategory"
                    value={props.values.subCategory}
                    onChange={props.handleChange}
                    
                    error={
                      props.touched.subCategory && Boolean(props.errors.subCategory)
                    }
                    helperText={props.touched.subCategory && props.errors.subCategory}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-md-12">
                  <Autocomplete
                    multiple
                    fullWidth
                    id="tags-standard"
                    options={tagList}
                    getOptionLabel={(option) => option.name}
                    defaultValue={[]}
                    value={props.values.tag}
                    onChange={(e, v) => {
                      props.setFieldValue("tag", v)
                    }}
                    name="tag"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ ...commonPos }}
                        variant="outlined"
                        label="Tags"
                        placeholder="Tags"
                        name="tag"
                        error={
                          props.touched.tag && Boolean(props.errors.tag)
                        }
                        helperText={props.errors.tag}
                      />
                    )}
                  />
                  {/* <MultipleSelectCheckmarks
                    label="Tags"
                    dataList={tagList}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="tag"
                    name="tag"
                    value={props.values.tag}
                    onChange={props.handleChange}
                    error={
                      props.touched.tag && Boolean(props.errors.tag)
                    }
                    helperText={props.errors.tag}
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label={"Title"}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="title"
                    name="title"
                    value={props.values.title}
                    onChange={props.handleChange}
                    error={
                      props.touched.title && Boolean(props.errors.title)
                    }
                    helperText={props.touched.title && props.errors.title}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label={"Author Name"}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="author"
                    name="author"
                    value={props.values.author}
                    onChange={props.handleChange}
                    error={
                      props.touched.author && Boolean(props.errors.author)
                    }
                    helperText={props.touched.author && props.errors.author}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <BasicDatePicker
                    label={"Date of publish"}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="date_of_publish"
                    name="date_of_publish"
                    value={props.values.date_of_publish}
                    // onChange={props.handleChange}
                    onChange={(newValue) => {
                      
                      props.setFieldValue("date_of_publish", newValue);
                      
                    }}
                    // setData={setData}
                    // dataType="date_of_publish"
                    error={
                      props.touched.date_of_publish && Boolean(props.errors.date_of_publish)
                    }
                    helperText={props.touched.date_of_publish && props.errors.date_of_publish}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    multiline
                    rows={16}
                    label={"Body text starts here..."}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="details"
                    name="details"
                    value={props.values.details}
                    onChange={props.handleChange}
                    error={
                      props.touched.details && Boolean(props.errors.details)
                    }
                    helperText={props.touched.details && props.errors.details}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label={"External link"}
                    fullWidth
                    sx={{ ...commonPos }}
                    id="external_links"
                    name="external_links"
                    value={props.values.external_links}
                    onChange={props.handleChange}
                    error={
                      props.touched.external_links && Boolean(props.errors.external_links)
                    }
                    helperText={props.touched.external_links && props.errors.external_links}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Field
                    name="report_file"
                    component={UploadButton}
                    title="Upload"
                    setFieldValue={props.setFieldValue}
                    errorMessage={props.errors.report_file ? props.errors.report_file : ""}
                    touched={props.touched.report_file}
                    onBlur={props.handleBlur}
                  />
                  <small className="text-muted">
                    Maximum size: 15 MB
                  </small>                  
                {/* <FileUploadButton
                    name="report_file"
                  />
                */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">

                  <Box sx={{ mt: 3 }}>
                    <Button sx={{ mr: 3, minWidth: 230 }} variant="contained"
                      type="submit"
                    >
                      Publish
                    </Button>
                    <p
                      className={
                        state.status_message.type === "success"
                          ? "success-color mt-xs-16 fw-700"
                          : "error-color mt-xs-16 fw-700"
                      }
                    >
                      {state.status_message.message}
                    </p>
                    {/*<Button
              sx={{ minWidth: 230 }}
              variant="outlined" >Save as draft</Button>*/}
                  </Box>
                </div>
              </div>
            </form>
          )
        }}
        {/* </Slide> */}
      </Formik>
    </div>
  );
}


const validationSchema = yup.object().shape({
  type: yup.string().required("Required"),
  category: yup.string().required("Required"),
  subCategory: yup.string(),
  tag: yup.array().of(yup.object()).required("Required").min(1, "Please Select at least one tag"),
  title: yup.string().required("Required"),
  author: yup.string().required("Required"),
  date_of_publish: yup.string().required("Required"),
  details: yup.string().required("Required"),
  external_links: yup.string().matches(
    // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    'Enter correct url (add http/https)!'
  ).required('Please enter url'),
  report_file: yup.mixed()
    .required("File Is required!")
    .test(
      "fileSize",
      "Your file is too big :(",
      value => value && value.size <= 262144000
    )
});
