import * as React from 'react';
import Box from "@mui/material/Box";
/* eslint-disable jsx-a11y/anchor-is-valid */
import newsImg1 from "../../../assets/images/newsImg1.jpg";
 import newsImg2 from "../../../assets/images/newsImg2.jpg";
 import newsImg3 from "../../../assets/images/newsImg3.jpg";
 import newsImg4 from "../../../assets/images/newsImg4.jpg";
//import PaginationComp from "../../common/Pagination";
import { LoadingComponent, RadioInputs, SearchOption, Tags } from "../../common/CommonComponents";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { SiteContext } from '../../../context/SiteContext';


export default function ShippingTable() {

//	 const { siteStore, dispatch } = React.useContext(SiteContext);
//  let { categoryTree } = siteStore;

//	 const initialFilterObject = {
//    per_page: 10,
//    page: 1,
//    type: 0,
//    category: parseInt(params.id),
//    subcategory: 0,
//    tag: [],
//    keyword: ""
//  };


	
//	const [filterObject, setFilterObject] = React.useState(initialFilterObject);
	
//	const resetFilter = async () => {
//		setFilterObject(prev => ({ ...initialFilterObject }))
//	}

//	  const handleChange = (event) => {

//		setFilterObject({
//		...filterObject,
//		subcategory: event.target.value
//		});

//	};


//	  let subCategoryList = [];
//		categoryTree.forEach((item) => {
//			item.category.forEach(cat => {
//			if (cat.id === parseInt(params.id)) {
//				subCategoryList = cat.subcategory
//			} else {

//			}
//			})
//		});

  return (
    <div className="linksCard container-fluid">

 		<div className="row">

	 	<div className="col-md-3 col-12">
			 <div className="filters">
              <div className="filter-block">
                <div className="d-flex justify-content-between align-items-start">
                  <h3 className="h4 title">Seach by filter</h3>
                  <Button onClick={""} className="btn btn-sm btn-primary text-white">Reset all</Button>
                </div>
                <div className="filter-cont">
                 <RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					defaultValue="female"
					name="radio-buttons-group"
				>
					<FormControlLabel value="Sample1" control={<Radio />} label="Sample 1" />
					<FormControlLabel value="Sample2" control={<Radio />} label="Sample 2" />
					<FormControlLabel value="Sample3" control={<Radio />} label="Sample 3" />
				</RadioGroup>
                </div>
              </div>

            
            </div>
		</div>
        <div className="col-md-9 col-12">

		<div className="table-title-search">
              <h4 className="title">
                Shipping Projects
              </h4>
              <div className="search">
                <SearchOption
                  
                />
              </div>

            </div>

			{/*Links card row starts*/}
           <div className="row">
              
            <div className="col-md-6 col-12 linksCard__col">
              
			  <a href="#" className="linksCard__link text-dark" target="_blank" rel="noreferrer">
                <div className="card linksCard__card shadow-sm">
                  <div className="linksCard__imgWrapper">
                    <img
                      src={newsImg2}
                      className="card-img-top linksCard__img"
                      alt="..."
                    />

					<Chip 
					label="Ship: CSHIPP" 
					variant="filled"
                    sx={{ background: "#fff" }} 
					className='linksCard__chip'
					/>
					  
                  </div>
                  <div className="card-body linksCard__cardBody">
                    <p className="card-text linksCard__cardText">
                     Project: <b>Clean Shipping Project Platform</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Country: <b>England</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Project time: <b>1.10.2018 - 31.3.2021</b>
                    </p>
					<div className="linksCard__fuelDiv">
						<div className="linksCard__fuelText">
							<p className="card-text linksCard__cardText">
							Fuel: <b>Fuel</b>
							</p>
							<p className="card-text linksCard__cardText">
							Fuel cell: <b>Fuel Cell</b>
							</p>
						</div>

						 <a href="#" className="btn btn-sm btn-read text-white">Read More</a>

					</div>
                  </div>
                </div>
              </a>
            </div>


            <div className="col-md-6 col-12 linksCard__col">
              
			  <a href="#" className="linksCard__link text-dark" target="_blank" rel="noreferrer">
                <div className="card linksCard__card shadow-sm">
                  <div className="linksCard__imgWrapper">
                    <img
                      src={newsImg1}
                      className="card-img-top linksCard__img"
                      alt="..."
                    />

					<Chip 
					label="Ship: CSHIPP" 
					variant="filled"
                    sx={{ background: "#fff" }} 
					className='linksCard__chip'
					/>
					  
                  </div>
                  <div className="card-body linksCard__cardBody">
                    <p className="card-text linksCard__cardText">
                     Project: <b>Clean Shipping Project Platform</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Country: <b>England</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Project time: <b>1.10.2018 - 31.3.2021</b>
                    </p>
					<div className="linksCard__fuelDiv">
						<div className="linksCard__fuelText">
							<p className="card-text linksCard__cardText">
							Fuel: <b>Fuel</b>
							</p>
							<p className="card-text linksCard__cardText">
							Fuel cell: <b>Fuel Cell</b>
							</p>
						</div>

						 <a href="#" className="btn btn-sm btn-read text-white">Read More</a>

					</div>
                  </div>
                </div>
              </a>
            </div>

			<div className="col-md-6 col-12 linksCard__col">
              
			  <a href="#" className="linksCard__link text-dark" target="_blank" rel="noreferrer">
                <div className="card linksCard__card shadow-sm">
                  <div className="linksCard__imgWrapper">
                    <img
                      src={newsImg3}
                      className="card-img-top linksCard__img"
                      alt="..."
                    />

					<Chip 
					label="Ship: CSHIPP" 
					variant="filled"
                    sx={{ background: "#fff" }} 
					className='linksCard__chip'
					/>
					  
                  </div>
                  <div className="card-body linksCard__cardBody">
                    <p className="card-text linksCard__cardText">
                     Project: <b>Clean Shipping Project Platform</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Country: <b>England</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Project time: <b>1.10.2018 - 31.3.2021</b>
                    </p>
					<div className="linksCard__fuelDiv">
						<div className="linksCard__fuelText">
							<p className="card-text linksCard__cardText">
							Fuel: <b>Fuel</b>
							</p>
							<p className="card-text linksCard__cardText">
							Fuel cell: <b>Fuel Cell</b>
							</p>
						</div>

						 <a href="#" className="btn btn-sm btn-read text-white">Read More</a>

					</div>
                  </div>
                </div>
              </a>
            </div>

			<div className="col-md-6 col-12 linksCard__col">
              
			  <a href="#" className="linksCard__link text-dark" target="_blank" rel="noreferrer">
                <div className="card linksCard__card shadow-sm">
                  <div className="linksCard__imgWrapper">
                    <img
                      src={newsImg4}
                      className="card-img-top linksCard__img"
                      alt="..."
                    />

					<Chip 
					label="Ship: CSHIPP" 
					variant="filled"
                    sx={{ background: "#fff" }} 
					className='linksCard__chip'
					/>
					  
                  </div>
                  <div className="card-body linksCard__cardBody">
                    <p className="card-text linksCard__cardText">
                     Project: <b>Clean Shipping Project Platform</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Country: <b>England</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                     Project time: <b>1.10.2018 - 31.3.2021</b>
                    </p>
					<div className="linksCard__fuelDiv">
						<div className="linksCard__fuelText">
							<p className="card-text linksCard__cardText">
							Fuel: <b>Fuel</b>
							</p>
							<p className="card-text linksCard__cardText">
							Fuel cell: <b>Fuel Cell</b>
							</p>
						</div>

						 <a href="#" className="btn btn-sm btn-read text-white">Read More</a>

					</div>
                  </div>
                </div>
              </a>
            </div>
            
           
		   </div>

		</div>

		   {/*Links card row ends*/}

		   </div>
        </div>

      
  
    
  );
}

