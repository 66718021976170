import React from 'react'
import { Button } from "@mui/material"

import { useNavigate, useSearchParams } from "react-router-dom";
import axios, { VERIFY_USER_EMAIL } from '../request/api';
import { LoadingComponent } from '../components/common/CommonComponents';

export default function VerifyEmailAddress() {
  let [searchParams, setuseSearchParams] = useSearchParams();

  const [status, setStatus] = React.useState("loading");
  const [message, setMessage] = React.useState("Your email address is verified");
  let navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      let token = searchParams.get("token");
      if (token) {
        setStatus("loading");

        let res = await VERIFY_USER_EMAIL(token);
        if (res.data) {
          if (res) {
            setStatus("success");
          }
        } else {
          setStatus("error");
          if (res.response.data) {
            setMessage(res.response.data.message)
          } else {
            setMessage("Unable to verify the email address")

          }
        }

      }
    })();
  }, []);
  return (
    status === "loading" ? <LoadingComponent /> : <div className='VerifyEmailAddress pt-xs-60 pb-xs-60'>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {status === "success" ? <div className="primaryColor-color">
              <h1>Success</h1>
              <h3>{message}</h3>
            </div> :
              <div className="error-color mt-xs-32">
                <h1>Error !!</h1>
                <h3>{message}</h3>

              </div>
            }
            <Button onClick={() => navigate("/login")} className="mt-xs-30" variant='contained'>login</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
