import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CalcDataTable from './CalcDataTable';
import CalculatorBars from './CalculatorBars';
import { AnnualTCOwithexternalities, AnnualTCOwithoutexternalities, RequiredPower, TabileTitleData } from '../../misc/data';
import CalculatorSTACKEDbars from './CalculatorSTACKEDbars';
import { Button } from '@mui/material';
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import navLogo from "../../assets/images/navLogo.png";
import { ErrorBoundary } from '../../misc/commonFunctions';
import ParamsView from './ParamsView';


export default function TabsAndTables({ state, reset }) {
  const { result } = state;
  const downloadFile = async () => {
    var WeightAndVolume = document.getElementById('WeightAndVolume');
    var TotalCostOfOwnership = document.getElementById('TotalCostOfOwnership');
    var ParamsView = document.getElementById('ParamsView');

    // var doc = new jsPDF("p",undefined,"letter");
    var doc = new jsPDF();




    domtoimage.toPng(WeightAndVolume)
      .then(function (dataUrl) {
        doc.addImage(navLogo, 'png', 40, 90, 126.469, 50.938);
        doc.addPage();
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        doc.addImage(dataUrl, 'png', 10, 10, width - 10, height - 10 - 80);



        domtoimage.toPng(TotalCostOfOwnership)
          .then((dataUrl) => {

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addPage();
            doc.addImage(dataUrl, 'png', 10, 10, width - 10, height - 130);

            domtoimage.toPng(ParamsView)
            .then((dataUrl) => {
  
              var width = doc.internal.pageSize.getWidth();
              var height = doc.internal.pageSize.getHeight();
              doc.addPage();
              doc.addImage(dataUrl, 'png', 10, 10, width - 10, height - 100);
              doc.save('result.pdf');
  
            })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            });
  
            

          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });



      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });


  }
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className='TabsAndTables'

    >

      <div id="PdfView">
        <div className='pdf-logo' id='pdf-logo'>

          <Box
            component="img"
            sx={{
              height: 55,
              width: 150,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Logo"
            src={navLogo}

          />
        </div>
        <h4 className="mb-xs-32">Weight and space feasibility analsysis</h4>
        <WeightAndVolume result={result} width={1100} id={true} />
        <h4 className="mb-xs-32">Total cost of ownership</h4>
        <TotalCostOfOwnership result={result} width={1100} id={true} />

        <ParamsView state={state} pdf={true} />


      </div>

      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 2
          }}>
          <TabList
            onChange={handleChange} aria-label="lab API tabs example"
            centered
          >
            <Tab label="weight & volume" value="1" />
            <Tab label="total cost of ownership" value="2" />
            {/* <Tab label="Entered Parameters" value="3" /> */}

          </TabList>
        </Box>
        <TabPanel value="1">
          <WeightAndVolume result={result} />

        </TabPanel>
        <TabPanel value="2">

          <TotalCostOfOwnership result={result} />

        </TabPanel>
        {/* <TabPanel value="3">

          <ErrorBoundary>
            <ParamsView state={state} />
          </ErrorBoundary>

        </TabPanel> */}

      </TabContext>
      <div className="result-action-buttons">
        <Button onClick={async () => {
          reset()

        }}
          fullWidth variant="outlined" >Start new analysis</Button>
        <Button
          onClick={async () => {
            downloadFile()

          }}
          fullWidth variant="contained">Download</Button>
      </div>
    </Box>
  );
}


export function TotalCostOfOwnership({ result, width, id }) {

  let { annualTCO } = result.data;
  let { withoutExternalitiesResult, withExternalitiesResult, withoutExternalities, withExternalities, withoutExternalitiesGraph, withExternalitiesGraph } = annualTCO;

  return (
    <div className="row TotalCostOfOwnership" id={id ? "TotalCostOfOwnership" : ""} style={{ width }}>
      <div className="col-md-6">
        <h5>Annual TCO without externalities</h5>
        <CalculatorSTACKEDbars
          data={
            [
              {
                "label": "Battery",
                ...withoutExternalitiesGraph.battery
              },
              {
                "label": "H2 Fuel Cell",
                ...withoutExternalitiesGraph.h2FuelCell
              },
              {
                "label": "Hybrid",
                ...withoutExternalitiesGraph.hybrid
              },
              {
                "label": "Diesel",
                ...withoutExternalitiesGraph.diesel
              },
              {
                "label": "LNG",
                ...withoutExternalitiesGraph.lng
              }
            ]
          }
          type="withoutExternalities"
        />
      </div>
      <div className="col-md-6">
        <h5>Annual TCO with externalities </h5>
        <CalculatorSTACKEDbars
          data={
            [
              {
                "label": "Battery",
                ...withExternalitiesGraph.battery
              },
              {
                "label": "H2 Fuel Cell",
                ...withExternalitiesGraph.h2FuelCell
              },
              {
                "label": "Hybrid",
                ...withExternalitiesGraph.hybrid
              },
              {
                "label": "Diesel",
                ...withExternalitiesGraph.diesel
              },
              {
                "label": "LNG",
                ...withExternalitiesGraph.lng
              }
            ]
          }
          type="withExternalities"
        />
      </div>

      <div className="row mt-xs-32">
        <div className="col-md-6">
          <CalcDataTable
            type="AnnualTCOwithoutexternalities"
            titleList={[
              "Annual TCO without externalities",
              "k€",
              "k€/a",
            ]}

            rows={[
              ["100% Battery", `${withoutExternalitiesResult.batteryAnnual}`, `${withoutExternalitiesResult.battery}`],
              ["100% Fuel Cell", `${withoutExternalitiesResult.h2FuelCellAnnual}`, `${withoutExternalitiesResult.h2FuelCell}`],
              ["Hybrid", `${withoutExternalitiesResult.hybridAnnual}`, `${withoutExternalitiesResult.hybrid}`],
              ["Diesel", `${withoutExternalitiesResult.dieselAnnual}`, `${withoutExternalitiesResult.diesel}`],
              ["LNG", `${withoutExternalitiesResult.lngAnnual}`, `${withoutExternalitiesResult.lng}`],
            ]}
          />
        </div>
        <div className="col-md-6">

          <CalcDataTable
            titleList={[
              "Annual TCO with externalities",
              "CO2 Production",
              "Air Pollution",
            ]}

            rows={[
              ["100% Battery", `${Number(withExternalitiesGraph.battery.co2ProductionCost).toFixed(2)} €`, `${withExternalitiesGraph.battery.airPollution} €/a`],
              ["100% Fuel Cell", `${withExternalitiesGraph.h2FuelCell.co2ProductionCost} €`, `${withExternalitiesGraph.h2FuelCell.airPollution} €/a`],
              ["Hybrid", `${Number(withExternalitiesGraph.hybrid.co2ProductionCost).toFixed(2)} €`, `${withExternalitiesGraph.hybrid.airPollution} €/a`],
              ["Diesel", `${Number(withExternalitiesGraph.diesel.co2ProductionCost).toFixed(2)} €`, `${Number(withExternalitiesGraph.diesel.airPollution).toFixed(2)} €/a`],
              ["LNG", `${Number(withExternalitiesGraph.lng.co2ProductionCost).toFixed(2)} €`, `${Number(withExternalitiesGraph.lng.airPollution).toFixed(2)} €/a`]

            ]}
          />
        </div>
      </div>

    </div>
  )
}



export function WeightAndVolume({ result, width, id }) {

  console.log('result:', result)
  let { weightResult, spaceResult, weight, space, requiredPower_Pr, underwater_hull_volume, resistant_coefficient } = result.data;


  return (
    <div className="row WeightAndVolume" id={id ? "WeightAndVolume" : ""} style={{ width }}>
      <div className="col-md-6"  >
        <CalculatorBars
          type="Weight"
          unit={weight.unit}
          max={{
            value: weight.values.limit,
            label: "max"
          }}
          data={
            [
              {
                labelKey: "a",
                label: "Battery",
                name: "Battery",
                value: weight.values.battery,
                color: "#FABE7A"
              },
              {
                labelKey: "b",
                label: "Hybrid",
                name: "Hybrid",
                value: weight.values.hybrid,
                color: "#F6866A"
              },
              {
                labelKey: "c",
                label: "H2FCell",
                name: "H2 Fuel Cell",
                value: weight.values.h2FuelCell,
                color: "#59E6F6"
              },
              {
                labelKey: "d",
                label: "Diesel",
                name: "Diesel",
                value: weight.values.diesel,
                color: "#7661E2"
              },
              {
                labelKey: "e",
                label: "LNG",
                name: "LNG",
                value: weight.values.lng,
                color: "#ff9800"
              }
            ]
          }
        />
      </div>
      <div className="col-md-6" >
        <CalculatorBars
          type="Space"
          unit={space.unit}
          max={{
            value: space.values.limit,
            label: "max"
          }}
          data={
            [

              {
                labelKey: "a",
                label: "Battery",
                name: "Battery",
                value: space.values.battery,
                color: "#FABE7A"
              },
              {
                labelKey: "b",
                label: "Hybrid",
                name: "Hybrid",
                value: space.values.hybrid,
                color: "#F6866A"
              },
              {
                labelKey: "c",
                label: "H2FCell",
                name: "H2 Fuel Cell",
                value: space.values.h2FuelCell,
                color: "#59E6F6"
              },
              {
                labelKey: "d",
                label: "Diesel",
                name: "Diesel",
                value: space.values.diesel,
                color: "#7661E2"
              },
              {
                labelKey: "e",
                label: "LNG",
                name: "LNG",
                value: space.values.lng,
                color: "#ff9800"
              },

            ]}
        />
      </div>
      <div className="col-md-6">

        <CalcDataTable
          type="RequiredPower"
          unit={""}
          titleList={TabileTitleData.RequiredPower}
          rows={[
            ["Underwater Hull Volume", `${underwater_hull_volume} m3`],
            ["Resistance Coefficient", `${resistant_coefficient} `],
            ["Required Power", `${requiredPower_Pr} KW`],
          ]}
        />
      </div>
      <div className="col-md-12">
        <h4 className="mb-xs-32">Weight and space feasibility analsysis</h4>
        <div className="row">
          <div className="col-md-6">
            <CalcDataTable
              titleList={[
                "Weight",
                "&nbsp;"
              ]}
              rows={[
                ["100% Battery", weightResult.battery],
                ["100% Fuel Cell", weightResult.h2FuelCell],
                ["Hybrid", weightResult.hybrid],
                ["Diesel", weightResult.diesel],
                ["Lng", weightResult.lng],
              ]}
              unit={""}
            />
          </div>
          <div className="col-md-6">
            <CalcDataTable
              titleList={[
                "Space",
                "&nbsp;"
              ]}
              rows={[
                ["100% Battery", spaceResult.battery],
                ["100% Fuel Cell", spaceResult.h2FuelCell],
                ["Hybrid", spaceResult.hybrid],
                ["Diesel", spaceResult.diesel],
                ["Lng", spaceResult.lng],
              ]}
              unit={""}
            />
          </div>
        </div>

      </div>
    </div>

  )
}
