import React from 'react'
import { getNextStep, getPrevStep, isThisStep_Valid, toast } from '../../misc/commonFunctions';
import { CalculatorStepsDATA_KEY_MAP } from '../../misc/data';
import { calculate } from '../../request/api';
import { LoadingComponent, WhiteButton } from '../common/CommonComponents';
import CapacityDimensioning from './CapacityDimensioning';
import OperationalProfile from './OperationalProfile';
import PropulsionPower from './PropulsionPower';
import { cloneDeep } from 'lodash';
import { requestObject } from '../../misc/requestObject';


export default function StepsInputOptions({ state, setState, }) {
  let { selectedStep, userType, CalculatorSteps } = state;





  const getResults = async () => {
    setState(prev => ({ ...prev, selectedStep: "loading" }));
    let request = {};

    for (let i = 0; i < CalculatorSteps.length; i++) {
      let step = CalculatorSteps[i];
      for (const property in step.inputType) {

        if (!isNaN(step.inputType[property].value)) {
          request[property] = parseFloat(step.inputType[property].value)
        } else {
          request[property] = step.inputType[property].value
        }
      }
    }
    try {
      let result = await calculate(request);
      console.log('result:', result)

      setState(prev => ({
        ...prev,
        selectedStep: "result",
        result: result.data
      }));
    } catch (error) {
      setState(prev => ({ ...prev, selectedStep: "Capacity_Dimensioning" }));
      if (error.response) {
        toast.error(error.response.data.message)
        for (const prop in error.response.data.errors) {

          console.error(prop, "required")
          console.log('error.response.data.errors[prop]:', error.response.data.errors[prop])
          for (let i = 0; i < error.response.data.errors[prop].length; i++) {
            toast.error(error.response.data.errors[prop][i])
          }


        }

      }
    }
  }


  React.useEffect(() => {
    let data = [];
    if (localStorage.getItem("Propulsion_Power")) {
      let localData = JSON.parse(localStorage.getItem("Propulsion_Power"));
      for (const prop in localData.inputType) {
        localData.inputType[prop].validationMessage = "";
      }
      CalculatorSteps[0] = localData
      setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }))
    }
    if (localStorage.getItem("Operational_Profile")) {
      let localData = JSON.parse(localStorage.getItem("Operational_Profile"));
      for (const prop in localData.inputType) {
        localData.inputType[prop].validationMessage = "";
      }
      CalculatorSteps[1] = localData
      setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }))
    }
    if (localStorage.getItem("Capacity_Dimensioning")) {
      let localData = JSON.parse(localStorage.getItem("Capacity_Dimensioning"));
      for (const prop in localData.inputType) {
        localData.inputType[prop].validationMessage = "";
      }
      CalculatorSteps[2] = localData
      setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }))
    }


  }, [])
  const gotoNextForm = () => {
    if (isThisStep_Valid(selectedStep, CalculatorSteps, setState)) {
      if (selectedStep === "Capacity_Dimensioning") {
        getResults();
      } else {
        setState(prev => ({ ...prev, selectedStep: getNextStep(selectedStep) }))
      }
    }
  }

  const gotoPrevForm = () => {
    setState(prev => ({ ...prev, selectedStep: getPrevStep(selectedStep) }))
  }
  const buttonDisabled = (type) => {
    if ((type === "next" || type === "prev") && selectedStep === "loading") {
      return true
    }
    if (type === "prev" && selectedStep === "Propulsion_Power") {
      return true
    } else if (type === "next" && selectedStep === "Propulsion_Power") {
      return false
    }

    return false;
  }


  return (
    <div className='StepsInputOptions'>

      <div className="steps-body">
        {selectedStep === "Propulsion_Power" && <PropulsionPower
          state={state}
          setState={setState}
        />}
        {selectedStep === "Operational_Profile" && <OperationalProfile
          state={state}
          setState={setState}
        />}
        {selectedStep === "Capacity_Dimensioning" && <CapacityDimensioning
          state={state}
          setState={setState}
        />}
        {selectedStep === "loading" && <LoadingComponent />}




      </div>
      <div className="steps-nav">
        <div className="d-flex justify-content-end">
          <WhiteButton
            onClick={gotoPrevForm}
            disabled={buttonDisabled("prev")}
          >Prev</WhiteButton>
          <WhiteButton
            onClick={gotoNextForm}
            disabled={buttonDisabled("next")}
            className='ml-xs-8'>{selectedStep === "Capacity_Dimensioning" ? "Show Result" : "Next"} </WhiteButton>
        </div>
      </div>
    </div>
  )
}
