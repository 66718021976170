import React from 'react'
import { handleChange } from '../../misc/commonFunctions';
import { TabSystem, TextFieldWithAdornment } from '../common/CommonComponents';

export default function AdvancedParameterForWeightAndSpaceFeasibilityAnalysis({ state, setState, }) {
  let { CalculatorSteps, userType } = state;

  const data = CalculatorSteps[2].inputType;

  return (
    <div className="row mt-xs-32">
    <div className="col-lg-12">
      <h4 className='color-w'>Advanced parameter for weight and space feasibility analysis</h4>
      <div className="row">
        <div className="col-lg-12">
          <div className="block-level-1">
            <h4 className='block-title'>Engine Trend Line (Y=aX+b)</h4>
            <TabSystem
              tabTitleData={[{
                value: 1,
                label: "Specific Power - Energy"
              },
              {
                value: 2,
                label: "Power Energy density"
              }
              ]}
              tabPanelData={
                [
                  {
                    value: 1,
                    component: <div className='row'>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Diesel Engine  </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.specificPowerEnergyDieselEngineA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyDieselEngineA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.specificPowerEnergyDieselEngineB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyDieselEngineB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">LNG Engine   </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.specificPowerEnergyLngEngineA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyLngEngineA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.specificPowerEnergyLngEngineB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyLngEngineB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Electric Motor   </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.specificPowerEnergyElectricMotorA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyElectricMotorA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.specificPowerEnergyElectricMotorB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyElectricMotorB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Converter    </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.specificPowerEnergyConverterA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyConverterA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.specificPowerEnergyConverterB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyConverterB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">VFD    </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.specificPowerEnergyVFDA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyVFDA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.specificPowerEnergyVFDB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyVFDB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Fuel Cell    </label>

                          <TextFieldWithAdornment
                            label="A"
                            value={data.specificPowerEnergyFuelCellA}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyFuelCellA", 2)
                            }}
                            rightLabel="KW ⇒ KG"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Battery     </label>

                          <TextFieldWithAdornment
                            label="A"
                            value={data.specificPowerEnergyBatteryA}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "specificPowerEnergyBatteryA", 2)
                            }}
                            rightLabel="KW ⇒ KG"
                          />
                        </div>
                      </div>
                    </div>
                  },
                  {
                    value: 2,
                    component: <div className='row'>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Diesel Engine  </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.powerEnergyDensityDieselEngineA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityDieselEngineA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.powerEnergyDensityDieselEngineB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityDieselEngineB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">LNG Engine   </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.powerEnergyDensityLngEngineA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityLngEngineA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.powerEnergyDensityLngEngineB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityLngEngineB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Electric Motor   </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.powerEnergyDensityElectricMotorA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityElectricMotorA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.powerEnergyDensityElectricMotorB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityElectricMotorB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Converter    </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.powerEnergyDensityConverterA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityConverterA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.powerEnergyDensityConverterB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityConverterB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">VFD    </label>
                          <div className="dual-input">
                            <TextFieldWithAdornment
                              label="A"
                              value={data.powerEnergyDensityVFDA}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityVFDA", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                            <TextFieldWithAdornment
                              label="B"
                              value={data.powerEnergyDensityVFDB}
                              onChange={e => {
                                handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityVFDB", 2)
                              }}
                              rightLabel="KW ⇒ KG"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Fuel Cell    </label>

                          <TextFieldWithAdornment
                            label="A"
                            value={data.powerEnergyDensityFuelCellA}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityFuelCellA", 2)
                            }}
                            rightLabel="KW ⇒ KG"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <label className="h2-input-group_title">Battery     </label>

                          <TextFieldWithAdornment
                            label="A"
                            value={data.powerEnergyDensityBatteryA}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "powerEnergyDensityBatteryA", 2)
                            }}
                            rightLabel="KW ⇒ KG"
                          />
                        </div>
                      </div>
                    </div>
                  },
                 


                ]
              }
            />

          </div>

          <div className="block-level-1">
            <h4 className='block-title'>Fuel Storage</h4>
            <TabSystem
              tabTitleData={[{
                value: 1,
                label: "Hydrogen"
              },
              {
                value: 2,
                label: "LNG"
              },
              {
                value: 3,
                label: "Diesel"
              }
              ]}
              tabPanelData={
                [
                  {
                    value: 1,
                    component: <div className='inner-tab'>
                      <TabSystem
                        tabTitleData={[{
                          value: 1,
                          label: "Compressed Hydrogen"
                        },
                        {
                          value: 2,
                          label: "Ammonia"
                        },
                        {
                          value: 3,
                          label: "Metal Hydrides "
                        },
                        {
                          value: 4,
                          label: "Liquid Hydrogen"
                        }
                        ]}
                        tabPanelData={
                          [
                            {
                              value: 1,
                              component: <div className='row'>
                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Hydrogen (70MPa)  </label>
                                    <div className="dual-input">
                                      <TextFieldWithAdornment
                                        label="A (weight)"
                                        value={data.fuelStorageCompressedHydrogen70MpaWeight}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageCompressedHydrogen70MpaWeight", 2)
                                        }}
                                        rightLabel="kg storage/kg H2"
                                      />
                                      <TextFieldWithAdornment
                                        label="B (volume)"
                                        value={data.fuelStorageCompressedHydrogen70MpaVolume}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageCompressedHydrogen70MpaVolume", 2)
                                        }}
                                        rightLabel="kg storage/kg H2"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Hydrogen (50MPa)  </label>
                                    <div className="dual-input">
                                      <TextFieldWithAdornment
                                        label="A (weight)"
                                        value={data.fuelStorageCompressedHydrogen50MpaWeight}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageCompressedHydrogen50MpaWeight", 2)
                                        }}
                                        rightLabel="kg storage/kg H2"
                                      />
                                      <TextFieldWithAdornment
                                        label="B (volume)"
                                        value={data.fuelStorageCompressedHydrogen50MpaVolume}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageCompressedHydrogen50MpaVolume", 2)
                                        }}
                                        rightLabel="kg storage/kg H2"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Hydrogen (35MPa)  </label>
                                    <div className="dual-input">
                                      <TextFieldWithAdornment
                                        label="A (weight)"
                                        value={data.fuelStorageCompressedHydrogen35MpaWeight}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageCompressedHydrogen35MpaWeight", 2)
                                        }}
                                        rightLabel="kg storage/kg H2"
                                      />
                                      <TextFieldWithAdornment
                                        label="B (volume)"
                                        value={data.fuelStorageCompressedHydrogen35MpaVolume}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageCompressedHydrogen35MpaVolume", 2)
                                        }}
                                        rightLabel="kg storage/kg H2"
                                      />
                                    </div>
                                  </div>
                                </div>

                              </div>
                            },
                            {
                              value: 2,
                              component: <div className='row'>
                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">NH3 Cracker   </label>
                                    <div className="dual-input">
                                      <TextFieldWithAdornment
                                        label="A"
                                        value={data.fuelStorageAmmoniaNH3CrackerWeight}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageAmmoniaNH3CrackerWeight", 2)
                                        }}
                                        rightLabel="kg/KW"
                                      />
                                      <TextFieldWithAdornment
                                        label="B"
                                        value={data.fuelStorageAmmoniaNH3CrackerVolume}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageAmmoniaNH3CrackerVolume", 2)
                                        }}
                                        rightLabel="m^3/kw"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Tank Volumetric Storage Density    </label>
                                    <TextFieldWithAdornment
                                      label="A"
                                      value={data.fuelStorageAmmoniaTankVolumetricStorageDensity}
                                      onChange={e => {
                                        handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageAmmoniaTankVolumetricStorageDensity", 2)
                                      }}
                                      rightLabel="kWhNh3/m^3"
                                    />

                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Tank specific Weight     </label>
                                    <TextFieldWithAdornment
                                      label="A"
                                      value={data.fuelStorageAmmoniaTankSpecificWeight}
                                      onChange={e => {
                                        handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageAmmoniaTankSpecificWeight", 2)
                                      }}
                                      rightLabel="kg/m^3"
                                    />

                                  </div>
                                </div>



                              </div>
                            },
                            {
                              value: 3,
                              component: <div className='row'>
                                <div className="col-lg-12">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Metal Hydrides    </label>
                                    <div className="dual-input">
                                      <TextFieldWithAdornment
                                        label="A"
                                        value={data.fuelStorageMetalHydridesWeight}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageMetalHydridesWeight", 2)
                                        }}
                                        rightLabel="Kg storeage/ kg h2"
                                      />
                                      <TextFieldWithAdornment
                                        label="B"
                                        value={data.fuelStorageMetalHydridesVolume}
                                        onChange={e => {
                                          handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageMetalHydridesVolume", 2)
                                        }}
                                        rightLabel="KG H2/m^3 storage"
                                      />
                                    </div>
                                  </div>
                                </div>


                              </div>
                            },
                            {
                              value: 4,
                              component: <div className='row'>
                                <div className="col-lg-6">
                                  <div className="h2-input-group">
                                    <label className="h2-input-group_title">Volumetric Storage Density     </label>
                                    <TextFieldWithAdornment
                                      label="A"
                                      value={data.fuelStorageLiquidHydrogenVolumetricStorageDensity}
                                      onChange={e => {
                                        handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageLiquidHydrogenVolumetricStorageDensity", 2)
                                      }}
                                      rightLabel="kgH@/m^3 storage"
                                    />
                                  </div>
                                </div>


                              </div>
                            },


                          ]
                        }
                      />
                    </div>
                  },
                  {
                    value: 2,
                    component: <div className='row'>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <TextFieldWithAdornment
                            label="LNG liquid"
                            value={data.fuelStorageDensityLngMass}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageDensityLngMass", 2)
                            }}
                            rightLabel="kg/l"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <TextFieldWithAdornment
                            label="Tank Volume for 1 liter of fuel  "
                            value={data.fuelStorageMassTankLng}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageMassTankLng", 2)
                            }}
                            rightLabel="m^3 storage/ Liter (Fuel)"
                          />
                        </div>
                      </div>
                    </div>
                  },
                  {
                    value: 3,
                    component: <div className='row'>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <TextFieldWithAdornment
                            label="Diesel "
                            value={data.fuelStorageDensityDieselMass}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageDensityDieselMass", 2)
                            }}
                            rightLabel="kg/l"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h2-input-group">
                          <TextFieldWithAdornment
                            label="Tank Volume for 1 liter of fuel  "
                            value={data.fuelStorageMassTankDiesel}
                            onChange={e => {
                              handleChange(e.target.value, setState, CalculatorSteps, "fuelStorageMassTankDiesel", 2)
                            }}
                            rightLabel="m^3 storage/ Liter (Fuel)"
                          />
                        </div>
                      </div>
                    </div>
                  },


                ]
              }
            />

          </div>

        </div>
      </div>
    </div>
  </div>
  )
}
