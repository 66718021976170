import React from 'react'
import { handleChange } from '../../misc/commonFunctions';
import { TabSystem, TextFieldWithAdornment } from '../common/CommonComponents';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function Efficiency({ state, setState, }) {
  let { CalculatorSteps, userType } = state;

  const data = CalculatorSteps[2].inputType;

  return (

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Efficiency</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="h2-input-group">
          <label className="h2-input-group_title">Efficiency</label>
          <TabSystem
            tabTitleData={[{
              value: 1,
              label: "100% Battery"
            },
            {
              value: 2,
              label: "H2 Fuel cell"
            },
            {
              value: 3,
              label: "Hybrid"
            },
            {
              value: 4,
              label: "Diesel"
            },
            {
              value: 5,
              label: "LNG"
            }
            ]}
            tabPanelData={
              [
                {
                  value: 1,
                  component: <div className='row'>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="E-motor"
                        value={data.efficiencyBatteryEmotor}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyBatteryEmotor", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Round Trip Battery Efficiency"
                        value={data.efficiencyBatteryRoundTrip}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyBatteryRoundTrip", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Converter"
                        value={data.efficiencyBatteryConverter}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyBatteryConverter", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="Speed Controller"
                        value={data.efficiencyBatterySpeedController}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyBatterySpeedController", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                  </div>
                },
                {
                  value: 2,
                  component: <div className='row'>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="E-motor"
                        value={data.efficiencyFuelCellEmotor}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyFuelCellEmotor", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Round Trip Battery Efficiency"
                        value={data.efficiencyFuelCellBatteryRoundTrip}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyFuelCellBatteryRoundTrip", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Fuel Cell"
                        value={data.efficiencyFuelCell}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyFuelCell", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Converter"
                        value={data.efficiencyFuelCellConverter}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyFuelCellConverter", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="Speed Controller"
                        value={data.efficiencyBatterySpeedController}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyBatterySpeedController", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>

                  </div>
                },
                {
                  value: 3,
                  component: <div className='row'>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="E-motor"
                        value={data.efficiencyHybridEmotor}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyHybridEmotor", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Round Trip Battery Efficiency"
                        value={data.efficiencyHybridBatteryRoundTrip}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyHybridBatteryRoundTrip", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Fuel Cell"
                        value={data.efficiencyHybridFuelCell}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyHybridFuelCell", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">


                      <TextFieldWithAdornment
                        label="Converter"
                        value={data.efficiencyHybridConverter}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyHybridConverter", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="Speed Controller"
                        value={data.efficiencyHybridSpeedController}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyHybridSpeedController", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>
                  </div>
                },
                {
                  value: 4,
                  component: <div className='row'>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="Diesel Combustion engine"
                        value={data.efficiencyDieselCombustionEngine}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyDieselCombustionEngine", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>

                  </div>
                },
                {
                  value: 5,
                  component: <div className='row'>
                    <div className="col-lg-6">
                      <TextFieldWithAdornment
                        label="LNG Combustion engine"
                        value={data.efficiencyLngCombustionEngine}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "efficiencyLngCombustionEngine", 2)
                        }}
                        rightLabel="(%)"
                      />
                    </div>

                  </div>
                }
              ]
            }
          />





        </div>
      </AccordionDetails>
    </Accordion>


  )
}
