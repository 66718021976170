import { Button, FormControl, FormHelperText } from "@mui/material";
import React, { useState } from "react";

import UploadFileIcon from "@mui/icons-material/UploadFile";

const UploadButton = props => {
  const [fileName, setFileName] = useState("");

  const handleFileChnage = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => setFileName(file.name);
      reader.readAsDataURL(file);
      props.setFieldValue(props.field.name, file);
    }
  };

  return (
    <FormControl margin="normal" fullWidth>
      <input
        style={{ display: "none" }}
        id="video-upload"
        name={props.field.name}
        type="file"
        // accept="video/*"
        onChange={handleFileChnage}
      />
      <label htmlFor="video-upload">
      <Button
        fullWidth
        size="medium"
        variant="outlined"
        component="label"
        sx={{ textAlign: "left", justifyContent: "flex-start", color: "#222" }}
      >
        <UploadFileIcon sx={{ marginRight: "5px" }} />
        {props.title}
        <input type="file" hidden 
        style={{ display: "none" }}
        id="video-upload"
        name={props.field.name}
        
        // accept="video/*"
        onChange={handleFileChnage}
        />
      </Button>
        {/* <Button color="primary" margin="normal" component="span">
          {props.title}
           
        </Button> */}
      </label>
      {fileName ? (
        <FormHelperText id="video-upload-filename">{fileName}</FormHelperText>
      ) : null}
      {props.errorMessage ? (
        <FormHelperText id="video-upload-helper-text" error={true}>
          {props.errorMessage}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default UploadButton;
