import React from "react";
import { GenericTables} from "../../common/GenericTables";
import { RadioInputs, SearchOption, Tags } from "../../common/CommonComponents";
import Box from "@mui/material/Box";
import { ReportFilters, ReportFilterTags } from "../../../misc/data";
import PaginationComp from "../../common/Pagination";

function createData(title, author, category, tag, publishDate, url) {
  return { title, author, category, tag, publishDate, url };
}

const rows = [
  createData(
    "Feasibility of the SF-BREEZE: a Zero-Emission, Hydrogen Fuel Cell, High-Speed Passenger Ferry",
    "International Transport Forum / OECD",
    "Case study",
    "Research, fuel, H2O",
    "10, Sept 2018",
    "https://www.google.com"
  ),
];
const cellTitles = [
  "Title",
  "Author",
  "Category",
  "Tag",
  "Publish date",
  "",
];
export default function InitiativeTable() {
  const [value, setValue] = React.useState("all");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [tag, setTag] = React.useState(null);

  const handleChangeTag = (v) => {
    setTag(v);
  };
  return (
    <section className="table-view Reports">
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-md-3 col-12">
            <div className="filters">
              <div className="filter-block">
                <h3 className="h4 title">Seach by filter</h3>
                <div className="filter-cont">
                  <RadioInputs
                    list={ReportFilters}
                    value={value}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="filter-block">
                <h3 className="h4 title">Tags</h3>
                <div className="filter-cont">
                  <Tags
                    list={ReportFilterTags}
                    value={tag}
                    handleChange={handleChangeTag}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12">
            <GenericTables
              title="Initiatives & Workshops"
              rows={rows}
              cellTitles={cellTitles}
              seachOption={true}
              titleOption={true}
            />
          </div>
          <Box sx={{ mt: 4 }}>
            <PaginationComp />
          </Box>
        </div>
      </div>
    </section>
  );
}
