import * as React from 'react';
import TabsAndTables from './TabsAndTables';

export default function CalcResultsView({ state, reset }) {
  return (
    <div className='CalcResultsView'>
      <TabsAndTables
        state={state}
        reset={reset}
      />

    </div>
  )
}
