import LegislativeTable from "../components/reportContent/Legislative/LegislativeTable";

function Legislative() {
  return (
    <>
      <LegislativeTable />
    </>
  );
}

export default Legislative;
