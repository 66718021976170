import { Chip } from "@mui/material";
import React from "react";
import newsImg1 from "../../assets/images/newsImg1.jpg";

export default function LinkList({ rows, cellTitles, type, dashboard }) {
  return (
    <div>
      {" "}
      <div className="row">
        {rows.map((link, index) => {
          return (
            <div className="col-md-4 col-12 linksCard__col">
              <a
                href={link.external_links}
                className="linksCard__link text-dark"
                target="_blank"
                rel="noreferrer"
              >
                <div className="card linksCard__card shadow-sm">
                  <div className="linksCard__imgWrapper">
                    <img
                      src={link.file_path ? link.file_path : newsImg1}
                      className="card-img-top linksCard__img"
                      alt="..."
                    />

                    {link.category === 4  && 
                      <Chip
                        label={link.ship_name ?  <span> <b> Ship</b>: {link.ship_name}</span>: ""}
                        variant="filled"
                        sx={{ background: "#fff" }}
                        className='linksCard__chip'
                      />
                    }

                    

                  </div>
                  <div className="card-body linksCard__cardBody">
                    <p className="card-text linksCard__cardText">
                      Project: <b>{link.project_name}</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                      Country: <b>{link.country}</b>
                    </p>
                    <p className="card-text linksCard__cardText">
                      Project time:{" "}
                      <b>
                        {link.start_date} to {link.end_date}
                      </b>
                    </p>

                    <p className="card-text linksCard__cardText">
                      Description:{" "}
                      <b>{link.port_name ? link.port_name : "N/A"}</b>
                    </p>
                    {link.category === 4 ? (
                      <div className="linksCard__fuelDiv">
                        <p className="card-text linksCard__cardText">
                          Fuel: <b>{link.fuel}</b>
                        </p>
                        <p className="card-text linksCard__cardText">
                          Power Generation System: <b>{link.fuel_cell_type}</b>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <a
                      href={link.external_links}
                      className="btn btn-sm btn-read text-white linksCard__btn"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </a>
            </div>
          );
        })}

        {rows.length === 0 && (
          <h4 className="mt-xs-32 text-center">No result found</h4>
        )}
      </div>
    </div>
  );
}
