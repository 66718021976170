
import { Button, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextFieldWithAdornment, WhiteButton } from '../common/CommonComponents';
import { handleChange } from '../../misc/commonFunctions';

export default function OperationalProfile({ state, setState, }) {
  let { CalculatorSteps } = state;
  const data = CalculatorSteps[1].inputType;


  const setValue = (key, value) => {
    CalculatorSteps[1].inputType[key].value = value;
    setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }))
  }
  return (
    <div className='OperationalProfile'>
      <h4 className="title">Operational Profile</h4>
      <div className="row">
        <div className="col-md-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Boat Distance</label>
            <TextFieldWithAdornment
              label="Origin-Destination"
              value={data.originDestinationDistance}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "originDestinationDistance", 1)
              }}
              rightLabel="Km"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Average Speed</label>
            <TextFieldWithAdornment
              label="service Average Speed "
              value={data.serviceAverageSpeed}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "serviceAverageSpeed", 1)
              }}
              rightLabel="Km/h"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Load Capacity</label>
            <TextFieldWithAdornment
              label="Max Pay Load Capacity  "
              value={data.maxLoadCapacity}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "maxLoadCapacity", 1)
              }}
              rightLabel="Tons"
            />
            <TextFieldWithAdornment
              label="Average (% fraction of max load cap) Range (0 - 1)"
              value={data.averageLoadCapacity}

              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "averageLoadCapacity", 1)
              }}
            />
            <p className='fz12 white-color'>Average Load Capacity has to take into accoint the
              round trip (Origin-Destination-Origin).

              Ex. If the Destination-Origin trip is done without load or passengers this value should not be bigger than 50%</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Operational hours/year</label>
            <TextFieldWithAdornment
              label="N° roundtrips for year "
              value={data.numberOfRoundTripsForYear}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "numberOfRoundTripsForYear", 1)
              }}
            />
            <TextFieldWithAdornment
              label="Sailing time per year"
              value={data.sailingTimePerYear}

              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "sailingTimePerYear", 1)
              }}
              rightLabel="Hours"
            />
            <p className='fz12 white-color'>If no value is indicated, sailing time per year is
              calculated considering the total distance & the
              average speed</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Bunkering</label>
            <TextFieldWithAdornment
              label="N° Stops "
              value={data.numberOfStops}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "numberOfStops", 1)
              }}
            />
            <TextFieldWithAdornment
              label="Longest Range"
              value={data.longestRange}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "longestRange", 1)
              }}
              rightLabel="Km"
            />
            <p className='fz12 white-color'>(Optional Value)</p>

          </div>
        </div>


      </div>






    </div>
  )
}
