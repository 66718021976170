export let dummyData = ['Propulsion Power', "Boat Size", "Length"];

let outputDataStrucutre = {
  "Propulsion Power": {
    "Boat Size": {
      "Length": {
        "value": 4,
      },
      "Breadth": {
        "value": 4,
      }
    },
    "Required Forward speed. inland waterway zone": {
      "Length": {
        "value": 4,
      },
      "Breadth": {
        "value": 4,
      }
    }
  }
}
export const paramTree = {
  vesselType: {
    label: 'Propulsion Power => Boat Type:',
    unit: ""
  },
  hullLength: {
    label: 'Propulsion Power => Boat Size => Length:',
    unit: "m"
  },
  hullBreadth: {
    label: 'Propulsion Power => Boat Size => Breadth:',
    unit: "m"
  },
  hullDraught: {
    label: 'Propulsion Power => Boat Size => Draught:',
    unit: "m"
  },
  maxSpeed: {
    label: 'Propulsion Power => Required Forward speed. inland waterway zone => Max Speed:',
    unit: "km/h"
  },
  keepAtLeastDuring: {
    label: 'Propulsion Power => Required Forward speed. inland waterway zone => Keep at least during:',
    unit: "h"
  },
  resistantCoEfficient: {
    label: 'Propulsion Power => Resistance => Resistance coefficient:',
    unit: ""
  },
  shaftEfficiency: {
    label: 'Propulsion Power => Propulsive Efficiency => Shaft:',
    unit: "%"
  },
  propellerEfficiency: {
    label: 'Propulsion Power => Propulsive Efficiency => propeller:',
    unit: "%"
  },
  installedPower: {
    label: 'Propulsion Power => Installed Power:',
    unit: ""
  }, originDestinationDistance: {
    label: 'Operation Profile => Distance:Origin Distination:',
    unit: "KM"
  },
  serviceAverageSpeed: {
    label: 'Operation Profile => Average speed:Service:',
    unit: "Km/h"
  },
  maxLoadCapacity: {
    label: 'Operation Profile => Load capacity: Max load capacity:',
    unit: "tons"
  },
  averageLoadCapacity: {
    label: 'Operation Profile => Load capacity: Average:',
    unit: ""
  },
  numberOfRoundTripsForYear: {
    label: 'Operation Profile => Operational hours per Year: No Round trip for year:',
    unit: ""
  },
  sailingTimePerYear: {
    label: 'Operation Profile => Operational hours per Year: Sailing time per year:',
    unit: ""
  },
  numberOfStops: {
    label: 'Operation Profile => Bunkering: N stops:',
    unit: ""
  },
  longestRange: {
    label: 'Operation Profile => Bunkering: Longest Range:',
    unit: "Km"
  },

  hydrogenStorageSystem: {
    label: 'Capacity Dimensioning => Hydrogen Storage => Hydrogen Storage System:',
    unit: ""
  },
  pressureOfHydrogen: {
    label: 'Capacity Dimensioning => Hydrogen Storage => Pressure of hydrogen storage:',
    unit: "Bar"
  },
  massLimit: {
    label: 'Capacity Dimensioning => Maximum limit:',
    unit: "Tons"
  },
  volumeLimit: {
    label: 'Capacity Dimensioning => Volume limit:',
    unit: "M^3"
  },
  efficiencyBatteryEmotor: {
    label: 'Capacity Dimensioning => Efficiency => 100% Battery => e-motor:',
    unit: ""
  },
  efficiencyBatteryRoundTrip: {
    label: 'Capacity Dimensioning => Efficiency => 100% Battery => round trip battery efficiency:',
    unit: ""
  },
  efficiencyBatteryConverter: {
    label: 'Capacity Dimensioning => Efficiency => 100% Battery => converter:',
    unit: ""
  },
  efficiencyBatterySpeedController: {
    label: 'Capacity Dimensioning => Efficiency => 100% Battery => speed controller:',
    unit: ""
  },
  efficiencyFuelCellEmotor: {
    label: 'Capacity Dimensioning => Efficiency => h2 fuel cell => e-motor:',
    unit: ""
  },
  efficiencyFuelCellRoundTrip: {
    label: 'Capacity Dimensioning => Efficiency => h2 fuel cell => round trip battery efficiency:',
    unit: ""
  },
  efficiencyFuelCell: {
    label: 'Capacity Dimensioning => Efficiency => h2 fuel cell => Fuel Cell:',
    unit: ""
  },
  efficiencyFuelCellConverter: {
    label: 'Capacity Dimensioning => Efficiency => h2 fuel cell => converter:',
    unit: ""
  },
  efficiencyFuelCellSpeedController: {
    label: 'Capacity Dimensioning => Efficiency => h2 fuel cell => speed controller:',
    unit: ""
  },
  efficiencyHybridEmotor: {
    label: 'Capacity Dimensioning => Efficiency => Hybrid => e-motor:',
    unit: ""
  },
  efficiencyHybridRoundTrip: {
    label: 'Capacity Dimensioning => Efficiency => Hybrid => round trip battery efficiency:',
    unit: ""
  },
  efficiencyHybrid: {
    label: 'Capacity Dimensioning => Efficiency => Hybrid => Fuel Cell:',
    unit: ""
  },
  efficiencyHybridConverter: {
    label: 'Capacity Dimensioning => Efficiency => Hybrid => converter:',
    unit: ""
  },
  efficiencyHybridSpeedController: {
    label: 'Capacity Dimensioning => Efficiency => Hybrid => speed controller:',
    unit: ""
  },
  efficiencyDieselCombustionEngine: {
    label: 'Capacity Dimensioning => Efficiency => diesel => Diesel Combustion engine:',
    unit: ""
  },
  efficiencyLngCombustionEngine: {
    label: 'Capacity Dimensioning => Efficiency => LNG => LNG Combustion engine:',
    unit: ""
  },
  otherParameterBatteryDepthOfDischarge: {
    label: 'Capacity Dimensioning => Other Parameters => 100% battery => Battery Depth of Discharge:',
    unit: ""
  },
  otherParameterFuelCellSpecificEnergyDensity: {
    label: 'Capacity Dimensioning => Other Parameters => H2 fuel cell => Specific Energy Density:',
    unit: "KWh/Kg"
  },
  otherParameterDieselLowerHeatingValue: {
    label: 'Capacity Dimensioning => Other Parameters => Diesel => Diesel-Lower Heating Value:',
    unit: "KWh/Kg"
  },
  otherParameterLngLowerHeatingValue: {
    label: 'Capacity Dimensioning => Other Parameters => LNG => LNG-Lower Heating Value:',
    unit: "KWh/Kg"
  },
  specificPowerEnergyDieselEngineA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Diesel Engine  (A):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyDieselEngineB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Diesel Engine  (B):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyLngEngineA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => LNG Engine  (A):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyLngEngineB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => LNG Engine  (B):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyElectricMotorA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Electric Motor  (A):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyElectricMotorB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Electric Motor  (B):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyConverterA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Converter   (A):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyConverterB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Converter   (B):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyVFDA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => VFD    (A):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyVFDB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => VFD    (B):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyFuelCellA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Fuel Cell (A):',
    unit: "KW ⇒ KG"
  },
  specificPowerEnergyBatteryA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Specific Power - Energy => Battery  (A):',
    unit: "KW ⇒ KG"
  }, powerEnergyDensityDieselEngineA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Diesel Engine   (A):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityDieselEngineB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Diesel Engine   (B):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityLngEngineA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => LNG Engine (A):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityLngEngineB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => LNG Engine (B):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityElectricMotorA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Electric Motor (A):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityElectricMotorB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Electric Motor (B):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityConverterA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Converter (A):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityConverterB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Converter (B):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityVFDA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => VFD (A):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityVFDB: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => VFD (B):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityFuelCellA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Fuel Cell  (A):',
    unit: "KW ⇒ m^3"
  },
  powerEnergyDensityBatteryA: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Engine Trend Line (Y=aX+b) => Power Energy density => Battery  (A):',
    unit: "KW ⇒ m^3"
  },
  fuelStorageCompressedHydrogen70MpaWeight: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Compressed Hydrogen => Hydrogen (70MPa):',
    unit: "kg storage/kg H2" //mg H2/m^3 storage
  },
  fuelStorageCompressedHydrogen70MpaVolume: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Compressed Hydrogen => Hydrogen (70MPa):',
    unit: "mg H2/m^3 storage" //kg storage/kg H2
  },
  fuelStorageCompressedHydrogen50MpaWeight: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Compressed Hydrogen => Hydrogen (50MPa):',
    unit: "kg storage/kg H2" //mg H2/m^3 storage
  },
  fuelStorageCompressedHydrogen50MpaVolume: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Compressed Hydrogen => Hydrogen (50MPa):',
    unit: "mg H2/m^3 storage" //kg storage/kg H2
  },
  fuelStorageCompressedHydrogen35MpaWeight: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Compressed Hydrogen => Hydrogen (50MPa):',
    unit: "kg storage/kg H2" //mg H2/m^3 storage
  },
  fuelStorageCompressedHydrogen35MpaVolume: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Compressed Hydrogen => Hydrogen (35MPa):',
    unit: "mg H2/m^3 storage" //kg storage/kg H2
  },
  fuelStorageDensityLngMass: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => LNG => LNG liquid 0.14 kg/l:',
    unit: ""
  },
  fuelStorageMassTankLng: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => LNG => Tank Volume for 1 liter of fuel :',
    unit: "m^3 storage/ Liter (Fuel)"
  },
  fuelStorageDensityDieselMass: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Diesel => Diesel  kg/l:',
    unit: ""
  },
  fuelStorageMassTankDiesel: {
    label: 'Capacity Dimensioning => Advanced Feasibility Parameter (weight and space) => Fuel Storage => Hydrogen => Diesel => Tank Volume for 1 liter of fuel :',
    unit: "m^3 storage/ Liter (Fuel)"
  },
  externalitiesCo2CostFactor: {
    label: 'Cost analysis => Externalities + co2 cost => Co2 => Cost Factor:',
    unit: "€/tCO2 eq. "
  },
  externalitiesDieselCo2EmmissionFactor: {
    label: 'Cost analysis => Externalities + co2 cost => Co2 => Diesel CO2 emission factor:',
    unit: "g CO2eq./ liter fuel"
  },
  externalitiesLngCo2EmmissionFactor: {
    label: 'Cost analysis => Externalities + co2 cost => Co2 => LNG_CO2 emission factor:',
    unit: "g CO2eq./ kg fuel"
  },
  externalitiesAirPollutionCost: {
    label: 'Cost analysis => Externalities + co2 cost => air pollution => Air Pollution cost:',
    unit: "€-cent/tkm"
  },
  externalitiesClimateChangeCost: {
    label: 'Cost analysis => Externalities + co2 cost => climate change => climate change cost:',
    unit: "€-cent/tkm"
  },
  capexEmotor: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => E-motor => E-Motor:',
    unit: "€/Kw"
  },
  capexEmotorVariableFrequencyDriver: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => E-motor => Variable Frequency driver:',
    unit: "€/Kw"
  },
  capexEmotorConverter: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => E-motor => Converter:',
    unit: "€/Kw"
  },
  capexBatterySystem: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Battery => Battery System 500:',
    unit: "€/Kwh"
  },
  capexBatteryInstallationCost: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Battery => Total Installation Cost:',
    unit: "€"
  },
  capexFuelCellInstallationCost: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Fuel cell system (With installation cost) :',
    unit: "€/Kw"
  },
  capexCompressedHydrogen35Mpa: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Compressed Hydrogen => Hydrogen Storage (35MPa):',
    unit: "€/KgH2"
  },
  capexCompressedHydrogen50Mpa: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Compressed Hydrogen => Hydrogen Storage (50MPa):',
    unit: "€/KgH2"
  },
  capexCompressedHydrogen70Mpa: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Compressed Hydrogen => Hydrogen Storage (70MPa):',
    unit: "€/KgH2"
  },
  capexMetalHydrides: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Metal Hydrides => Sodium Alanate:',
    unit: "€/KgH2"
  },
  capexAmmoniaCracker: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Ammonia => Cracker:',
    unit: "€/Kw"
  },
  capexAmmoniaTank: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Ammonia => Tank:',
    unit: "€/Kg"
  },
  capexLiquidHydrogenStorageCost: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Fuel cell => Liquid Hydrogen => Liquid hydrogen storage Cost:',
    unit: "€/KgH2 "
  },
  capexDieselEngineAndGearbox: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Diesel => Diesel Engine CCRII + Gearbox:',
    unit: "€/Kwh "
  },
  capexDieselTank: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Diesel => Diesel Tank:',
    unit: "€/Kg (tank weight)"
  },
  capexDieselInstallationCost: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => Diesel => Total installation cost:',
    unit: "€"
  },
  capexLngEngineAndGearbox: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => LNG => LNG + gear box:',
    unit: "€/Kw"
  },
  capexLngTank: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => LNG => LNG cryogenic tank:',
    unit: "€/m3(LNG stored)"
  },
  capexLngInstallationCost: {
    label: 'Cost analysis => Cost parameter => Capex Parameters => LNG => Total installation const:',
    unit: "€"
  },
  opexFixedCombustionEngine: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Fixed Opex => Combustion Engine:',
    unit: "€/Kw/a "
  },
  opexFixedConverter: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Fixed Opex => Converter:',
    unit: "€/Kw/a"
  },
  opexFixedVariableFrequencyDriver: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Fixed Opex => Variable frequency driver:',
    unit: "€/Kw/a"
  },
  opexFixedCracker: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Fixed Opex => Cracker:',
    unit: "0.02%"
  },
  opexVariableDieselPrice: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Diesel Price:',
    unit: "€/Kg"
  },
  opexVariableElectricityCost: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Electricity Cost:',
    unit: "€/KWh"
  },
  opexVariableLngPrice: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => LNG Price:',
    unit: "€/Kg"
  },
  opexVariableCompressedHydrogen700Bar: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Compressed Hydrogen => Compressed Hydrogen Cost (700 bar):',
    unit: "€/KgH2"
  },
  opexVariableCompressedHydrogen500Bar: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Compressed Hydrogen => Compressed Hydrogen Cost (500 bar):',
    unit: "€/KgH2"
  },
  opexVariableCompressedHydrogen350Bar: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Compressed Hydrogen => Compressed Hydrogen Cost (350 bar):',
    unit: "€/KgH2"
  },
  opexVariableMetalHydridesRefuelingCost: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Metal Hydrides => Metal Hydrides  refueling cost:',
    unit: "€/KgH2"
  },
  opexVariableAmmoniaCost: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Ammonia => Ammonia cost:',
    unit: "€/Kg"
  },
  opexVariableLiquidHydrogenPrice: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Variable Opex => Liquid Hydrogen => Liquid Hydrogen price:',
    unit: "€/Kgh2"
  },
  replacementFuelCellLifetime: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Replacement => Fuel Cell => Lifetime:',
    unit: "Hours"
  },
  replacementFuelCellCost: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Replacement => Fuel Cell => Replacement:',
    unit: "Cost FC system cost "
  },
  replacementBatteryLifetime: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Replacement => Battery => Lifetime:',
    unit: "Cycles"
  },
  replacementBatteryCost: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Replacement => Battery => Replacement:',
    unit: "€/Kwh "
  },
  engineRevisionInterval: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Engine Revision => Interval:',
    unit: "Hours "
  },
  engineRevisionCost: {
    label: 'Cost analysis => Cost parameter => Opex Parameters => Engine Revision => Cost:',
    unit: "€/Kw "
  },
  lifeTime: {
    label: 'Cost analysis => Cost parameter => System Lifetime => Lifetime:',
    unit: "Years "
  }
}