import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Card, CardContent, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { Field, Formik } from "formik";
import Fade from "react-reveal/Fade";
import { useNavigate, useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import NavBar from "../components/UserDashboard/NavBar";
import { profile, update_profile, update_password } from "../request/api";
//import { Visibility, VisibilityOff } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FormHelperText } from "@mui/material";



export default function Profile() {
	let navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	const [values, setValues] = useState({
		showPassword: false,
	});


	//const handlePasswordChange = (prop) => (event) => {
	//	setValues({ ...values, [prop]: event.target.value });
	//};

	//const handleClickShowPassword = () => {
	//	setValues({
	//		...values,
	//		showPassword: !values.showPassword,
	//	});
	//};

	//const handleMouseDownPassword = (event) => {
	//	event.preventDefault();
	//};


	let [state, setState] = useState({
		user_image: null,
		status_message: {
			type: "success",
			message: "",
		},
		authType: "profile_update",
	});
	let [initialVal, setinitialVal] = useState({
		email: "",
		name: "",
		company: "",
		service_type: "",
		address: "",
		other: "",
		user_image: "",
		file_path: "",
		password: "",
		password_confirmation: "",
	});
	let { authType, status_message } = state;

	let validationSchema = yup.object().shape(
		{
			email: yup
				.string("Enter your email")
				.email("Enter a valid email")
				.required("Email is required"),
			name: yup.string("Enter your name").required("Name is required"),
			address: yup.string("Enter your address").required("Address is required"),
			company: yup.string("Enter institution / company affiliation"),
			service_type: yup.string("Enter service type"),
			other: yup.string("How did you learn about us"),

			password: yup
				.string("Enter your password")
				.min(6, "Password should be of minimum 6 characters length"),

			password_confirmation: yup.string().when('password', {
				is: (password) => {
					return (password && password.length > 0)
				},
				then: yup.string()
					.required('Password is required')
			}),
			// user_image: yup.mixed()
			// 	// .required("File Is required!")
			// 	.test(
			// 		"fileSize",
			// 		"Your file is too big :(",
			// 		value => value && value.size <= 262144000
			// 	)

		}
	);;


	//  upload and show profile avatar/image code starts

	const [images, setImages] = useState([]);
	const [imageURLs, setImageURLs] = useState([]);

	//let storedImg = JSON.parse(localStorage.getItem("images"));

	useEffect(() => {
		if (images.length < 1) return;
		const newImageURls = [];
		images.forEach(img => newImageURls.push(URL.createObjectURL(img)));
		setImageURLs(newImageURls);
	}, [images]);



	const onImageChange = (event) => {
		//const file = event.target.files[0];
		//getBase64(file).then(base64 => {
		//	localStorage["fileBase64"] = base64;
		//	console.debug("file stored",base64);
		//});
		setImages([...event.target.files]);
	}


	const [data, setData] = useState('');

	const handleChange = (event) => {
		setData(event.target.value);
	};

	//  upload and show profile avatar/image code ends


	const getProfile = async () => {
		let res = await profile();

		if (res.data) {
			setinitialVal(prev => ({
				...prev,
				email: res.data.response.email,
				name: res.data.response.name,
				address: res.data.response.address,
				company: res.data.response.company,
				service_type: res.data.response.service_type,
				other: res.data.response.other,
				file_path: res.data.response.file_path,
			}))
		} else {
			localStorage.clear();
			navigate("/login")
		}
	}
	useEffect(() => {
		getProfile();
	}, []);

	return (
		<div className="pb-xs-32 mb-xs-60">
			<div className="form-top">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<NavBar />
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row pt-xs-32 justify-content-center">
					<div className="col-md-11 col-12">
						<div>
							<Formik
								enableReinitialize={true}
								initialValues={initialVal}
								validationSchema={validationSchema}
								onSubmit={async (values, actions) => {
									let data = new FormData();
									for (const property in values) {
										data.append(property, values[property]);
									}
									let message = "";
									let res = await update_profile(data);


									if (res && res.data) {
										message = message + " " + res.data.message;
										// getProfile();

									} else {
										message = message + " " + res.response.data.message;
									}

									let res2 = null;
									if (values.password) {
										res2 = await update_password({
											password: values.password,
											password_confirmation: values.password_confirmation,
										});


										if (res2.data || res2.response) {
											if (res2 && res2.data) {
												message = message + " " + res2.data.message;
											} else {
												message = message + " " + res2.response.data.message;
											}
										}
									}

									setState({
										...state,
										status_message: {
											type: "success",
											message,
										},
									});
								}}
							>
								{(props) => (
									<form onSubmit={props.handleSubmit}>
										<div className="container">
											<h2 className="mb-xs-32 text-center">Your profile</h2>
											<Box mb="3rem">
												<Card sx={{ backgroundColor: '#0000000f', paddingTop: "1rem" }}>
													<CardContent>
														<div className="row justify-content-between">
															<div className="col-md-3 col-12">
																<Avatar src={initialVal.file_path ? initialVal.file_path : "/broken-image.jpg"} sx={{ margin: "auto", height: 100, width: 100 }} />
																
																<Field
																	name="user_image"
																	component={UploadButtonProfilePic}
																	setFieldValue={(name, v) => {
																		console.log('v:', name, v)
																		props.setFieldValue(name, v);
																	}}
																	errorMessage={props.errors.user_image ? props.errors.user_image : ""}
																	touched={props.touched.user_image}
																	onBlur={props.handleBlur}
																/>

															</div>

															<div className="col-md-3 col-12">
																<Typography variant="h6">
																	Name:  <b>{props.values.name}</b>
																</Typography>
																<Typography sx={{ mt: 1.5, mb: 1.5 }} >
																	Email: <b>{props.values.email}</b>
																</Typography>
																<Typography sx={{ mt: 1.5, mb: 1.5 }}>
																	Address: <b>{props.values.address}</b>
																</Typography>
															</div>

															<div className="col-md-5 col-12">
																<Typography>
																	Institution / Company Affiliation:  <b>{props.values.company}</b>
																</Typography>
																
																<Typography sx={{ mt: 1.5, mb: 1.5 }}>
																	Type of Service: <b>{props.values.service_type}</b>
																</Typography>
																<Typography sx={{ mt: 1.5, mb: 1.5 }}>
																	How did you learn about us: <b>{props.values.other}</b>
																</Typography>
															</div>
														</div>
													</CardContent>
												</Card>
											</Box>

											<div className="row justify-content-center">
												<Fade>
													<h2 className="mb-xs-64 text-center">Edit Profile</h2>

													<div className="col-md-4 col-12">
														<div className="auth-inputs">

															<TextField
																fullWidth
																variant="filled"
																id="name"
																name="name"
																label="Name"
																value={props.values.name}
																onChange={props.handleChange}
																error={props.touched.name && Boolean(props.errors.name)}
																helperText={props.touched.name && props.errors.name}
															/>
															<TextField
																fullWidth
																variant="filled"
																id="email"
																name="email"
																label="Email"
																value={props.values.email}
																onChange={props.handleChange}
																error={
																	props.touched.email && Boolean(props.errors.email)
																}
																helperText={props.touched.email && props.errors.email}
															/>
															<TextField
																fullWidth
																variant="filled"
																id="address"
																name="address"
																label="Address"
																value={props.values.address}
																onChange={props.handleChange}
																error={
																	props.touched.address && Boolean(props.errors.address)
																}
																helperText={props.touched.address && props.errors.address}
																multiline
																rows={2}
															/>

														</div>
													</div>


													<div className="col-md-4 col-12">
														<div className="subscribeForm__form">
															<form>
																<Box>

																	<TextField
																		fullWidth
																		variant="filled"
																		id="company"
																		name="company"
																		label="Institution / Company Affiliation"
																		value={props.values.company}
																		onChange={props.handleChange}
																		error={props.touched.company && Boolean(props.errors.company)}
																		helperText={props.touched.company && props.errors.company}
																	/>

																	{/*<FormControl variant="filled" sx={{ minWidth: "100%" }}>
																		<InputLabel id="demo-simple-select-filled-label">Company size</InputLabel>
																		<Select
																			labelId="demo-simple-select-filled-label"
																			id="size"
																			value={age}
																			onChange={handleChange}
																		>
																			<MenuItem value={50}>50 or less employees</MenuItem>
																			<MenuItem value={250}>50-250 employees</MenuItem>
																			<MenuItem value={1000}>more than 1000 employees</MenuItem>
																		</Select>
																	</FormControl>*/}

																	<TextField
																		fullWidth
																		variant="filled"
																		id="service_type"
																		name="service_type"
																		label="Type of service"
																		value={props.values.service_type}
																		onChange={props.handleChange}
																		error={props.touched.service_type && Boolean(props.errors.service_type)}
																		helperText={props.touched.service_type && props.errors.service_type}
																	/>


																	<TextField 
																		fullWidth
																		variant="filled"
																		id="other"
																		name="other"
																		label="How did you learn about us"
																		value={props.values.other}
																		onChange={props.handleChange}
																		error={
																			props.touched.other && Boolean(props.errors.other)
																		}
																		helperText={props.touched.other && props.errors.other}
																		multiline
																		rows={2}
																	/>

																</Box>

															</form>
														</div>


													</div>


													<div className="col-md-4 col-12">

														<Box>
															<TextField
																fullWidth
																variant="filled"
																id="password"
																name="password"
																label="Password"
																type="password"
																value={props.values.password}
																onChange={props.handleChange}
																error={
																props.touched.password &&
																Boolean(props.errors.password)
																}
																helperText={
																props.touched.password && props.errors.password
																}
															/>

															{/*<TextField
																fullWidth
																variant="filled"
																id="password"
																name="password"
																label="Change password"
																type="password"
																value={props.values.password}
																onChange={props.handleChange}
																error={
																props.touched.password &&
																Boolean(props.errors.password)
																}
																helperText={
																props.touched.password && props.errors.password
																}
															/>*/}

															<TextField
																fullWidth
																variant="filled"
																id="password_confirmation"
																name="password_confirmation"
																label="Password confirmation"
																type="password"
																value={props.values.password_confirmation}
																onChange={props.handleChange}
																error={
																props.touched.password_confirmation &&
																Boolean(props.errors.password_confirmation)
																}
																helperText={
																props.touched.password_confirmation &&
																props.errors.password_confirmation
																}
															/>

															{/*<FormControl fullWidth sx={{ backgroundColor: "#0000000f" }} variant="filled">
																<InputLabel htmlFor="current-password">Current password</InputLabel>
																<Input

																	id="current-password"
																	type={values.showPassword ? 'text' : 'password'}
																	value={values.password}
																	onChange={handlePasswordChange('password')}
																	endAdornment={
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={handleClickShowPassword}
																				onMouseDown={handleMouseDownPassword}
																			>
																				{values.showPassword ? <VisibilityOff /> : <Visibility />}
																			</IconButton>
																		</InputAdornment>
																	}
																/>

															</FormControl>

															<FormControl fullWidth sx={{ backgroundColor: "#0000000f" }} variant="filled">
																<InputLabel htmlFor="change-password">Change password</InputLabel>
																<Input

																	id="change-password"
																	type={values.showPassword ? 'text' : 'password'}
																	value={props.values.password}
																	onChange={handlePasswordChange('password')}
																	endAdornment={
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={handleClickShowPassword}
																				onMouseDown={handleMouseDownPassword}
																			>
																				{values.showPassword ? <VisibilityOff /> : <Visibility />}
																			</IconButton>
																		</InputAdornment>
																	}
																/>
															</FormControl>

															<FormControl fullWidth sx={{ backgroundColor: "#0000000f" }} variant="filled">
																<InputLabel htmlFor="confirm-password">Confirm password</InputLabel>
																<Input

																	id="confirm-password"
																	type={values.showPassword ? 'text' : 'password'}
																	value={props.values.password_confirmation}
																	onChange={handlePasswordChange('password')}
																	endAdornment={
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={handleClickShowPassword}
																				onMouseDown={handleMouseDownPassword}
																			>
																				{values.showPassword ? <VisibilityOff /> : <Visibility />}
																			</IconButton>
																		</InputAdornment>
																	}
																/>
															</FormControl>*/}

														</Box>

														<div className="submit-buttons mt-xs-32">
															<Button
																type="submit"
																fullWidth
																variant="contained"
																size="large"
															>
																Update
															</Button>
															<p
																className={"fw-700 mt-xs-16 text-center"}
															>
																{status_message.message}
															</p>
														</div>
													</div>


												</Fade>
											</div>


										</div>



									</form>
								)}
								{/* </Slide> */}
							</Formik>
						</div>

					</div>
				</div>
			</div>
		</div>
	);
}




const UploadButtonProfilePic = props => {
	const [fileName, setFileName] = useState("");

	const handleFileChnage = e => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		if (file) {
			reader.onloadend = () => setFileName(file.name);
			reader.readAsDataURL(file);
			props.setFieldValue(props.field.name, file);
		}
	};

	return (
		<FormControl margin="normal" fullWidth>
			<input
				style={{ display: "none" }}
				id="video-upload"
				name={props.field.name}
				type="file"
				// accept="video/*"
				onChange={handleFileChnage}
			/>
			<label htmlFor="video-upload">
				<Button
					fullWidth
					size="medium"
					variant="outlined"
					component="label"
					sx={{ textAlign: "left", justifyContent: "flex-start", color: "#222" }}
				>
					<UploadFileIcon sx={{ marginRight: "5px" }} />
					Change profile picture
					<input type="file" hidden
						style={{ display: "none" }}
						id="video-upload"
						name={props.field.name}

						// accept="video/*"
						onChange={handleFileChnage}
					/>
				</Button>
				{/* <Button color="primary" margin="normal" component="span">
          {props.title}
           
        </Button> */}
			</label>
			{fileName ? (
				<FormHelperText id="video-upload-filename">{fileName}</FormHelperText>
			) : null}
			{props.errorMessage ? (
				<FormHelperText id="video-upload-helper-text" error={true}>
					{props.errorMessage}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};