import React from 'react'
import { handleChange } from '../../misc/commonFunctions';
import { TabSystem, TextFieldWithAdornment } from '../common/CommonComponents';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function OtherParameters({ state, setState, }) {
  let { CalculatorSteps, userType } = state;

  const data = CalculatorSteps[2].inputType;

  return (

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Other Parameters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="h2-input-group">
          <h4 class="color-w">Other Parameters</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="h2-input-group">
                <label className="h2-input-group_title">100% battery</label>
                <TextFieldWithAdornment
                  label="Battery Depth of Discharge"
                  value={data.otherParameterBatteryDepthOfDischarge}
                  onChange={e => {
                    handleChange(e.target.value, setState, CalculatorSteps, "otherParameterBatteryDepthOfDischarge", 2)
                  }}
                  rightLabel="(%)"
                />

              </div>
            </div>
            <div className="col-lg-6">
              <div className="h2-input-group">
                <label className="h2-input-group_title">H2 fuel cell</label>
                <TextFieldWithAdornment
                  label="Specific Energy Density "
                  value={data.otherParameterFuelCellSpecificEnergyDensity}
                  onChange={e => {
                    handleChange(e.target.value, setState, CalculatorSteps, "otherParameterFuelCellSpecificEnergyDensity", 2)
                  }}
                  rightLabel="(KWh/Kg)"
                />

              </div>
            </div>
            <div className="col-lg-6">
              <div className="h2-input-group">
                <label className="h2-input-group_title">Diesel</label>
                <TextFieldWithAdornment
                  label="Diesel-Lower Heating Value  "
                  value={data.otherParameterDieselLowerHeatingValue}
                  onChange={e => {
                    handleChange(e.target.value, setState, CalculatorSteps, "otherParameterDieselLowerHeatingValue", 2)
                  }}
                  rightLabel="(KWh/Kg)"
                />

              </div>
            </div>
            <div className="col-lg-6">
              <div className="h2-input-group">
                <label className="h2-input-group_title">LNG</label>
                <TextFieldWithAdornment
                  label="LNG-Lower Heating Value  "
                  value={data.otherParameterLngLowerHeatingValue}
                  onChange={e => {
                    handleChange(e.target.value, setState, CalculatorSteps, "otherParameterLngLowerHeatingValue", 2)
                  }}
                  rightLabel="(KWh/Kg)"
                />

              </div>
            </div>

          </div>




        </div>
      </AccordionDetails>
    </Accordion>
  )
}
