import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react'
import navLogo from "../../assets/images/navLogo.png";
import { isThisStep_Valid, toast } from '../../misc/commonFunctions';
export default function CalculatorSideBar({ state, setState, reset }) {
  let { CalculatorSteps, selectedStep } = state;

  return (
    <div className='CalculatorSideBar'>
      <div className="head">
        <div className="logo">
          <img src={navLogo} alt="" />
        </div>
        <span className='fw-600 h4'>H2Ships Calculator</span>
      </div>
      <div className="sidebar-body ">
        <div className="user-type-list selected">
          <p>Select user Type</p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={state.userType}


              onChange={e => {
                // reset();
                setState(
                  prev => ({
                    ...prev,
                    userType: e.target.value,
                    selectedStep: "Propulsion_Power"
                  }))
              }}
            >
              {/* standard . advanced */}
              <FormControlLabel
                value={"standard"}
                control={<Radio />}
                label={<span className='fw-600'>Standard User</span>}
                className="fw-600"
              />
              <FormControlLabel
                value={"advanced"}
                control={<Radio />}

                label={<span className='fw-600'>Advanced User</span>}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="calc-steps-list">
          {CalculatorSteps.map(s => <Button
            onClick={() => {
              if (isThisStep_Valid(s.key, CalculatorSteps, setState)) {
                setState(prev => ({ ...prev, selectedStep: s.key }))
              }else{
                toast.error("Please finish this step")
              }

            }}
            fullWidth
            key={s.key} className={selectedStep === s.key ? 'selected' : ""}>{s.title}</Button>)}


          {/* <Button fullWidth className=''>Advanced Parameter for weight and space feasibility analysis</Button>
          <Button fullWidth className=''>Advanced Parameter for cost analysis</Button>
          <Button fullWidth className=''>Advanced Parameters for Cost Analysis (Externalities)</Button> */}


        </div>
        <Button className='mt-xs-32' color="secondary" variant="outlined" fullWidth
          onClick={() => reset()}
        >Reset</Button>
      </div>
    </div>
  )
}
