import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useState } from 'react'
import CalcResultsView from '../components/calculator/CalcResultsView'
import CalculatorSideBar from '../components/calculator/CalculatorSideBar'
import StepsInputOptions from '../components/calculator/StepsInputOptions'
import { CalculatorStepsDATA, StateDataModel } from '../misc/data'
import { result } from '../misc/result'

export default function Calculator() {
  const [state, setState] = useState(cloneDeep(StateDataModel))
  const reset = () => {
    setState(cloneDeep(StateDataModel));

    localStorage.removeItem("Propulsion_Power");
    localStorage.removeItem("Operational_Profile");
    localStorage.removeItem("Capacity_Dimensioning");
  };
  let { selectedStep, userType, CalculatorSteps } = state;
  

  return (
    <div className="Calculator">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <CalculatorSideBar
              state={state}
              setState={setState}
              reset={reset}
            />
          </div>
          <div className="col-lg-8">
            {selectedStep !== "result" &&
              <StepsInputOptions
                state={state}
                setState={setState}
                reset={reset}
              />
            }
            {selectedStep === "result" &&

              <CalcResultsView
              state={state}
                reset={reset}
              />
            }
          </div>

        </div>
      </div>

      {/* <div className="left">

      </div>
      <div className="right">

        <Dialog
          open={selectedStep === "result"}
          onClose={handleClose}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Analysis Result"}
          </DialogTitle>`
          <DialogContent>
            <CalcResultsView
              result={result.data}
            />
          </DialogContent>
          <DialogActions>
            
            <Button onClick={handleClose} autoFocus>
              Start new analysis
            </Button>

            <Button onClick={handleClose} autoFocus>
              Download
            </Button>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>

          </DialogActions>
        </Dialog>
      


      </div> */}





    </div>
  )
}
