/* eslint-disable jsx-a11y/anchor-is-valid */
//import { Link } from "react-router-dom";
import React, { useContext } from 'react'
import { HashRouter, Link } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LoginIcon from '@mui/icons-material/Login';
import slugify from "slugify";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";
import navLogo from "../../assets/images/navLogo.png";
import { SiteContext } from '../../context/SiteContext';
import { getSubCategoryList } from '../../../src/request/api';
import { HashLink } from 'react-router-hash-link';




export default function Header() {
  const { siteStore, dispatch } = useContext(SiteContext);
  let { categoryTree } = siteStore;
  // let { site, dispatch }  = 
  const location = useLocation();

  const isAuthenticated = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoEvent = () => {
	navigate("/events")
  }

  React.useEffect(() => {
    (async () => {
      let res = await getSubCategoryList();
      if (res) {
        dispatch({
          type: "update_category_tree",
          categoryTree: res.data.data
        })
      }
    })();

  }, []);


  return (
    <nav id="main_nav" className="navbar navbar-expand-lg navbar-light border-bottom">
      <Link to="/" className="navbar-brand navbar__logo navbar__link">
        <span>The Platform is developed by</span>
        <img src={navLogo} alt="nav-logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse text-end"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <Link to="/" className="navbar__link">
            <li className="nav-item">
              <span
                className="nav-link navbar__nav-link"
                aria-current="page"
              >
                Home
              </span>
            </li>
          </Link>
          {/*
          <Link to="about" className="navbar__link">
            <li className="nav-item">
              <span className="nav-link navbar__nav-link">About Us</span>
             
            </li>
          </Link>*/}

          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle navbar__nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              About Us
            </a>
            <ul
              className="dropdown-menu navbar__dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <Link to="overview" className="navbar__link">
                <li>
                  <span className="dropdown-item navbar__nav-link">
                    Platform Overview
                  </span>
                </li>
              </Link>

              <Link to="concepts" className="navbar__link">
                <li>
                  <span className="dropdown-item navbar__nav-link">
                    Concepts
                  </span>
                </li>
              </Link>

              <Link to="pilots" className="navbar__link">
                <li>
                  <span className="dropdown-item navbar__nav-link">
                    Pilots
                  </span>
                </li>
              </Link>


              <Link to="faq" className="navbar__link">
                <li>
                  <span className="dropdown-item navbar__nav-link">
                    FAQs
                  </span>
                </li>
              </Link>

            </ul>
          </li> */}
{/*

          <Link to="news" className="navbar__link">
            <li className="nav-item">
              <span className="nav-link navbar__nav-link">News</span>
            </li>
          </Link>*/}

          <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle navbar__nav-link"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                News & Events
              </a>
              <ul
                className="dropdown-menu navbar__dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <Link to="news" className="navbar__link">
                  <li>
                    <span className="dropdown-item navbar__nav-link">
                      News
                    </span>
                  </li>
                </Link>


              
				<HashLink smooth to={'/news#events'}>
                  <li>
                    <span className="dropdown-item navbar__nav-link">
                      Events
                    </span>
                  </li>
				</HashLink>
               

               
              </ul>
            </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle navbar__nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Get Involved
            </a>
            <ul
              className="dropdown-menu navbar__dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <Link to="enquiry" className="navbar__link">
                <li>
                  <span className="dropdown-item navbar__nav-link">
                    Enquiries
                  </span>
                </li>
              </Link>

              {/*<Link to="join" className="navbar__link">
                <li>
                  <span className="dropdown-item navbar__nav-link">
                    Apply to join
                  </span>
                </li>
              </Link>*/}
            </ul>
          </li>



          {
            categoryTree.map(type => <li className="nav-item dropdown" key={type.name}>
              <a
                className="nav-link dropdown-toggle navbar__nav-link"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {type.name}
              </a>
              <ul
                className="dropdown-menu navbar__dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                {type.category.map((cat) => {
                  return <Link key={cat.name} to={`/content/${cat.id}/${slugify(cat.name)}`} className="navbar__link">
                    <li>
                      <span className="dropdown-item navbar__nav-link">
                        {cat.name}
                      </span>
                    </li>
                  </Link>

                })}


              </ul>
            </li>)
          }

          {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle navbar__nav-link"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Resources
              </a>
              <ul
                className="dropdown-menu navbar__dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <Link to="reports" className="navbar__link">
                  <li>
                    <span className="dropdown-item navbar__nav-link">
                      Reports
                    </span>
                  </li>
                </Link>

                <Link to="legislative" className="navbar__link">
                  <li>
                    <span className="dropdown-item navbar__nav-link">
                      Legislative
                    </span>
                  </li>
                </Link>

                <Link to="edumaterials" className="navbar__link">
                  <li>
                    <span className="dropdown-item navbar__nav-link">
                      Education Materials
                    </span>
                  </li>
                </Link>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle navbar__nav-link"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Links
              </a>
              <ul
                className="dropdown-menu navbar__dropdown-menu"
                aria-labelledby="navbarDropdown"
              >

                <li>
                  <a className="dropdown-item navbar__nav-link" href="#">
                    Shipping Platforms
                  </a>
                </li>

                <li>
                  <a className="dropdown-item navbar__nav-link" href="#">
                    Initiatives & Workshops
                  </a>
                </li>

                <li>
                  <a className="dropdown-item navbar__nav-link" href="#">
                    Funds & Research
                  </a>
                </li>
              </ul>
            </li> */}

        </ul>


        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {/* <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    color: "#222",
                    marginRight: "1.5rem",
                    fontWeight: "normal",
                  }}
                >
                  Logged in as John Doe
                </Typography> */}
          {isAuthenticated ?
            <Avatar
              alt="Remy Sharp"
              sx={{ width: 32, height: 32, background: "#2d9d69" }}
            />
            :
            <Avatar
              alt="Remy Sharp"
              sx={{ width: 32, height: 32, background: "#888" }}
            />
          }

          <ArrowDropDownIcon style={{ color: "#333" }} />


        </IconButton>


        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}

          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}

        >
          {!isAuthenticated && <MenuItem
            onClick={() => { handleClose(); navigate("/login") }}
          >

            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Log in
          </MenuItem>}
          {!isAuthenticated && <MenuItem
            onClick={() => { handleClose(); navigate("/signup") }}
          >

            <ListItemIcon>
              <PersonAddAltIcon fontSize="small" />
            </ListItemIcon>
            Sign Up
          </MenuItem>}
          {isAuthenticated && <MenuItem
            onClick={() => { handleClose(); navigate("/user-dashboard") }}
          >
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            Dashboard
          </MenuItem>}

          {isAuthenticated && <MenuItem
            onClick={() => { handleClose(); navigate("/profile") }}
          >
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            Edit Profile
          </MenuItem>}

          {isAuthenticated && <MenuItem
            onClick={() => {
              localStorage.clear();
              handleClose();
              navigate("/")
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          }
        </Menu>

        <div className="btn btn-primary" onClick={()=> navigate("/calculator")}>
          <i className="fa fa-calculator"></i> H2SHIPS DESIGN TOOL
        </div>
      </div>
    </nav>


  );
}
