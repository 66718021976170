import React from "react";

function ObjectiveContent() {
  return (
    <>
      <div className="objectiveContent container mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h2 className="objectiveContent__title mb-5">Platform Objectives</h2>
            <p className="objectiveContent__details">
              This site is a resource for learning about hydrogen in shipping; a place where ports interested in participating can apply to get involved in the H2SHIPS Project and a place where specialists and laypeople can find a rich repository of legislative, educational and technical materials relating to hydrogen and fuel cells in shipping. The site is also a portal for sharing the H2SHIPS Project outputs.
            </p>
          </div>
          <div className="col-md-4">
            <div className="ratio ratio-16x9">

              <iframe width="1584" height="615" className="shadow-lg objectiveContent__videoFrame" src="https://www.youtube.com/embed/56wH1iNTI3U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="objectiveContent container mb-5">
        <div className="row justify-content-center">
          <div className="col-md-12 mb-5">
            <h2 className="objectiveContent__title mb-3">About</h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                This platform aims at supporting the development of hydrogen-based solutions
                for the energy transition of shipping. It offers a comprehensive and
                up-to-date database and will provide knowledge exchange and the building of
                a hydrogen community on shipping in North-West Europe and beyond. It was
                created as part of{" "}
              </span>
              <a href="http://www.nweurope.eu/H2SHIPS">
                <span style={{ fontWeight: 400 }}>H2SHIPS</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                , a project funded by the Interreg NWE programme. It is intended to remain
                active after the end of the project, and is designed as a collaborative
                platform.
              </span>
            </p>

          </div>
          <div className="col-md-12 mb-5">
            <h2 className="objectiveContent__title mb-3">How to get involved</h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you want to upload new online resources such as news, events, project
                descriptions and publications{" "}
              </span>
              <span style={{ fontWeight: 400 }}>please feel free to <a href="/signup" target="_blank">register</a> </span>
              <span style={{ fontWeight: 400 }}>
                and you will be able to add new contents that will be reviewed by one of our
                moderators. We make our best to ensure that new content is reviewed and
                published as soon as possible.{" "}
              </span>
            </p>


          </div>
          <div className="col-md-12 mb-5">
            <h2 className="objectiveContent__title mb-3">Contents of the platform</h2>
            <ul>
              <li><a href="/news" target="_blank">News</a>  &nbsp;related to Hydrogen applications in shipping</li>
              <li><a href="/events" target="_blank">Events</a>  &nbsp;related to Hydrogen and the&nbsp;</li>
              <li>Descriptions of  <a href="https://www.nweurope.eu/projects/project-search/h2ships-system-based-solutions-for-h2-fuelled-water-transport-in-north-west-europe/" target="_blank" rel="noreferrer">projects and demonstration cases</a> including <a href="/content/4/Ships" target="_blank">the presentation of the H2SHIPS project</a> </li>
              <li><a target={"_blank"} href="/content/4/Ships"> Networks</a> promoting H2 in shipping</li>
              <li>Various publications to be found in our resources centre (learning and teaching material, legal texts, <a href="/content/1/Reports" target={"_blank"}> scientific and strategic publications</a>)</li>
            </ul>
            <p>A <a href="/calculator" target="_blank">tool</a> developed within H2SHIPS that provides insights into the characteristics and benefits of H2-based propulsion systems</p>
            <br />
            <br />
            <p><strong>Rules of the platform</strong></p>
            <p>Publication of copyright-infringing content and advertising/self-promotion are prohibited.&nbsp;</p>
            <p><strong>Contact information</strong></p>
            <p>This webpage is maintained by University of Birmingham.&nbsp;</p>
            <p>For any question, please use the <a href="/enquiry" target={"_blank"}> contact form</a></p>
          </div>

        </div>
      </div>

    </>
  );
}

export default ObjectiveContent;
