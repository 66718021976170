import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import React from 'react'
import { handleChange } from '../../misc/commonFunctions';
import { SelectCustom, TabSystem, TextFieldWithAdornment, WhiteButton } from '../common/CommonComponents';
import AdvancedParameterForWeightAndSpaceFeasibilityAnalysis from './AdvancedParameterForWeightAndSpaceFeasibilityAnalysis';
import CostCalculationsParameters from './CostCalculationsParameters';
import Efficiency from './Efficiency';
import OtherParameters from './OtherParameters';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CapacityDimensioning({ state, setState, }) {
  const [open, setOpen] = React.useState(false);
  const [modalType, setmodalType] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };
  let { CalculatorSteps, userType } = state;

  const data = CalculatorSteps[2].inputType;



  return (
    <div className='CapacityDimensioning'>
      <h4 className="title">Capacity Dimensioning</h4>

      <div className="row">

        <div className="col-lg-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Hydrogen Storage</label>
            <SelectCustom
              label="Hydrogen storage system"
              value={data.hydrogenStorageSystem}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "hydrogenStorageSystem", 2)
                if (e.target.value !== "Compressed Hydrogen") {
                  handleChange("", setState, CalculatorSteps, "pressureOfHydrogen", 2)
                  data.pressureOfHydrogen.required = false;
                  data.pressureOfHydrogen.validationMessage = "";
                } else {
                  data.pressureOfHydrogen.required = true;
                  data.pressureOfHydrogen.validationMessage = "Please Insert Pressure of Hydrogen storage";
                  CalculatorSteps[2].inputType = data;
                }

                setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }));
              }}
              list={[
                "Liquid Hydrogen",
                "Compressed Hydrogen",
                "Metal Hydrides",
                "Ammonia",
              ]}
            />
            <SelectCustom
              disabled={data.hydrogenStorageSystem.value !== "Compressed Hydrogen"}
              label="Pressure of Hydrogen storage "
              value={data.pressureOfHydrogen}

              onChange={e => {
                handleChange("Compressed Hydrogen", setState, CalculatorSteps, "hydrogenStorageSystem", 2)
                handleChange(e.target.value, setState, CalculatorSteps, "pressureOfHydrogen", 2)
              }}
              rightLabel="(Bar)"
              list={[
                700,
                500,
                350,
              ]}
            />

          </div>
        </div>
        <div className="col-lg-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Mass & Volume Limits</label>
            <TextFieldWithAdornment
              label="Mass Limit "
              value={data.massLimit}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "massLimit", 2)
              }}
              rightLabel="(Tons)"
            />
            <TextFieldWithAdornment
              label="Volume Limit"
              value={data.volumeLimit}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "volumeLimit", 2)
              }}
              rightLabel="(m3)"
            />
          </div>
        </div>
        <div className="mb-xs-32">
          {userType === "advanced" && <>
            <div className="col-lg-12">
              <Efficiency state={state} setState={setState} />
              <OtherParameters state={state} setState={setState} />
            </div>
          </>}
        </div>

      </div>





      <div className="mb-xs-32">
        {userType === "advanced" && <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Advanced Parameter For Weight And Space Feasibility Analysis</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdvancedParameterForWeightAndSpaceFeasibilityAnalysis state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Cost Calculations Parameters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CostCalculationsParameters state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        {/* <Button
          variant='outlined'
          style={{
            color: "#fff",
            borderColor: "#fff",
            marginRight: "16px"
          }}
          onClick={() => {
            handleClickOpen();
            setmodalType("AdvancedParameterForWeightAndSpaceFeasibilityAnalysis")
          }} >
          Advanced Parameter For Weight And Space Feasibility Analysis
        </Button>
        <Button
          variant='outlined'
          style={{
            color: "#fff",
            borderColor: "#fff"
          }}
          onClick={() => {
            handleClickOpen();
            setmodalType("CostCalculationsParameters")
          }} >
          Cost Calculations Parameters
        </Button> */}
      </div>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "#5c96e0" }}
        PaperProps={{
          style: { backgroundColor: "#5c96e0" }
        }}
      >
        
        <DialogContent
          style={{ backgroundColor: "#5c96e0" }}
        >
          {modalType === "AdvancedParameterForWeightAndSpaceFeasibilityAnalysis" && <AdvancedParameterForWeightAndSpaceFeasibilityAnalysis state={state} setState={setState} />}
          {modalType === "CostCalculationsParameters" && <CostCalculationsParameters state={state} setState={setState} />}
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} style={{
            color: "#fff",
            borderColor: "#fff"
          }}
            size="large"
            variant='outlined'
          >
            Save
          </Button>


        </DialogActions>
      </Dialog> */}
    </div>
  )
}
