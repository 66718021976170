import { Button, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextFieldWithAdornment, WhiteButton } from '../common/CommonComponents';
import { handleChange } from '../../misc/commonFunctions';


export default function PropulsionPower({ state, setState, }) {
  let { CalculatorSteps, userType } = state;
  const data = CalculatorSteps[0].inputType;

  return (
    <div className='PropulsionPower'>
      <h4 className="title">Propulsion Power</h4>
      <p className='fz14 white-color'><b>Disclaimer: </b> The User is hereby notified that the information contained herein may not meet the user's needs. The User is advised that, although the information is derived from research and literature we cannot guarantee either its correctness, comprehensiveness or currency. The User of H2SHIPS DESIGN TOOL assumes sole responsibility for any decisions made or actions taken based on the information concluded here. </p>
      <div className="row">

      </div>
      <div className="row">

        <div className="col-md-6">
          <div className="h2-input-group">
            <label className="h2-input-group_title">Boat Type</label>
            <TextField
              select
              label="Select Boat Type"
              variant="filled"
              fullWidth
              value={data.vesselType.value}
              onChange={e => {
                handleChange(e.target.value, setState, CalculatorSteps, "vesselType", 0)
              }}
            >
              <MenuItem value={"Cargo"}>Cargo</MenuItem>
              <MenuItem value={"Tanker"}>Tanker</MenuItem>
              <MenuItem value={"Push Boat"}>Push Boat</MenuItem>
              <MenuItem value={"Passengers"}>Passengers</MenuItem>

            </ TextField>
          </div>
        </div>

        <div className="col-md-12">
          <div className="block-level-1">
            <div className="row">
              <div className="col-md-12">
                <div className="h2-input-group white-block">
                  <label className='h2-input-group_title'>Do you know installed power of the boat?</label>
                  <div className='radio-button-wrapper'>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={data.knowInstalledPower.value}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "knowInstalledPower", 0)
                          if (e.target.value === "Yes") {

                            data.installedPower.required = true;
                            data.installedPower.validationMessage = "Please Insert Installed Power";
                            data.resistantCoEfficient.required = false;
                            data.resistantCoEfficient.validationMessage = "";


                            data.hullLength.validationMessage = "";
                            data.hullLength.value = "";
                            data.hullBreadth.validationMessage = "";
                            data.hullBreadth.value = "";
                            data.hullDraught.validationMessage = "";
                            data.hullDraught.value = "";

                            data.hullLength.required = false;
                            data.hullBreadth.required = false;
                            data.hullDraught.required = false;


                            CalculatorSteps[0].inputType = data;

                            setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }));
                          } else {

                            data.installedPower.required = false;
                            data.installedPower.validationMessage = "";
                            data.resistantCoEfficient.required = false;
                            data.resistantCoEfficient.validationMessage = "";

                            data.hullLength.required = true;
                            data.hullBreadth.required = true;
                            data.hullDraught.required = true;
                            CalculatorSteps[0].inputType = data;

                            setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }));

                          }
                        }}
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value={"Yes"}
                            control={<Radio />}
                            label={<span className='fw-600'>Yes</span>}
                          />
                          <FormControlLabel
                            value={"No"}
                            control={<Radio />}
                            label={<span className='fw-600'>No</span>}
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="h2-input-group">
                  <label className="h2-input-group_title">Boat Size</label>
                  <TextFieldWithAdornment
                    disabled={data.knowInstalledPower.value === "Yes"}
                    label="Length"
                    value={data.hullLength}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "hullLength", 0)
                    }}
                    rightLabel="meter"
                  />
                  <TextFieldWithAdornment
                    disabled={data.knowInstalledPower.value === "Yes"}
                    label="Breadth"
                    variant="filled"
                    value={data.hullBreadth}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "hullBreadth", 0)
                    }}
                    rightLabel="meter"
                  />
                  <TextFieldWithAdornment
                    disabled={data.knowInstalledPower.value === "Yes"}
                    label="Draught"
                    value={data.hullDraught}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "hullDraught", 0)
                    }}
                    rightLabel="meter"
                  />
                  {/* <p className='calc-helper-text black'>Values must be between (0 - 5000)</p> */}

                </div>
              </div>
              <div className="col-md-6">
                <div className="h2-input-group">
                  <label className="h2-input-group_title">Please Insert Installed Power</label>
                  <TextFieldWithAdornment
                    disabled={data.knowInstalledPower.value === "No"}
                    label="Installed Power"
                    value={data.installedPower}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "installedPower", 0)
                    }}
                  // rightLabel="Kw"
                  />


                </div>
              </div>
              <div className="col-md-6">
                <div className="h2-input-group">
                  <TextFieldWithAdornment
                    label="Max Speed"

                    value={data.maxSpeed}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "maxSpeed", 0)
                    }}
                    rightLabel="Km/h"
                  />
                  {/* <p className='calc-helper-text black'>Values must be between (0 - 5000)</p> */}
                  <TextFieldWithAdornment
                    label="Keeping At Least During"

                    value={data.keepAtLeastDuring}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "keepAtLeastDuring", 0)
                    }}
                    rightLabel="h"
                  />

                </div>

              </div>

            </div>

          </div>
        </div>
        <div className="col-md-12">
          <div className="block-level-1">
            <div className="row">
              <div className="col-md-6">
                <div className="h2-input-group">
                  <label className="h2-input-group_title">Resistance Coefficient</label>
                  <TextFieldWithAdornment
                    label="(Optional)"
                    value={data.resistantCoEfficient}
                    onChange={e => {
                      handleChange(e.target.value, setState, CalculatorSteps, "resistantCoEfficient", 0)
                    }}
                    rightLabel=""
                  />

                </div>
              </div>

              {userType === "advanced" &&
                <>

                  <div className="col-md-6">
                    <div className="h2-input-group">
                      <label className="h2-input-group_title">Propulsive Efficiency</label>
                      <TextFieldWithAdornment
                        label="Shaft"
                        variant="filled"
                        fullWidth
                        value={data.shaftEfficiency}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "shaftEfficiency", 0)
                        }}
                      />
                      <TextFieldWithAdornment
                        label="Propeller"
                        value={data.propellerEfficiency}
                        onChange={e => {
                          handleChange(e.target.value, setState, CalculatorSteps, "propellerEfficiency", 0)
                        }}
                        rightLabel=""
                      />

                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>



      </div>






    </div>
  )
}
