import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { chartColors } from '../../misc/data';




export default function CalculatorBars({ data, type, unit, max }) {
  // const [getPng, { ref, isLoading }] = useCurrentPng();
  // const [png, setPng] = React.useState(null);
  // {
  //   labelKey: "c",
  //   label: "H2 Fuel Cell",
  //   value: 3,
  //   color: "#59E6F6"
  // },

  // React.useEffect(() => {
  //   (async () => {
  //     const ppp = await getPng();
  //     if (ppp) {
        
  //       console.log('ppp:', ppp)
  //       setPng(ppp);
  //     }

  //   })();
  // }, []);

  // console.log("png", png);

  return (
    <>
      <div
        className='CalculatorBars'
        style={{
          height: 500
        }}

      >
        <h5>{type} ({unit})</h5>
        <ResponsiveContainer  >
          <BarChart
            // ref={ref}

            data={data}
          // margin={{
          //   top: 5,
          //   right: 30,
          //   left: 20,
          //   bottom: 5,
          // }}
          >
            <ReferenceLine y={max.value} label={max.label} stroke="red" strokeDasharray="3 3" />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="label" />
            <YAxis width={90} />
            <Tooltip />
            {/* <Legend
              content={(props) => <p className="text-center fw-600 fz12 mb-xs-32">{data.map(e => <span style={{ color: e.color }}>{e.label}:{Math.round(e.value)} </span>)}</p>}
            /> */}
            <Bar dataKey="value" >
              {
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={chartColors[index].color} />
                ))
              }
            </Bar>

          </BarChart>
        </ResponsiveContainer>
      </div>
      {/* <div className='CalculatorBars'>
        <h4 className="title">
          Weight (Tons)
        </h4>
        <div className="bars-body">

          <div className="bar-list">
          {CalculatorBarDataList.map((d) => {
            return <div className="bar-contianer" key={d.label}
              style={{
                width: `${100 / CalculatorBarDataList.length}%`,
              }}
            >
              <span className='value'>
                {d.value}
              </span>
              <div
                style={{
                  backgroundColor: d.color,
                  height: `${d.value}0%`,
                  width: `100%`,
                }}
                className="inner-bar">


              </div>
              <span className='labelKey'>
                {d.labelKey}
              </span>

            </div>
          })}
        </div>

        <div className="bar-label-list-container">
          <div className="bar-label-list">
            {CalculatorBarDataList.map((d) => {
              return <div className="bar-label-contianer" key={d.label}>
                <div className="key"
                  style={{ backgroundColor: d.color }}
                >
                  {d.labelKey}
                </div>
                <div className="label">
                  {d.label}
                </div>


              </div>
            })}
          </div>
        </div>
      
        </div>
      </div> */}
    </>

  )
}
