import AboutIntro from "../components/contents/aboutContent/AboutIntro";
//import CoreTeam from "../components/contents/aboutContent/CoreTeam";
//import TimelineContent from "../components/contents/aboutContent/TimelineContent";
import MeetTeam from "../components/contents/aboutContent/MeetTeam";

function OverView() {
  return (
    <>
      <AboutIntro />
      {/*<TimelineContent />*/}
      <MeetTeam />
    </>
  );
}

export default OverView;
