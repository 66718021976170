import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { Button, FormHelperText, TextField } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TodayIcon from '@mui/icons-material/Today';
import moment from "moment";


export function BasicSelect(props) {
  let { label, value, dataList, dataType, setData } = props;


  return (
    <TextField
      {...props}
    //   label={"Author Name"}
    //   fullWidth
    //   sx={{ ...commonPos }}
    //   id="author"
    //   name="author"
    //   value={props.values.author}
    //   onChange={props.handleChange}
    //   error={
    //     props.touched.author && Boolean(props.errors.author)
    //   }
    //   helperText={props.touched.author && props.errors.author}
    //
    >


      {dataList.map((d) => <MenuItem key={d.id + d.name} value={d.id}>{d.name}</MenuItem>)}


    </ TextField>
  );
}


const names = ["Reserch", "Oil", "H2O", "Research", "Oil", "H2O"];
export const commonPos = {
  mb: 2
}
export function MultipleSelectCheckmarks(props) {
  let { label, value, dataList, dataType, setData } = props;



  return (
    <FormControl
      fullWidth
      // sx={{ ...commonPos }}
      {...props}
    >
      <InputLabel id={dataType + "label"}>{label}</InputLabel>
      <Select
        {...props}
        label={label}
        labelId={dataType + "label"}
        id={dataType + "id"}
        multiple
        value={value}
        input={<OutlinedInput
          label={label}
          style={{marginBottom: 0}}
        />}

        renderValue={(selected) => selected.map(s => s.name).join(", ")}
      >
        {dataList.map((d) =>
          <MenuItem key={d.name}
            value={d}>
            <Checkbox checked={value.indexOf(d) > -1} />
            <ListItemText primary={d.name} />
          </MenuItem>)}



      </Select>
      <FormHelperText error={props.error}>
        {props.helperText}
      </FormHelperText>

    </FormControl>
  );
}

export function BasicTextField(props) {
  let { label, value, dataType, setData } = props;
  return (
    <FormControl
      // {...props}
      fullWidth
      sx={{ ...commonPos }}
    >
      <TextField

        {...props}

      // fullWidth

      // label={label}
      // variant="outlined"
      // labelId={dataType + "label"}
      // id={dataType + "id"}
      // value={value}

      // onChange={(e) => setData(dataType, e.target.value)}
      />
    </FormControl>
  );
}



export default function BasicDatePicker(props) {
  let { label, value, dataType, onChange } = props;



  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        // open={open}
        onChange={(newValue) => {

          onChange(newValue)
        }}
        renderInput={(params) => {

          return (
            <TextField
              {...params}
              {...props}
              inputProps={{
                ...params.inputProps,
                value: moment(params.inputProps.value).format('DD/MM/YY')
              }}

              sx={{ ...commonPos }}
              // InputProps={{
              //   startAdornment: <TodayIcon />,
              //   endAdornment: <TodayIcon />,
              // }}
              fullWidth
            />
          )
        }}
      />
    </LocalizationProvider>
  );
}

export function FileUploadButton() {
  return (
    <Box fullWidth>
      <Button
        fullWidth
        size="medium"
        variant="outlined"
        component="label"
        sx={{ textAlign: "left", justifyContent: "flex-start", color: "#222" }}
      >
        <UploadFileIcon sx={{ marginRight: "5px" }} />
        Attach document
        <input type="file" hidden />
      </Button>
    </Box>
  );
}
