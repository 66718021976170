/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Button, FormGroup, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import moment from "moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#74AAF1",
    color: theme.palette.common.white,
    fontSize: 18,
    padding: 12,
	

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    padding: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export function GenericTables({ rows, cellTitles, type, dashboard, setSort,state }) {
  const category = rows[0]?.category;
  let { defaultSortKey,
    titleSortType,
    dateSortType, } = state;

  return (
    <div className="GenericTables">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align={"left"}
                className="text-start"
                style={{ width: "47%" }}
              >
                <Button
                  onClick={() => setSort("title")}
                  endIcon={(titleSortType === "ASC" || titleSortType === "") ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                  style={{
                    color: "#fff",
					textTransform: 'capitalize'
                  }}
				  

                >
                  Title
                </Button>

              </StyledTableCell>
              <StyledTableCell
                style={{ width: "15%" }}
                align={"right"}
                className="text-start"
              >
                Author (Copyright)
              </StyledTableCell>

              
              
              {(category != 3) ? 
                <StyledTableCell
                  style={{ width: "14%" }}
                  align={"right"}
                  className="text-start" >
                  Sub category
                </StyledTableCell> : ''
              }

              {category !== 3 ?
                <StyledTableCell
                  style={{ width: "18%" }}
                  align={"center"}
                  className="text-center" >
                  <Button
                    onClick={() => setSort("date_of_publish")}
                    endIcon={(dateSortType === "ASC" || dateSortType === "") ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    style={{
                      color: "#fff",
                      textTransform: 'capitalize'
                    }} >
                    Publish date
                  </Button>
                </StyledTableCell> : ''
              }
              
              
              <StyledTableCell
                style={{ width: "5%" }}
                align={"right"}
                className="text-start"
              >

              </StyledTableCell>
              <StyledTableCell
                style={{ width: "5%" }}
                align={"right"}
                className="text-start"
              >

              </StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={row.title + i}>
                <StyledTableCell
                  style={{ width: "47%" }}
                  component="th"
                  scope="row"
                >
                  {row.title}

                </StyledTableCell>
                <StyledTableCell style={{ width: "17%" }} align="left">
                  	<a href={row.author_url} target="_blank" rel="noreferrer"> {row.author}</a>
                </StyledTableCell>

                {category != 3 ?
                  <StyledTableCell style={{ width: "12%" }} align="left">
                    {row.sub_category_name}
                  </StyledTableCell> : ''
                }

                {category != 3 ?
                  <StyledTableCell style={{ width: "18%" }} align="center">
                    {row.date_of_publish}
                  </StyledTableCell> : ''
                }
                

                
                <StyledTableCell style={{ width: "5%" }} align="left">

                  <Button
                    variant="contained"
                    disabled={!row.external_links}
                    size="small"
                    onClick={() => {
                      window.open(row.external_links, "_blank");
                    }}
                  >
                    <span style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center"
                    }}>

                      {type === "edit" ? "Edit" : "Read More"}
                      {type === "edit" && <svg className="ml-xs-8" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.15659 2.68683H2.59035C2.16857 2.68683 1.76405 2.85438 1.4658 3.15263C1.16755 3.45088 1 3.85539 1 4.27718V15.4097C1 15.8314 1.16755 16.236 1.4658 16.5342C1.76405 16.8324 2.16857 17 2.59035 17H13.7228C14.1446 17 14.5491 16.8324 14.8474 16.5342C15.1456 16.236 15.3132 15.8314 15.3132 15.4097V9.84342" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.1204 1.49406C14.4368 1.17772 14.8658 1 15.3132 1C15.7605 1 16.1896 1.17772 16.5059 1.49406C16.8223 1.8104 17 2.23945 17 2.68682C17 3.1342 16.8223 3.56325 16.5059 3.87959L8.95176 11.4338L5.77106 12.2289L6.56623 9.04824L14.1204 1.49406Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      }

                    </span>
                  </Button>

                </StyledTableCell>
                <StyledTableCell style={{ width: "5%" }} align="left">

                  <Button
                    variant="contained"

                    disabled={!row.file_path}
                    size="small"
                    href={row.file_path}
                    target="_blank"
                  >


                    Download


                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && <h4 className="mt-xs-32 text-center">No result found</h4>}
    </div>
  );
}




