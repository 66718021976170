import * as yup from "yup";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import { Formik } from "formik";
import { enquiry } from '../../../request/api';
import { email_yup, name_yup } from "../../../misc/auth";


function EnquiryForm() {

  let [state, setState] = useState({
    status_message: {
      type: "success",
      message: "",
    },
  });

  let { status_message } = state;


  const subscribe_email_yup = yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required");


  const subscribe_init_value = {
    name: "",
    email: "",
    message: "",
  }

  const subscribe_validation_schema = yup.object({
    name: name_yup,
    email: email_yup,
    message: yup.string("Enter your Message").required("Message is required"),
  });



  return (
    <div className="subscribeForm enquiryForm container-fluid">
      <div className="row subscribeForm__row">
        <div className="col-md-5 col-12">
          <div className="subscribeForm__title text-white">
            <h4>Send us your enquiry</h4>
            <h5>
              If you would like to know anything more about this platform or
              have any questions about hydrogen and shipping please contact us.
            </h5>
          </div>
        </div>

        <div className="col-md-5 col-12">
          <div className="subscribeForm__form">
            <Formik
              enableReinitialize={true}
              initialValues={subscribe_init_value}
              validationSchema={subscribe_validation_schema}

              onSubmit={async (values, actions) => {
                let res = await enquiry(values);
                if (res.data) {

                  setState({
                    ...state,

                    status_message: {
                      type: "success",
                      message: res.data.message,

                    },
                  });
                } else {
                  let { message, errors } = res.response.data;


                  for (const property in errors) {
                    // console.log(`${property}: ${errors[property]}`);
                    // message = message + ` ${property}: ${errors[property]}`
                    message = message + ` ${errors[property]}`;
                  }

                  setState({
                    ...state,

                    status_message: {
                      type: "error",
                      message,
                    },
                  });
                }
              }}
            >

              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <h1 className="text-white subscribeForm__formTitle">
                    Enquiry form
                  </h1>

                  <div className="form-floating subscribeForm__formFloating">
                    <TextField
                      fullWidth
                      label="Enter Your Name"
                      id="name"
                      variant="filled"
                      sx={{ background: "#fff" }}
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      error={
                        props.touched.name && Boolean(props.errors.name)
                      }
                      helperText={props.touched.name && props.errors.name}

                    />
                  </div>
                  <div className="form-floating subscribeForm__formFloating">
                    <TextField
                      fullWidth
                      label="Enter Your Email"
                      id="email"
                      variant="filled"
                      sx={{ background: "#fff" }}
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      error={
                        props.touched.email && Boolean(props.errors.email)
                      }
                      helperText={props.touched.email && props.errors.email}

                    />
                  </div>
                  <div className="form-floating subscribeForm__formFloating">
                    <TextField
                      fullWidth
                      label="Enter Your Message"
                      id="message"
                      variant="filled"
                      sx={{ background: "#fff" }}
                      message="message"

                      value={props.values.message}
                      onChange={props.handleChange}
                      error={
                        props.touched.message && Boolean(props.errors.message)
                      }
                      helperText={props.touched.message && props.errors.message}
                      multiline
                      // maxRows={8}
                      rows={4}
                    />
                  </div>



                  <button
                    className="w-100 btn btn-lg btn-primary subscribeForm__submitBtn"
                    type="submit"
                  >
                    Submit
                  </button>
                  <p
                    className={
                      status_message.type === "success"
                        ? "text-center text-white mt-xs-8"
                        : "text-center text-white mt-xs-8"
                    }
                  >
                    {status_message.message}
                  </p>
                </form>

              )}

            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryForm;
