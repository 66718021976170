import Fade from "react-reveal/Zoom";

function Hero() {
  return (
    <div className="hero">
      <div className="hero__banner">
        <div className="hero__title">
          <Fade>
            <h1 className="hero__headTitle hero__titleCLip">
              THE H2SHIPS PLATFORM
            </h1>

            <h5 className="hero__subTitle hero__titleCLip">
              System-Based Solutions for H2-Fuelled Water Transport in
              North-West Europe
            </h5>
          </Fade>
        </div>
        {/*<img src={HeroBanner} alt="hero-banner" className="hero__bannerImg" />*/}
        {/*<div className="hero__videoContainer">
          <div className="hero__videoOverlay"></div>
          <video loop autoPlay muted className="hero__videoDiv">
            <source src={"http://159.223.8.42/static/media/heroVideo.e8034e6569e2cf57aef6.mp4"} type="video/mp4" />
            <source src={"http://159.223.8.42/static/media/heroVideo.e8034e6569e2cf57aef6.mp4"} type="video/ogg" />
          </video>
        </div>*/}

        {/*youtube vdo link */}
        <div className="hero__videoContainer video-background">
          <iframe
            width="1584"
            height="615"
            src="https://www.youtube.com/embed/33EXqeyEQr0?rel=0&amp;autoplay=1&playlist=33EXqeyEQr0&controls=0&modestbranding=1&disablekb=1&loop=1&mute=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="ytplayer"
          />
        </div>
        {/*youtube vdo link */}
      </div>
    </div>
  );
}

export default Hero;
