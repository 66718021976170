/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FaqAccordion() {
  return (
    <div className="faqAccordion">
      <h3 className="h4 title mb-4 faqAccordion__title">
        Frequently Asked Questions
      </h3>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is H2SHIPS all about?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            H2SHIPS is an EU Platform funded by Interreg North-West Europe and
            will develop an infrastructure for shipping using hydrogen
            propulsion that can drastically reduce pollution and improve our air
            and water quality.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What is the purpose of this site?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            This site is a resource for learning about hydrogen in shipping; a
            place where ports interested in participating can apply to get
            involved in the H2SHIPS Platform and a place where specialists and
            laypeople can find a rich repository of legislative, educational and
            technical materials relating to hydrogen and fuel cells in shipping.
            The site is also a portal for sharing the H2SHIPS Platform outputs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Isn’t hydrogen too expensive?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            No doubt – it is currently cheaper for a ship owner to sail on
            non-road diesel than on hydrogen. However, the industrialisation and
            deployment of emission-free hydrogen technologies will reduce its
            production cost and its use in transport. This process has already
            started and will be reinforced with national and European hydrogen
            roadmaps.
            <br />
            <br />
            It is true however that as long as there are large amounts of
            easy-to-use fossil fuels available, any alternative will seem
            cheaper if we consider only the direct costs for the end user. If
            the indirect costs due to fossil fuels (effects of local air
            pollution and global climate change) are also considered, then the
            balance is different. A political will is thus necessary to trigger
            the industrialization of Hydrogen as an energy vector, which will
            result in lower costs
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Hydrogen technology is not advanced enough to power a ship, is it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            Hydrogen powered vessels already exist and are in operation, for
            example Alsterwasser in Hamburg, Nemo H2 in Amsterdam, Jules Verne 2
            in France etc. These demonstration platforms have shown that the
            technology works. Now it is time to determine the most suitable
            technical solutions for different applications and types of ships.
            For that purpose, several platforms are currently in development in
            Europe, with a focus on big size fuel cell stacks and the use of
            different forms of H2 storage (compressed, cryogenic liquid, liquid
            organic carriers, ammonia, solid)
            <a
              href="https://hydrogeneurope.eu/sites/default/files/2018-01/EMSA%20Study%20on%20the%20use%20of%20Fuel%20Cells%20in%20Shipping.pdf"
              target="_blank"
              rel="noreferrer"
            >
              See study on the use of fuel cellsin shipping
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Isn’t hydrogen gas too dangerous to store and use?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            Hydrogen is no more dangerous than other flammable fuels, but has
            specific intrinsic properties. Hydrogen has already widely been used
            for decades in the aerospace industry as well as in the chemical
            industry for producing ammonia, methanol, and hydrochloric acid. Due
            to this, an infrastructure to produce, transport and use hydrogen
            already exists, with a good safety record – for instance hundreds of
            kilometres of hydrogen pipelines have been operated in Europe for 70
            years. This experience and the existing norms and procedures can be
            used to safely develop further infrastructure in sectors like
            mobility, buildings etc…
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Doesn’t hydrogen technology require far more testing to ensure it’s
            safe?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            Safety measures for the handling and use of hydrogen already exist
            thanks to decades of experience due to industrial uses of hydrogen.
            For new applications of Hydrogen, in particular in transport, any
            new product entering the market must undergo stringent testing and
            approval processes.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Can hydrogen in transport ever be as safe as conventional fuels?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            Any type of fuel is dangerous. Some of the specific properties of
            Hydrogen are favourable compared to diesel – for instance it is
            non-toxic, and if released in an open environment it will disperse
            much more rapidly than gasoline vapour, thus reducing the risk of
            fire. On the other hand, Hydrogen is a very small molecule with low
            viscosity, and therefore prone to leakage, which makes it more
            difficult to handle and to store. Overall, there is no property of
            Hydrogen which can’t be managed properly with appropriate measures –
            like for conventional fuels. Hydrogen safety in transport is subject
            to norms and regulations like any type fuel.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Doesn’t hydrogen combust terribly easily?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            The auto-ignition temperature of Hydrogen is very similar to that of
            natural gas. Compared to other fuels, hydrogen has a high heat of
            combustion, and a large flammability range – between 4 and 75 volume
            % of hydrogen, which increases the risk of ignition of a hydrogen
            cloud in air. On the other hand, due to its high diffusion velocity,
            a cloud of Hydrogen leaking from a tank into the air would dissipate
            much quicker than other flammable vapours, thus again reducing the
            risk of ignition. It is thus not straightforward to say whether
            Hydrogen’s specific combustion properties make it more or less
            dangerous than other more conventional fuels.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Isn’t the process of converting renewables into hydrogen (and then
            using it for power) terribly inefficent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            For hydrogen production, storage and subsequent conversion into
            electricity, the efficiency is currently a maximum of 43%. It is
            assumed that in future electrical overall efficiencies up to 49 to
            55% will be achieved.
            <br />
            <br />
            From a pure perspective of energy balance, it will always be more
            efficient to use directly the electricity than to transform it to
            Hydrogen. However, the capacity of hydrogen to be stored either in
            gaseous or liquid forms, allows for more energy than batteries
            providing more autonomy. There are many applications (especially
            shipping, railroads, heavy road transportation) where Hydrogen is
            advantageous if we consider not only the efficiency, but also the
            environmental impact, operational constraints (filling/reloading
            time), overall costs, and often the mere technical feasibility.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            There are now a number of electric ships – why bother with hydrogen?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold" }}>
            Most of hydrogen powered vessel are actually electric propulsion
            vessels, where the energy for propulsion is mainly stored in the
            form of hydrogen, and transformed into electricity via a fuel cell.
            In this configuration a battery is needed anyway to serve as buffer
            between the fuel cell and the motor. The choice of how much energy
            should be stored in the battery and how much under the form of
            hydrogen then strongly depends on the operational needs of the ship,
            having in mind that: 1) it is much quicker to fill a hydrogen tank
            than to load a battery 2) fuel cell + H2 storage solutions become
            more advantageous, in particular in terms of embarked mass, as the
            amount of stored energy increases (i.e. the longer the requested
            range, the more interesting it is to choose Hydrogen).
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FaqAccordion;
