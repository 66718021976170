import React, { Component, createContext, useReducer } from "react";
import { typeList, categoryList, tagList,categoryTree } from "../misc/data";

export const SiteContext = createContext();
let defaultValue = {
  typeList: typeList,
  categoryList: categoryList,
  tagList: tagList,
  categoryTree: [...categoryTree],


};

const SiteContextProvider = (props) => {

  const [siteStore, dispatch] = useReducer(siteReducer, defaultValue);

  return (<SiteContext.Provider value={{ siteStore, dispatch }}>
    {props.children}
  </SiteContext.Provider>)
}

export default SiteContextProvider;


const siteReducer = (state, action) => {
  switch (action.type) {
    case 'update_category_tree':
      return {
        ...state,
        categoryTree: action.categoryTree,
      }
    case 'update_tags':
      return {
        ...state,
        tagList: action.tagList,
        destination: action.destination,
      }
    case 'change_class':
      return {
        ...state,
        class: action.class,

      }
    case 'change_passenger':
      return {
        ...state,
        passenger: action.passenger,
      }
    case 'change_childDOBlist':
      return {
        ...state,
        childDOBlist: action.childDOBlist,
      }

    default:
      return state;
  }
}