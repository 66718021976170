/* eslint-disable jsx-a11y/anchor-is-valid */

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

function PaginationComp({ page, pageCount, onChange }) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    //  style={{ minHeight: "3vh" }}
    >
      <Stack spacing={2} className="text-end">
        <Pagination page={page} count={pageCount}
          // hidePrevButton hideNextButton
          onChange={(e, page) => {
            
            onChange(e, page)
          }}
          color="primary" />
      </Stack>
    </Grid>
  );
}

export default PaginationComp;
