/* eslint-disable jsx-a11y/anchor-is-valid */
import eventbg from "../../../assets/images/events-bg.jpg";
import LocationOnIcon from '@mui/icons-material/LocationOn';

function EventCard({id, date, title, location, details}) {
  return (
    <div className="eventCard">
      <div className="eventCard__flex">
        <div className="card eventCard__card">
          <div className="card-body">
            <img src={eventbg} className="w-100 mb-3 rounded" alt="events-img" />
            <h5 className="card-title mb-3">{date}</h5>
           
            <h6 className="card-subtitle eventCard__subtitle mb-2 fw-bold">
              {title}
            </h6>
            <p className="card-text"> <LocationOnIcon /> {details}</p>
            <a href={location} target="_blank" className="eventCard__btn fw-normal" rel="noreferrer">Event info</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
