import PilotContent from "../components/contents/aboutContent/PilotContent";

function Pilots() {
  return (
    <div>
      <PilotContent />
    </div>
  );
}

export default Pilots;
