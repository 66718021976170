import React from "react";
import AuthComponent from "../components/auth/AuthComponent";

export default function SignUp() {
  return (
    <div>
      <AuthComponent authType="signup" />
    </div>
  );
}
