import EnquiryForm from "../components/contents/formContent/EnquiryForm";

function Enquiry() {
  return (
    <>
      <EnquiryForm />
    </>
  );
}

export default Enquiry;
