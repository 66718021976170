import { toast as toastMaterial } from 'material-react-toastify';
import { CalculatorStepsDATA_KEY_MAP } from './data';
import { cloneDeep } from 'lodash';
import React from 'react';
export function debounce_leading(func, timeout = 300) {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}




export const handleChange = (value, setState, CalculatorSteps, type, index) => {
  CalculatorSteps[index].inputType[type].value = value;
  setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }))
}

let defaultOptions = {
  position: 'top-right',
}

const toast = (message, options = defaultOptions) => toastMaterial(message, options = defaultOptions);

toast.info = (message, options = defaultOptions) =>
  toastMaterial.info(
    <>
      <span className="mdi mdi-information" /> {message}
    </>,
    options,
  );

toast.error = (message, options = defaultOptions) =>
  toastMaterial.error(
    <>
      <span className="mdi mdi-alert-circle" /> {message}
    </>,
    options,
  );

toast.warn = (message, options = defaultOptions) =>
  toastMaterial.warn(
    <>
      <span className="mdi mdi-alert" /> {message}
    </>,
    options,
  );

toast.success = (message, options = defaultOptions) =>
  toastMaterial.success(
    <>
      <span className="mdi mdi-check-circle" /> {message}
    </>,
    options,
  );

export { toast };

export const isThisStep_Valid = (key, CalculatorSteps, setState) => {
  let isValidated = true;
  let stepData = CalculatorSteps[CalculatorStepsDATA_KEY_MAP[key]];

  for (const prop in stepData.inputType) {
    let fieldData = stepData.inputType[prop];
    if (fieldData.value === "" && fieldData.required) {
      fieldData.validationMessage = "This field is required"


      isValidated = false;
      stepData.inputType[prop] = fieldData;
    }
  }

  CalculatorSteps[CalculatorStepsDATA_KEY_MAP[key]] = cloneDeep(stepData);
  setState(prev => ({ ...prev, CalculatorSteps: CalculatorSteps }))

  if (isValidated) {
    localStorage.setItem(key, JSON.stringify(stepData));
  }

  return isValidated;
}



export const getPrevStep = (step) => {


  if (step === "result") {
    return "Capacity_Dimensioning"
  } else if (step === "Capacity_Dimensioning") {
    return "Operational_Profile"
  } else if (step === "Operational_Profile") {
    return "Propulsion_Power"
  }
}

export const getNextStep = (step) => {
  if (step === "Propulsion_Power") {
    return "Operational_Profile"
  } else if (step === "Operational_Profile") {
    return "Capacity_Dimensioning"
  } else if (step === "Capacity_Dimensioning") {

    return "result"
  }
}








// {
//   "Propulsion Power": {
//       "Installed Power:": {
//           "value": 33,
//           "unit": ""
//       },
//       "Required Forward speed. inland waterway zone": {
//           "Max Speed:": {
//               "value": 33,
//               "unit": "km/h"
//           },
//           "Keep at least during:": {
//               "value": 33,
//               "unit": "h"
//           }
//       },
//       "Resistance": {
//           "Resistance coefficient:": {
//               "value": 33,
//               "unit": ""
//           }
//       },
//       "Propulsive Efficiency": {
//           "Shaft:": {
//               "value": 99,
//               "unit": "%"
//           },
//           "propeller:": {
//               "value": 72,
//               "unit": "%"
//           }
//       }
//   },
//   "Operation Profile": {
//       "Distance:Origin Distination:": {
//           "value": 100,
//           "unit": "KM"
//       },
//       "Average speed:Service:": {
//           "value": 35,
//           "unit": "Km/h"
//       },
//       "Load capacity: Max load capacity:": {
//           "value": 4000,
//           "unit": "tons"
//       },
//       "Load capacity: Average:": {
//           "value": 0.5,
//           "unit": ""
//       },
//       "Operational hours per Year: No Round trip for year:": {
//           "value": 4999,
//           "unit": ""
//       },
//       "Bunkering: N stops:": {
//           "value": 2,
//           "unit": ""
//       }
//   },
//   "Capacity Dimensioning": {
//       "Hydrogen Storage": {
//           "Pressure of hydrogen storage:": {
//               "value": 700,
//               "unit": "Bar"
//           }
//       },
//       "Maximum limit:": {
//           "value": 33,
//           "unit": "Tons"
//       },
//       "Volume limit:": {
//           "value": 1000,
//           "unit": "M^3"
//       }
//   },
//   "Cost analysis": {
//       "Cost parameter": {
//           "System Lifetime": {
//               "Lifetime:": {
//                   "value": 20,
//                   "unit": "Years "
//               }
//           }
//       }
//   }
// }

export const showObjectPropertyBasedValue = (obj) => {


  return Object.keys(obj).map((key, index) => {
    console.log('key, index:', key, index)
    if (key === "value") {
      console.log('hitttt:', key, index)

      return <div key={index + key}>
        <h6>{key}</h6>
        <h6>{obj[key]}</h6>
      </div>
    } else {
      return <div key={index + key}>
        <h6>{key}</h6>
        {showObjectPropertyBasedValue(obj[key])}
      </div>
    }
  })

}





export const iterate = (obj) => {
  Object.keys(obj).forEach(key => {
    console.log(`key: ${key}, value: ${obj[key]}`)
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      iterate(obj[key])
    }
    
  })
}
// iterate(obj);

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("error 007",error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}