import React from "react";
import GenericContentTable from "../components/GenericContentList/GenericContentTable";
import { useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";
function GenericContentList() {
  // let navigate = useNavigate();
  // let [searchParams, setSearchParams] = useSearchParams();
  // const location = useLocation();
  let params = useParams();
  return (
    <>
      <GenericContentTable
        params={params}
        dashboard={false}
      />
    </>
  );
}

export default GenericContentList;

