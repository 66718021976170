import teamimage from "../../../assets/images/teamimage.jpeg";

function MeetTeam() {
  const handlerOne = () => {
    return 0;
  };
  return (
    <div className="meetTeam container mb-5">
      <h2 className="meetTeam__title">Meet Our Team</h2>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <img
            src={teamimage}
            alt="meetTeam-intro"
            className="meetTeam__img rounded"
          />
          {/*<p className="text-center text-secondary mt-4">
            H2SHIPS Project Partners
          </p>*/}
        </div>
        <div className="col-md-4">
          <div className="meetTeam__text">
            <h5 className="meetTeam__detailsTitle">
              This exciting project brings together 12 partners from across
              Europe
            </h5>
            <p className="meetTeam__subText mt-4">
              You can find further details about the partners involved in the
              H2SHIPS Project by clicking{" "}
              <a
                href="https://www.nweurope.eu/projects/project-search/h2ships-system-based-solutions-for-h2-fuelled-water-transport-in-north-west-europe/"
                target="_blank"
                rel="noreferrer"
              >
                here.
              </a>
            </p>
            <p className="meetTeam__subText">
              You can check out our latest project tweets, why not subscribe to
              our{" "}
              <a
                href="https://twitter.com/H2Ships"
                target="_blank"
                rel="noreferrer"
              >
                twitter
              </a>
              ?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetTeam;
