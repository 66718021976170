import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import { GetEvents } from "../../../request/api";
import { LoadingComponent } from '../../common/CommonComponents';


function EventContainer() {

  const [events, setEvents] = useState([]);
  const [loading, setloading] = useState(false)
  const [response, setresponse] = useState({
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: '',
    per_page: 9,
    prev_page_url: null,
    to: 0,
    total: 0,
  });

   const getAllEvents = async () => {
    setloading(true)
    await GetEvents("").then((res) => {

      setresponse(res.data.data);
    });
    setloading(false)
  }

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <div className="eventContainer container" id="events">
      <h1 className="text-center eventContainer__title">Upcoming Events</h1>
          <div className="eventCard__flex row mb-3">
			{response.data.map((e, id) => (
				<div className="col-md-4 col-12 mb-4">
					<EventCard id={e.id} title={e.title} date={e.events_date} location={e.location} details={e.details}/>
				</div>
			))}
          </div>
        </div>
  );
}

export default EventContainer;
