import FaqBody from "../components/contents/faqContent/FaqBody";

function Faq() {
  return (
    <>
      <FaqBody />
    </>
  );
}

export default Faq;
