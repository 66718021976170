import InitiativeTable from "../components/linksContent/Initiatives/InitiativeTable";

function InitiativeWorkshop() {
  return (
    <>
      <InitiativeTable />
    </>
  );
}

export default InitiativeWorkshop;
