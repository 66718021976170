import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import DashboadNav from "../components/UserDashboard/DashboadNav";
import GenericForms from "../components/UserDashboard/GenericForms/GenericForms";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import NavBar from "../components/UserDashboard/NavBar";
function createData(title, author, category, tag, publishDate, url) {
  return { title, author, category, tag, publishDate, url };
}
const rows = [
  createData(
    "Feasibility of the SF-BREEZE: a Zero-Emission, Hydrogen Fuel Cell, High-Speed Passenger Ferry",
    "International Transport Forum / OECD",
    "Case study",
    "Research, fuel, H2O",
    "10, Sept 2018",
    "https://www.google.com"
  ),
  createData(
    "Feasibility of the SF-BREEZE: a Zero-Emission, Hydrogen Fuel Cell, High-Speed Passenger Ferry",
    "International Transport Forum / OECD",
    "Case study",
    "Research, fuel, H2O",
    "10, Sept 2018",
    "https://www.google.com"
  ),
  createData(
    "Feasibility of the SF-BREEZE: a Zero-Emission, Hydrogen Fuel Cell, High-Speed Passenger Ferry",
    "International Transport Forum / OECD",
    "Case study",
    "Research, fuel, H2O",
    "10, Sept 2018",
    "https://www.google.com"
  ),
  createData(
    "Feasibility of the SF-BREEZE: a Zero-Emission, Hydrogen Fuel Cell, High-Speed Passenger Ferry",
    "International Transport Forum / OECD",
    "Case study",
    "Research, fuel, H2O",
    "10, Sept 2018",
    "https://www.google.com"
  ),
];
const cellTitles = [
  "Title",
  "Author",
  "Category",
  "Tag",
  "Publish date",
  "",
];
export default function UserDashboardForm() {
  let navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="UserDashboardForm pb-xs-64">
      <div className="form-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <NavBar />
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-xs-32">
        <div className="row justify-content-center">
          {/*<div className="col-md-3">
            <DashboadNav />
          </div>*/}
          <div className="col-md-9">
            <GenericForms />
          </div>
        </div>
      </div>
    </div>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#2E486A",
  "&:hover": {
    backgroundColor: "#2E486A",
  },
}));
