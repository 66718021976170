import ConceptContent from "../components/contents/aboutContent/ConceptContent";

function Concepts() {
  return (
    <div>
      <ConceptContent />
    </div>
  );
}

export default Concepts;
