import * as React from 'react';
import { ReportFilters, ReportFilterTags, ReportsCellTitles } from "../../../misc/data";
import PaginationComp from "../../common/Pagination";
import { GenericTables } from "../../common/GenericTables";
import { RadioInputs, SearchOption, Tags } from "../../common/CommonComponents";
import { GetReports, getReportTypeList, getReportTagList } from "../../../request/api";
import Box from "@mui/material/Box";
import { debounce_leading } from '../../../misc/commonFunctions';
import { Button } from '@mui/material';




const cellTitles = [
  "Title",
  "Author",
  "Category",
  "Tag",
  "Publish date",
  "",
];
export default function LegislativeTable() {
  const initialFilterObject = {
    per_page: 0,
    page: 0,
    type: "all",
    category: 0,
    tags: [],
    keyword: ""
  };

  const [typeList, settypeList] = React.useState([]);
  const [tagList, settagList] = React.useState([])
  const [response, setresponse] = React.useState({
    current_page: 0,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: '',
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  });

  const [filterObject, setFilterObject] = React.useState(initialFilterObject);
  const resetFilter = async () => {
    setFilterObject({ ...initialFilterObject })
    let res = await GetReports("");
    setresponse(res.data.data);
  }
  const initFilter = async (filterObject) => {
    let queryStringArr = []

    if (filterObject.per_page !== 0) {
      queryStringArr.push(`per_page=${filterObject.per_page}`);
    }
    if (filterObject.page !== 0) {
      queryStringArr.push(`page=${filterObject.page}`);
    }
    if (filterObject.category !== 0) {
      queryStringArr.push(`category=${filterObject.category}`);
    }

    if (filterObject.type !== "all") {
      queryStringArr.push(`type=${filterObject.type}`);
    }

    if (filterObject.tags.length !== 0) {
      queryStringArr.push(`tags=${filterObject.tags}`);
    }
    if (filterObject.keyword.length !== 0) {
      queryStringArr.push(`keyword=${filterObject.keyword}`);
    }


    let queryString = '?' + queryStringArr.join("&");
    if (filterObject !== initialFilterObject) {
      queryString = queryString === "?" ? "" : queryString
      let res = await GetReports(queryString);
      setresponse(res.data.data);
    }


  }
  React.useEffect(() => {
    debounce_leading(initFilter(filterObject), 700)

  }, [filterObject.category, filterObject.type, filterObject.tags]);

  const dataFetch = async () => {

    await GetReports("").then(async (res) => {
      setresponse(res.data.data);
      await getReportTypeList().then(async (typeList) => {
        typeList = typeList.data.data.map((d => ({ label: d.name, value: d.id })))
        typeList.unshift({
          label: "All Reports",
          value: "all",
        });
        settypeList(typeList);


        let tagList = await getReportTagList();
        tagList = tagList.data.data.map((d => ({ label: d.name, value: d.id })))
        settagList(tagList)
      });
    });;

  }


  React.useEffect(() => {
    dataFetch();
  }, []);


  const handleChange = (event) => {
    
    setFilterObject({
      ...filterObject,
      type: event.target.value
    });

  };

  const handleChangeTag = (v) => {
    let prevTag = [...filterObject.tags];
    let index = prevTag.indexOf(v);
    if (index === -1) {
      prevTag.push(v)
    } else {
      prevTag.splice(index, 1);
    }


    setFilterObject({
      ...filterObject,
      tags: prevTag
    });
  };

  return (
    <section className="table-view Reports">
      <div className="container-fluid">
        <div className="row mt-4">
        <div className="col-md-3 col-12">
            <p className="text-right">
              <Button onClick={resetFilter}>Reset</Button>
            </p>
            <div className="filters">
              <div className="filter-block">
                <h3 className="h4 title">Seach by filter</h3>
                <div className="filter-cont">
                  <RadioInputs
                    list={typeList}
                    value={filterObject.type}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="filter-block">
                <h3 className="h4 title">Tags</h3>
                <div className="filter-cont">
                  <Tags
                    list={tagList}
                    values={filterObject.tags}
                    handleChange={handleChangeTag}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12">
          <div className="table-title-search">
              <h4 className="title">
              Legislative
              </h4>
              <div className="search">
                <SearchOption
                  keyword={filterObject.keyword}
                  search={(v) => {
                    initFilter(filterObject)
                  }}
                  onChange={(v) => {
                    let newobj = {
                      ...filterObject,
                      keyword: v,
                      page: 1
                    }
                    setFilterObject(newobj);
                    if (v === "") {
                      initFilter(newobj);
                    }
                  }}
                />
              </div>

            </div>
            <GenericTables
              rows={response.data}
              cellTitles={ReportsCellTitles}
            />
          </div>
          {response.total > 10 && <Box sx={{ mt: 4 }}>
            <PaginationComp
              onChange={(e, page) => {
                setFilterObject({
                  ...filterObject,
                  page
                });

              }}
              page={filterObject.page}
              pageCount={response.total / response.per_page}
            />
          </Box>}
        </div>
      </div>
    </section>
  );
}
