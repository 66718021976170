import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { GenericTables } from "../components/common/GenericTables";
import GenericContentTable from "../components/GenericContentList/GenericContentTable";
import { Box, Button, Card, CardActionArea, CardMedia, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import NavBar from "../components/UserDashboard/NavBar";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ViewListIcon from '@mui/icons-material/ViewList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';




export default function UserDashboard() {

const navigate = useNavigate();

  return (
    <div className="UserDashboard pb-xs-64">
      <div className="form-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <NavBar />
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-xs-32 pb-xs-64">
        <div className="row justify-content-center">
          {/* <div className="col-md-3">
            <DashboadNav />
          </div> */}
          <div className="col-md-3 col-12">
           <Card sx={{ maxWidth: 345 }} onClick={() => navigate("/user-dashboard-form")}>
				<CardActionArea>
					<CardContent>
					<Typography gutterBottom variant="h5" component="div" mb="3rem">
						Add Content
					</Typography>
						<AddCircleIcon fontSize="large" color="primary"/>
							{/*<AddCircleIcon fontSize="large" sx={{ background: "#0b4da2", borderTopRightRadius:"3rem"}}/>*/}
					</CardContent>
				</CardActionArea>
			</Card>
          </div>

		  <div className="col-md-3 col-12">
			 <Card sx={{ maxWidth: 345 }} onClick={() => navigate("/my-activity")}>
				<CardActionArea>
					<CardContent>
					<Typography gutterBottom variant="h5" component="div"  mb="3rem">
						My Activity
					</Typography>
					<ViewListIcon fontSize="large" color="primary"/>
					</CardContent>
				</CardActionArea>
			</Card>
          </div>

			<div className="col-md-3 col-12">
				<Card sx={{ maxWidth: 345 }} onClick={() => navigate("/profile")}>
					<CardActionArea>
						<CardContent>
						<Typography gutterBottom variant="h5" component="div" mb="3rem">
							Edit Profile
						</Typography>
						<AccountCircleIcon fontSize="large" color="primary"/>
						</CardContent>
					</CardActionArea>
				</Card>
			</div>
        </div>
      </div>
    </div>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#2E486A",
  "&:hover": {
    backgroundColor: "#2E486A",
  },
}));
