import _ from 'lodash';
import React from 'react'
import { ErrorBoundary, iterate, showObjectPropertyBasedValue } from '../../misc/commonFunctions';
import { paramTree } from '../../misc/paramTree';

export default function ParamsView({ state, pdf }) {
  let viewOutput = {};
  let { CalculatorSteps } = state;
  let request = {};
  for (let i = 0; i < CalculatorSteps.length; i++) {
    let step = CalculatorSteps[i];
    for (const property in step.inputType) {

      if (!isNaN(step.inputType[property].value)) {
        request[property] = parseFloat(step.inputType[property].value)
      } else {
        request[property] = step.inputType[property].value
      }
    }
  }


  for (const prop in request) {
    if (request[prop] !== null && !isNaN(request[prop])) {
      if (paramTree[prop]) {
        let labelList = paramTree[prop].label.split("=>")
        const result = labelList.reverse().reduce((res, key) => {

          return { [key.trim()]: res }
        }, {
          value: request[prop],
          unit: paramTree[prop].unit
        });

        // console.log("result", result)

        _.merge(viewOutput, result);


      } else {
        console.error("not found", prop, paramTree[prop])
      }
    }
  }

  const iterate = (obj) => {
    return Object.keys(obj).map((key, i) => {

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        return (
          <div className=' pl-xs-8 pr-xs-8' style={{ border: "1px dashed #ddd" }}>
            <h6>{key} {key === "value" && obj[key]}</h6>
            <div className='p-xs-4'>
              {iterate(obj[key])}
            </div>
          </div>
        )
      }
      if (key === "value") {
        return (
          <div className='pl-xs-8 pr-xs-8' style={{ border: "1px dashed #0b4da2" }}>
            <h6 className='p-xs-4' style={{ color: "#0b4da2" }}>Value: {obj.value} {obj.unit}</h6>
          </div>
        )
      }



    })
  }

  return (
    <div id={pdf ? "ParamsView" : ""}>
      <h4 className='mb-xs-16'>Entered Parameters </h4>
      <div className="custom-row">
        {iterate(viewOutput)}
      </div>
    </div>
  )
}
