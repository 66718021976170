import NewsDetails from "../components/contents/newsContent/NewsDetails";

function NewsSingle() {
  return (
    <>
      <NewsDetails />
    </>
  );
}

export default NewsSingle;
