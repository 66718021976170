import * as React from "react";
import GenericContentTable from "../../src/components/GenericContentList/GenericContentTable";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import NavBar from "../components/UserDashboard/NavBar";


export default function UserActivity() {


  return (
    <div className="UserActivity pb-xs-64">
      <div className="form-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <NavBar />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid UserActivity__container">
        <div className="row justify-content-center">
          {/* <div className="col-md-3">
            <DashboadNav />
          </div> */}
          <div className="col-md-9">
			<h2 className="mt-xs-64 text-center UserActivity__title">My Activity</h2>
            <GenericContentTable
              dashboard={true}
              params={{
                id: 0,
                slug: ""
              }}
            />

          </div>
        </div>
      </div>
    </div>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#2E486A",
  "&:hover": {
    backgroundColor: "#2E486A",
  },
}));
